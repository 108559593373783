/**
 * The SuperAdminDashboard component is the main dashboard for the super admin user. It displays a set of cards with important data about the admins.
 * 
 * The component fetches the data from the server and passes it to the individual card components.
 * 
 * The component uses various Material-UI components to style and layout the dashboard.
 */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, Grid } from '@mui/material';
import AdminCountCard from '../../components/superadmin/AdminCountCard';
import ActiveSubscriptionsCard from '../../components/superadmin/ActiveSubscriptionsCard';
import TotalRevenueCard from '../../components/superadmin/TotalRevenueCard';
import SubscriptionTierBreakdownCard from '../../components/superadmin/SubscriptionTierBreakdownCard';

const SuperAdminDashboard = () => {
    const [dashboardData, setDashboardData] = useState({
        adminCount: 0,
        activeSubscriptions: 0,
        totalRevenue: 0,
        tierBreakdown: {}
    });

    const fetchDashboardData = async () => {
        try {
            const { data } = await axios.get('/api/superadmin/dashboard', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });
            setDashboardData(data);
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        }
    };

    useEffect(() => {
        fetchDashboardData();
        const intervalId = setInterval(fetchDashboardData, 60000); // Refresh every minute

        return () => clearInterval(intervalId); // Clean up on unmount
    }, []);

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Typography variant="h4" gutterBottom>
                Super Admin Dashboard
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                    <AdminCountCard count={dashboardData.adminCount} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <ActiveSubscriptionsCard count={dashboardData.activeSubscriptions} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TotalRevenueCard revenue={dashboardData.totalRevenue} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <SubscriptionTierBreakdownCard breakdown={dashboardData.tierBreakdown} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default SuperAdminDashboard;
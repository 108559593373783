// client/src/components/resident/UpcomingEvents.js

import React from 'react';
import { Typography, List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Event as EventIcon } from '@mui/icons-material';

const UpcomingEvents = ({ events }) => {
  return (
    <div>
      <Typography variant="h6" gutterBottom>Upcoming Events</Typography>
      <List>
        {events.map((event, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <EventIcon color="primary" />
            </ListItemIcon>
            <ListItemText 
              primary={event.title}
              secondary={new Date(event.date).toLocaleString()}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default UpcomingEvents;
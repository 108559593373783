/**
 * The main layout component for the application, which includes a navbar, sidebar, and main content area.
 *
 * @param {object} props - The component props.
 * @param {React.ReactNode} props.children - The main content to be rendered in the layout.
 * @returns {React.ReactElement} The main layout component.
 */
import React, { useState } from 'react';
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Sidebar from '../common/Sidebar';
import Navbar from '../common/Navbar';

const MainLayout = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
    console.log('Sidebar open:', !mobileOpen); // Add this line for debugging
  };

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <Navbar onMenuClick={handleDrawerToggle}>
        {isMobile && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Navbar>
      <Sidebar mobileOpen={mobileOpen} onClose={handleDrawerToggle} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - 240px)` },
          mt: '64px', // Height of the Navbar
          ml: { xs: 0, sm: mobileOpen ? '240px' : 0 }, // Conditionally apply left margin for sidebar on desktop
          p: { xs: 2, sm: '24px 16px 24px 24px' }, // Adjusted padding for desktop
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default MainLayout;
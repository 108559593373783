import React, { useState } from 'react';
import axios from 'axios';
import { Typography, Box, Paper, TextField, Button, Grid, Snackbar, InputAdornment, Collapse, IconButton, Select, MenuItem } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { Cake as CakeIcon, Phone as PhoneIcon, Email as EmailIcon, Home as HomeIcon, Edit as EditIcon, Save as SaveIcon, ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Person as PersonIcon, Work as WorkIcon, Notes as NotesIcon, WarningAmber as AllergiesIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { IMaskInput } from 'react-imask';
import { parseISO, format, isValid } from 'date-fns';

const StyledPaper = styled(Paper)(({ theme }) => ({
  overflow: 'hidden',
  transition: 'all 0.3s',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2, 3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const StyledTextField = styled(TextField)(({ theme, isediting }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: isediting === 'true' ? theme.palette.background.paper : 'transparent',
    transition: theme.transitions.create(['background-color', 'box-shadow']),
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: isediting === 'true' ? `1px solid ${theme.palette.primary.main}` : 'none',
  },
  '& .MuiInputBase-input': {
    padding: isediting === 'true' ? theme.spacing(1) : 0,
    fontWeight: 'normal',
    color: theme.palette.text.primary,
  },
  '& .MuiInputLabel-root': {
    display: isediting === 'true' ? 'block' : 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: isediting === 'true' ? theme.palette.primary.main : 'transparent',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: isediting === 'true' ? theme.palette.primary.main : 'transparent',
  },
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
  },
  transition: 'all 0.3s',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  border: `2px solid ${theme.palette.primary.contrastText}`,
}));

const ExpandButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.dark,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  marginRight: theme.spacing(2),
  transition: 'transform 0.3s',
  '&.expanded': {
    transform: 'rotate(180deg)',
  },
}));

const TitleBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const DateMaskInput = React.forwardRef(function DateMaskInput(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00/00/0000"
      definitions={{
        '0': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const InfoItem = ({ icon, label, value, isEditing, onChange, InputProps }) => (
  <Grid item xs={12} sm={6}>
    <Box display="flex" alignItems="center" mb={2}>
      <InputAdornment position="start" sx={{ mr: 1, color: 'primary.main' }}>
        {icon}
      </InputAdornment>
      <Box flexGrow={1}>
        {!isEditing && (
          <Typography variant="body1" fontWeight="bold" color="primary.main">
            {label}
          </Typography>
        )}
        <StyledTextField
          fullWidth
          label={isEditing ? label : ''}
          value={value}
          onChange={onChange}
          InputProps={{
            readOnly: !isEditing,
            ...InputProps,
          }}
          variant={isEditing ? "outlined" : "standard"}
          size="small"
          isediting={isEditing.toString()}
        />
      </Box>
    </Box>
  </Grid>
);

const GeneralInformation = ({ resident, setResident, isMobile }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [editedFields, setEditedFields] = useState({
    dateOfBirth: resident.dateOfBirth ? format(parseISO(resident.dateOfBirth), 'MM/dd/yyyy') : '',
    phoneNumber: resident.phoneNumber || '',
    email: resident.email || '',
    gender: resident.gender || '',
    employmentStatus: resident.employmentStatus || '',
    allergies: resident.allergies || '',
    address: {
      street: resident.address?.street || '',
      city: resident.address?.city || '',
      state: resident.address?.state || '',
      zipCode: resident.address?.zipCode || '',
    },
    notes: resident.notes || '',
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleChange = (field) => (event) => {
    setEditedFields((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleAddressChange = (field) => (event) => {
    setEditedFields((prev) => ({
      ...prev,
      address: {
        ...prev.address,
        [field]: event.target.value,
      },
    }));
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      // Convert date to ISO format before sending to server
      const dateOfBirth = editedFields.dateOfBirth;
      const [month, day, year] = dateOfBirth.split('/');
      const isoDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      
      if (!isValid(parseISO(isoDate))) {
        throw new Error('Invalid date format');
      }

      const dataToSend = {
        ...editedFields,
        dateOfBirth: isoDate,
      };

      console.log('Sending data:', dataToSend);
      const response = await axios.put(
        `/api/admin/resident/${resident._id}`,
        dataToSend,
        {
          headers: { 
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      
      console.log('Response:', response.data);
      if (typeof setResident === 'function') {
        // Format the date back to MM/dd/yyyy for display
        const updatedResident = {
          ...response.data,
          dateOfBirth: format(parseISO(response.data.dateOfBirth), 'MM/dd/yyyy'),
        };
        setResident(updatedResident);
      } else {
        console.warn('setResident is not a function. Unable to update resident state.');
      }
      setIsEditing(false);
      setSnackbarMessage('Resident information updated successfully');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error updating resident information:', error);
      console.error('Error response:', error.response);
      setSnackbarMessage(`Error updating resident information: ${error.response?.data?.message || error.message}`);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <StyledPaper elevation={2}>
      <StyledHeader>
        <TitleBox>
          <ExpandButton
            onClick={handleToggleExpand}
            className={isExpanded ? 'expanded' : ''}
            size={isMobile ? "small" : "large"}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ExpandButton>
          <Typography variant={isMobile ? "subtitle1" : "h6"} fontWeight="bold">
            General Information
          </Typography>
        </TitleBox>
        <AnimatedButton
          onClick={isEditing ? handleSave : handleEdit}
          startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
          size={isMobile ? "small" : "medium"}
        >
          {isEditing ? 'Save' : 'Edit'}
        </AnimatedButton>
      </StyledHeader>
      <Collapse in={isExpanded}>
        <StyledContent>
          <Grid container spacing={isMobile ? 2 : 3}>
            <InfoItem
              icon={<CakeIcon />}
              label="Date of Birth"
              value={editedFields.dateOfBirth}
              isEditing={isEditing}
              onChange={handleChange('dateOfBirth')}
              InputProps={{
                inputComponent: DateMaskInput,
              }}
            />
            <InfoItem
              icon={<PhoneIcon />}
              label="Phone Number"
              value={editedFields.phoneNumber}
              isEditing={isEditing}
              onChange={handleChange('phoneNumber')}
            />
            <InfoItem
              icon={<EmailIcon />}
              label="Email"
              value={editedFields.email}
              isEditing={isEditing}
              onChange={handleChange('email')}
            />
            <Grid item xs={12} sm={6}>
              <Box display="flex" alignItems="center" mb={2}>
                <InputAdornment position="start" sx={{ mr: 1, color: 'primary.main' }}>
                  <PersonIcon />
                </InputAdornment>
                <Box flexGrow={1}>
                  {!isEditing && (
                    <Typography variant="body1" fontWeight="bold" color="primary.main">
                      Gender
                    </Typography>
                  )}
                  <StyledTextField
                    select
                    fullWidth
                    label={isEditing ? "Gender" : ""}
                    value={editedFields.gender}
                    onChange={handleChange('gender')}
                    InputProps={{
                      readOnly: !isEditing,
                    }}
                    variant={isEditing ? "outlined" : "standard"}
                    size="small"
                    isediting={isEditing.toString()}
                  >
                    <MenuItem value="Male">Male</MenuItem>
                    <MenuItem value="Female">Female</MenuItem>
                  </StyledTextField>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="flex-start" mb={2}>
                <InputAdornment position="start" sx={{ mr: 1, mt: 1, color: 'primary.main' }}>
                  <HomeIcon />
                </InputAdornment>
                <Box flexGrow={1}>
                  {!isEditing && (
                    <Typography variant="body1" fontWeight="bold" color="primary.main">
                      Address
                    </Typography>
                  )}
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <StyledTextField
                        fullWidth
                        label={isEditing ? "Street" : ""}
                        value={editedFields.address.street}
                        onChange={handleAddressChange('street')}
                        InputProps={{
                          readOnly: !isEditing,
                        }}
                        variant={isEditing ? "outlined" : "standard"}
                        size="small"
                        isediting={isEditing.toString()}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <StyledTextField
                        fullWidth
                        label={isEditing ? "City" : ""}
                        value={editedFields.address.city}
                        onChange={handleAddressChange('city')}
                        InputProps={{
                          readOnly: !isEditing,
                        }}
                        variant={isEditing ? "outlined" : "standard"}
                        size="small"
                        isediting={isEditing.toString()}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <StyledTextField
                        fullWidth
                        label={isEditing ? "State" : ""}
                        value={editedFields.address.state}
                        onChange={handleAddressChange('state')}
                        InputProps={{
                          readOnly: !isEditing,
                        }}
                        variant={isEditing ? "outlined" : "standard"}
                        size="small"
                        isediting={isEditing.toString()}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <StyledTextField
                        fullWidth
                        label={isEditing ? "Zip Code" : ""}
                        value={editedFields.address.zipCode}
                        onChange={handleAddressChange('zipCode')}
                        InputProps={{
                          readOnly: !isEditing,
                        }}
                        variant={isEditing ? "outlined" : "standard"}
                        size="small"
                        isediting={isEditing.toString()}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" alignItems="center" mb={2}>
                <InputAdornment position="start" sx={{ mr: 1, color: 'primary.main' }}>
                  <WorkIcon />
                </InputAdornment>
                <Box flexGrow={1}>
                  {!isEditing && (
                    <Typography variant="body1" fontWeight="bold" color="primary.main">
                      Employment Status
                    </Typography>
                  )}
                  <StyledTextField
                    fullWidth
                    label={isEditing ? "Employment Status" : ""}
                    value={editedFields.employmentStatus}
                    onChange={handleChange('employmentStatus')}
                    InputProps={{
                      readOnly: !isEditing,
                    }}
                    variant={isEditing ? "outlined" : "standard"}
                    size="small"
                    isediting={isEditing.toString()}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box display="flex" alignItems="center" mb={2}>
                <InputAdornment position="start" sx={{ mr: 1, color: 'primary.main' }}>
                  <AllergiesIcon />
                </InputAdornment>
                <Box flexGrow={1}>
                  {!isEditing && (
                    <Typography variant="body1" fontWeight="bold" color="primary.main">
                      Allergies
                    </Typography>
                  )}
                  <StyledTextField
                    fullWidth
                    label={isEditing ? "Allergies" : ""}
                    value={editedFields.allergies}
                    onChange={handleChange('allergies')}
                    InputProps={{
                      readOnly: !isEditing,
                    }}
                    variant={isEditing ? "outlined" : "standard"}
                    size="small"
                    isediting={isEditing.toString()}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="flex-start" mb={2}>
                <InputAdornment position="start" sx={{ mr: 1, mt: 1, color: 'primary.main' }}>
                  <NotesIcon />
                </InputAdornment>
                <Box flexGrow={1}>
                  {!isEditing && (
                    <Typography variant="body1" fontWeight="bold" color="primary.main">
                      Notes
                    </Typography>
                  )}
                  <StyledTextField
                    fullWidth
                    multiline
                    rows={4}
                    label={isEditing ? "Notes" : ""}
                    value={editedFields.notes}
                    onChange={handleChange('notes')}
                    InputProps={{
                      readOnly: !isEditing,
                    }}
                    variant={isEditing ? "outlined" : "standard"}
                    size="small"
                    isediting={isEditing.toString()}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </StyledContent>
      </Collapse>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StyledPaper>
  );
};

export default GeneralInformation;
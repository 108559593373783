// client/src/components/admin/EventManager.js

import React, { useState } from 'react';
import axios from 'axios';
import { Typography, List, ListItem, ListItemText, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, Snackbar, Box, IconButton, Card, CardContent, CardActions } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const EventManager = ({ events, setEvents, residents }) => {
  const [open, setOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({ title: '', description: '', date: new Date(), attendees: [] });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [editingEvent, setEditingEvent] = useState(null);

  const handleCreate = async () => {
    try {
      const eventData = {
        ...newEvent,
        createdBy: localStorage.getItem('userId')
      };
      const res = await axios.post('/api/admin/events', eventData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setEvents([...events, res.data]);
      setOpen(false);
      setNewEvent({ title: '', description: '', date: new Date(), attendees: [] });
      setSnackbar({ open: true, message: 'Event created successfully', severity: 'success' });
    } catch (error) {
      console.error('Error creating event:', error);
      setSnackbar({ open: true, message: 'Error creating event', severity: 'error' });
    }
  };

  const handleEdit = (event) => {
    setEditingEvent(event);
    setNewEvent({...event, date: new Date(event.date)});
    setOpen(true);
  };

  const handleUpdate = async () => {
    try {
      const res = await axios.put(`/api/admin/events/${editingEvent._id}`, newEvent, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setEvents(events.map(event => event._id === editingEvent._id ? res.data : event));
      setOpen(false);
      setEditingEvent(null);
      setNewEvent({ title: '', description: '', date: new Date(), attendees: [] });
      setSnackbar({ open: true, message: 'Event updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Error updating event:', error);
      setSnackbar({ open: true, message: 'Error updating event', severity: 'error' });
    }
  };

  const handleDelete = async (eventId) => {
    try {
      await axios.delete(`/api/admin/events/${eventId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setEvents(events.filter(event => event._id !== eventId));
      setSnackbar({ open: true, message: 'Event deleted successfully', severity: 'success' });
    } catch (error) {
      console.error('Error deleting event:', error);
      setSnackbar({ open: true, message: 'Error deleting event', severity: 'error' });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Events</Typography>
        <Button startIcon={<AddIcon />} variant="contained" onClick={() => setOpen(true)}>
          Create Event
        </Button>
      </Box>
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        <List>
          {events.map((event) => (
            <Card key={event._id} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6">{event.title}</Typography>
                <Typography color="textSecondary">
                  {new Date(event.date).toLocaleString()}
                </Typography>
                <Typography variant="body2">{event.description}</Typography>
              </CardContent>
              <CardActions>
                <IconButton onClick={() => handleEdit(event)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDelete(event._id)}>
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Card>
          ))}
        </List>
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{editingEvent ? 'Edit Event' : 'Create New Event'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Title"
            value={newEvent.title}
            onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description"
            value={newEvent.description}
            onChange={(e) => setNewEvent({ ...newEvent, description: e.target.value })}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Date and Time"
              value={newEvent.date}
              onChange={(newDate) => setNewEvent({ ...newEvent, date: newDate })}
              renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
            />
          </LocalizationProvider>
          <Select
            multiple
            value={newEvent.attendees}
            onChange={(e) => setNewEvent({ ...newEvent, attendees: e.target.value })}
            fullWidth
            margin="normal"
            renderValue={(selected) => selected.join(', ')}
          >
            {residents && residents.map((resident) => (
              <MenuItem key={resident._id} value={resident._id}>
                {resident.name}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={editingEvent ? handleUpdate : handleCreate}>
            {editingEvent ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Box>
  );
};

export default EventManager;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Container, Typography, Paper, Box, List, ListItem, ListItemText, Button } from '@mui/material';
import AccessDenied from '../../components/common/AccessDenied';

const Forms = () => {
  const [formsEnabled, setFormsEnabled] = useState(false);
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/resident/forms', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setFormsEnabled(true);
        setForms(response.data.forms);
        setLoading(false);
      } catch (error) {
        if (error.response && error.response.status === 403) {
          setFormsEnabled(false);
        } else {
          setError('An error occurred while fetching data');
        }
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!formsEnabled) return <AccessDenied />;

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            Available Forms
          </Typography>
        </Box>
        {forms.length > 0 ? (
          <List>
            {forms.map((form) => (
              <ListItem key={form._id}>
                <ListItemText primary={form.title} />
                <Button
                  component={Link}
                  to={`/resident/forms/${form._id}`}
                  variant="contained"
                  color="primary"
                >
                  Fill Out Form
                </Button>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body1">No forms available at the moment.</Typography>
        )}
      </Paper>
    </Container>
  );
};

export default Forms;
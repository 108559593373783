import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import {
  Container, Typography, Grid, Card, CardContent, CardActions,
  Button, Box, Chip, CardMedia, Modal, Divider, Stack, TextField, Avatar
} from '@mui/material';
import { 
  Add as AddIcon, 
  Hotel as HotelIcon, 
  SingleBed as SingleBedIcon,
  PhotoCamera as PhotoCameraIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ColorModeContext } from '../../contexts/ColorModeContext';

const AdminHouses = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const [houses, setHouses] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [newHouse, setNewHouse] = useState({
    name: '',
    address: '',
    rooms: [{ name: '', beds: 1 }],
    imageUrl: ''
  });
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchHouses();
  }, []);

  const fetchHouses = async () => {
    try {
      const response = await axios.get('/api/admin/houses', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setHouses(response.data);
    } catch (error) {
      console.error('Error fetching houses:', error);
    }
  };

  const handleAddHouse = async () => {
    try {
      const response = await axios.post('/api/admin/houses', newHouse, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setHouses([...houses, response.data]);
      setNewHouse({ name: '', address: '', rooms: [{ name: '', beds: 1 }], imageUrl: '' });
      setOpenModal(false);
    } catch (error) {
      console.error('Error adding house:', error);
    }
  };

  const handleAddRoom = () => {
    setNewHouse({
      ...newHouse,
      rooms: [...newHouse.rooms, { name: '', beds: 1 }]
    });
  };

  const handleRoomChange = (index, field, value) => {
    const updatedRooms = newHouse.rooms.map((room, i) => 
      i === index ? { ...room, [field]: value } : room
    );
    setNewHouse({ ...newHouse, rooms: updatedRooms });
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('image', file);

      try {
        const response = await axios.post('/api/admin/upload-house-image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setNewHouse(prevHouse => ({ ...prevHouse, imageUrl: response.data.imageUrl }));
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
        <Typography variant="h4" component="h1">
          Houses
        </Typography>
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          onClick={() => {
            setNewHouse({ name: '', address: '', rooms: [{ name: '', beds: 1 }], imageUrl: '' });
            setOpenModal(true);
          }}
        >
          Add House
        </Button>
      </Box>

      <Grid container spacing={3}>
        {houses.map((house) => (
          <Grid item xs={12} sm={6} md={4} key={house._id}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: theme.shadows[10],
                },
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={house.imageUrl || 'https://via.placeholder.com/200x200?text=House+Image'}
                alt={house.name}
              />
              <CardContent>
                <Typography variant="h6" component="div">
                  {house.name}
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  {house.address}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Chip
                    icon={<HotelIcon />}
                    label={`${house.rooms ? house.rooms.length : 0} Rooms`}
                    size="small"
                  />
                  <Chip
                    icon={<SingleBedIcon />}
                    label={`${house.rooms ? house.rooms.reduce((total, room) => total + (room.beds || 0), 0) : 0} Beds`}
                    size="small"
                  />
                </Box>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => navigate(`/admin/houses/${house._id}`)}
                >
                  View Details
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
            Add New House
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Stack spacing={2}>
            <TextField
              label="House Name"
              value={newHouse.name}
              onChange={(e) => setNewHouse({ ...newHouse, name: e.target.value })}
              fullWidth
            />
            <TextField
              label="Address"
              value={newHouse.address}
              onChange={(e) => setNewHouse({ ...newHouse, address: e.target.value })}
              fullWidth
            />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Avatar
                src={newHouse.imageUrl || 'https://via.placeholder.com/100x100?text=House'}
                sx={{ width: 100, height: 100 }}
              />
              <Button
                component="label"
                variant="outlined"
                startIcon={<PhotoCameraIcon />}
              >
                Upload Image
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  style={{ display: 'none' }}
                />
              </Button>
            </Box>
            <Typography variant="subtitle1">Rooms</Typography>
            {newHouse.rooms.map((room, index) => (
              <Box key={index} sx={{ display: 'flex', gap: 2 }}>
                <TextField
                  label="Room Name"
                  value={room.name}
                  onChange={(e) => handleRoomChange(index, 'name', e.target.value)}
                  fullWidth
                />
                <TextField
                  label="Beds"
                  type="number"
                  value={room.beds}
                  onChange={(e) => handleRoomChange(index, 'beds', parseInt(e.target.value))}
                  sx={{ width: '100px' }}
                />
              </Box>
            ))}
            <Button
              startIcon={<AddIcon />}
              variant="outlined"
              onClick={handleAddRoom}
            >
              Add Room
            </Button>
          </Stack>
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleAddHouse}>
              Add House
            </Button>
          </Box>
        </Box>
      </Modal>
    </Container>
  );
};

export default AdminHouses;
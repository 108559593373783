// client/src/components/resident/SobrietyTracker.js

import React from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import { differenceInDays } from 'date-fns';

const SobrietyTracker = ({ sobrietyDate }) => {
  const daysSober = differenceInDays(new Date(), new Date(sobrietyDate));
  const progress = (daysSober % 30) / 30 * 100; // Progress towards next month milestone

  return (
    <Box textAlign="center">
      <Typography variant="h6" gutterBottom>Sobriety Tracker</Typography>
      <Box position="relative" display="inline-flex">
        <CircularProgress variant="determinate" value={progress} size={120} thickness={4} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h4" component="div" color="text.secondary">
            {daysSober}
          </Typography>
        </Box>
      </Box>
      <Typography variant="h6" color="primary" mt={2}>
        Days Sober
      </Typography>
      <Typography variant="body2" color="text.secondary">
        Keep going! You're doing great!
      </Typography>
    </Box>
  );
};

export default SobrietyTracker;
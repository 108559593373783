import React from 'react';
import { 
  Typography, Box, Button, LinearProgress, Tooltip, useTheme
} from '@mui/material';
import { 
  CreditCard as CreditCardIcon, 
  ArrowUpward as ArrowUpwardIcon,
  People as PeopleIcon,
  CalendarToday as CalendarIcon
} from '@mui/icons-material';

const SubscriptionInfo = ({
  subscriptionInfo,
  residentsCount,
  handleManageSubscription,
  handleUpgradeSubscription
}) => {
  const theme = useTheme();
  const progressValue = (residentsCount / subscriptionInfo.maxResidents) * 100;

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <CreditCardIcon sx={{ mr: 1 }} /> Subscription Information
      </Typography>
      
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', mb: 3 }}>
        <Box sx={{ flex: 1, mr: { sm: 2 }, mb: { xs: 2, sm: 0 } }}>
          <Typography variant="subtitle1" gutterBottom>
            Current Tier:
          </Typography>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'grey.100', 
            p: 1.5, 
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`
          }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
              {subscriptionInfo.tier.charAt(0).toUpperCase() + subscriptionInfo.tier.slice(1)}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ flex: 1 }}>
          <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
            <CalendarIcon sx={{ mr: 1, fontSize: 'small' }} /> Current Period Ends:
          </Typography>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'grey.100', 
            p: 1.5, 
            borderRadius: 1,
            border: `1px solid ${theme.palette.divider}`
          }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: theme.palette.text.primary }}>
              {new Date(subscriptionInfo.currentPeriodEnd).toLocaleDateString()}
            </Typography>
          </Box>
        </Box>
      </Box>
      
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
          <PeopleIcon sx={{ mr: 1, fontSize: 'small' }} /> Residents:
        </Typography>
        <Box sx={{ mt: 1 }}>
          <LinearProgress 
            variant="determinate" 
            value={progressValue} 
            sx={{ 
              height: 8, 
              borderRadius: 4,
              backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.1)',
              '& .MuiLinearProgress-bar': {
                borderRadius: 4,
              }
            }}
          />
          <Typography variant="body2" sx={{ mt: 0.5 }}>
            {residentsCount} / {subscriptionInfo.maxResidents} Residents
          </Typography>
        </Box>
      </Box>

      <Box sx={{ mt: 'auto', display: 'flex', gap: 2 }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleManageSubscription}
          startIcon={<CreditCardIcon />}
        >
          Manage Subscription
        </Button>
        {subscriptionInfo.tier !== 'xlarge' && (
          <Tooltip title={subscriptionInfo.tier === 'xlarge' ? "You're already on the highest tier" : ""}>
            <span>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleUpgradeSubscription}
                disabled={subscriptionInfo.tier === 'xlarge'}
                startIcon={<ArrowUpwardIcon />}
              >
                Upgrade
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};

export default SubscriptionInfo;
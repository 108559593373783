/**
 * A React component that displays a card with the count of active subscriptions.
 *
 * @param {object} props - The component props.
 * @param {number} props.count - The count of active subscriptions.
 * @returns {JSX.Element} - The ActiveSubscriptionsCard component.
 */
import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';

const ActiveSubscriptionsCard = ({ count }) => {
  return (
    <Card>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Active Subscriptions
        </Typography>
        <Typography variant="h5" component="h2">
          {count}
        </Typography>
        <SubscriptionsIcon color="primary" />
      </CardContent>
    </Card>
  );
};

export default ActiveSubscriptionsCard;
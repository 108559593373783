import React from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Build as BuildIcon, 
  AttachMoney as AttachMoneyIcon, 
  Feedback as FeedbackIcon, 
  Description as DescriptionIcon,
  Speed as SpeedIcon // New icon for Quick Access
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

const FeatureButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const QuickActions = ({
  maintenanceRequestEnabled,
  rentPaymentEnabled,
  grievanceRequestEnabled,
  formsEnabled
}) => {
  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <SpeedIcon sx={{ mr: 1 }} /> Quick Access
      </Typography>
      <Grid container spacing={2} sx={{ flexGrow: 1 }}>
        {maintenanceRequestEnabled && (
          <Grid item xs={6} sm={6} md={6} lg={6} sx={{ height: { xs: 100, sm: 120, md: '50%' } }}>
            <FeatureButton
              variant="outlined"
              color="primary"
              startIcon={<BuildIcon />}
              component={Link}
              to="/admin/maintenance-requests"
            >
              Maintenance Requests
            </FeatureButton>
          </Grid>
        )}
        {rentPaymentEnabled && (
          <Grid item xs={6} sm={6} md={6} lg={6} sx={{ height: { xs: 100, sm: 120, md: '50%' } }}>
            <FeatureButton
              variant="outlined"
              color="primary"
              startIcon={<AttachMoneyIcon />}
              component={Link}
              to="/admin/rent-payments"
            >
              Rent Payments
            </FeatureButton>
          </Grid>
        )}
        {grievanceRequestEnabled && (
          <Grid item xs={6} sm={6} md={6} lg={6} sx={{ height: { xs: 100, sm: 120, md: '50%' } }}>
            <FeatureButton
              variant="outlined"
              color="primary"
              startIcon={<FeedbackIcon />}
              component={Link}
              to="/admin/grievances"
            >
              Grievances
            </FeatureButton>
          </Grid>
        )}
        {formsEnabled && (
          <Grid item xs={6} sm={6} md={6} lg={6} sx={{ height: { xs: 100, sm: 120, md: '50%' } }}>
            <FeatureButton
              variant="outlined"
              color="primary"
              startIcon={<DescriptionIcon />}
              component={Link}
              to="/admin/forms"
            >
              Manage Forms
            </FeatureButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default QuickActions;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper, Typography 
} from '@mui/material';

const FormSubmissions = ({ formId }) => {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const response = await axios.get(`/api/admin/forms/${formId}/submissions`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setSubmissions(response.data);
        setLoading(false);
      } catch (error) {
        setError('An error occurred while fetching submissions');
        setLoading(false);
      }
    };

    fetchSubmissions();
  }, [formId]);

  if (loading) return <div>Loading submissions...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <TableContainer component={Paper}>
      <Typography variant="h6" gutterBottom component="div" sx={{ p: 2 }}>
        Form Submissions
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Submission Date</TableCell>
            <TableCell>Resident</TableCell>
            {submissions[0] && Object.keys(submissions[0].data).map((key) => (
              <TableCell key={key}>{key}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {submissions.map((submission, index) => (
            <TableRow key={index}>
              <TableCell>{new Date(submission.createdAt).toLocaleString()}</TableCell>
              <TableCell>{submission.resident.name}</TableCell>
              {Object.values(submission.data).map((value, i) => (
                <TableCell key={i}>{value}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FormSubmissions;
import React, { useState } from 'react';
import axios from 'axios';
import {
  Typography, Box, Chip, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField,
  Paper, Grid, InputAdornment, Snackbar, Collapse, IconButton, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { LocalBar as SobrietyIcon, Cake as CakeIcon, Person as PersonIcon, Edit as EditIcon, Save as SaveIcon, ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { IMaskInput } from 'react-imask';
import { parseISO, format, isValid, startOfDay } from 'date-fns';

const StyledPaper = styled(Paper)(({ theme }) => ({
  overflow: 'hidden',
  transition: 'all 0.3s',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2, 3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const StyledTextField = styled(TextField)(({ theme, isediting }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: isediting === 'true' ? theme.palette.background.paper : 'transparent',
    transition: theme.transitions.create(['background-color', 'box-shadow']),
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: isediting === 'true' ? `1px solid ${theme.palette.primary.main}` : 'none',
  },
  '& .MuiInputBase-input': {
    padding: isediting === 'true' ? theme.spacing(1) : 0,
    fontWeight: 'normal',
    color: theme.palette.text.primary,
  },
  '& .MuiInputLabel-root': {
    display: isediting === 'true' ? 'block' : 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: isediting === 'true' ? theme.palette.primary.main : 'transparent',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: isediting === 'true' ? theme.palette.primary.main : 'transparent',
  },
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
  },
  transition: 'all 0.3s',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  border: `2px solid ${theme.palette.primary.contrastText}`,
}));

const DateMaskInput = React.forwardRef(function DateMaskInput(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00/00/0000"
      definitions={{
        '0': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AnimatedIconButton = styled(IconButton)(({ theme }) => ({
  transition: 'transform 0.3s',
  '&.expanded': {
    transform: 'rotate(180deg)',
  },
}));

const ExpandButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.dark,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  marginRight: theme.spacing(2),
  transition: 'transform 0.3s',
  '&.expanded': {
    transform: 'rotate(180deg)',
  },
}));

const TitleBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const InfoItem = ({ icon, label, value, isEditing, onChange, InputProps }) => (
  <Grid item xs={12} sm={6}>
    <Box display="flex" alignItems="center" mb={2}>
      <InputAdornment position="start" sx={{ mr: 1, color: 'primary.main' }}>
        {icon}
      </InputAdornment>
      <Box flexGrow={1}>
        {!isEditing && (
          <Typography variant="body1" fontWeight="bold" color="primary.main">
            {label}
          </Typography>
        )}
        <StyledTextField
          fullWidth
          label={isEditing ? label : ''}
          value={value}
          onChange={onChange}
          InputProps={{
            readOnly: !isEditing,
            ...InputProps,
          }}
          variant={isEditing ? "outlined" : "standard"}
          size="small"
          isediting={isEditing.toString()}
        />
      </Box>
    </Box>
  </Grid>
);

const RecoveryInformation = ({ resident, setResident, isMobile }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [editedFields, setEditedFields] = useState({
    sobrietyDate: resident.sobrietyDate ? format(parseISO(resident.sobrietyDate), 'MM/dd/yyyy') : '',
    dateOfFirstUse: resident.dateOfFirstUse ? format(parseISO(resident.dateOfFirstUse), 'MM/dd/yyyy') : '',
    levelOfCare: resident.levelOfCare || '',
    recoveryPrograms: resident.recoveryPrograms || [],
  });
  const [drugsOfChoice, setDrugsOfChoice] = useState(resident.drugsOfChoice || []);
  const [addingProgram, setAddingProgram] = useState(false);
  const [newProgram, setNewProgram] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const drugOptions = [
    'Alcohol',
    'Cocaine',
    'Heroin',
    'Methamphetamine',
    'Prescription Opioids',
    'Marijuana',
    'Benzodiazepines',
    'Amphetamines',
    'MDMA (Ecstasy)',
    'LSD',
    'Ketamine',
    'Synthetic Cannabinoids',
    'Inhalants',
    'PCP',
    'Hallucinogens',
    'Prescription Stimulants',
    'Fentanyl',
    'GHB',
    'Steroids',
    'Other'
  ];

  const handleChange = (field) => (event) => {
    if (field === 'drugsOfChoice') {
      setDrugsOfChoice(event.target.value);
    } else {
      setEditedFields((prev) => ({
        ...prev,
        [field]: event.target.value,
      }));
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      // Convert dates to ISO format before sending to server
      const sobrietyDate = editedFields.sobrietyDate;
      const dateOfFirstUse = editedFields.dateOfFirstUse;
      const [sobrietyMonth, sobrietyDay, sobrietyYear] = sobrietyDate.split('/');
      const [firstUseMonth, firstUseDay, firstUseYear] = dateOfFirstUse.split('/');
      
      const sobrietyDateISO = startOfDay(new Date(sobrietyYear, sobrietyMonth - 1, sobrietyDay)).toISOString();
      const firstUseDateISO = startOfDay(new Date(firstUseYear, firstUseMonth - 1, firstUseDay)).toISOString();

      if (!isValid(parseISO(sobrietyDateISO)) || !isValid(parseISO(firstUseDateISO))) {
        throw new Error('Invalid date format');
      }

      const dataToSend = {
        ...editedFields,
        drugsOfChoice: drugsOfChoice,
        sobrietyDate: sobrietyDateISO,
        dateOfFirstUse: firstUseDateISO,
      };

      console.log('Sending data:', dataToSend);
      const response = await axios.put(
        `/api/admin/resident/${resident._id}`,
        dataToSend,
        {
          headers: { 
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      
      console.log('Response:', response.data);
      if (typeof setResident === 'function') {
        // Format the dates back to MM/dd/yyyy for display
        const updatedResident = {
          ...response.data,
          sobrietyDate: format(parseISO(response.data.sobrietyDate), 'MM/dd/yyyy'),
          dateOfFirstUse: format(parseISO(response.data.dateOfFirstUse), 'MM/dd/yyyy'),
        };
        setResident(updatedResident);
      } else {
        console.warn('setResident is not a function. Unable to update resident state.');
      }
      setIsEditing(false);
      setSnackbarMessage('Recovery information updated successfully');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error updating recovery information:', error);
      console.error('Error response:', error.response);
      setSnackbarMessage(`Error updating recovery information: ${error.response?.data?.message || error.message}`);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleAddProgram = () => {
    setEditedFields(prev => ({
      ...prev,
      recoveryPrograms: [...prev.recoveryPrograms, newProgram]
    }));
    setAddingProgram(false);
    setNewProgram('');
  };

  const handleDeleteProgram = (index) => {
    setEditedFields(prev => ({
      ...prev,
      recoveryPrograms: prev.recoveryPrograms.filter((_, i) => i !== index)
    }));
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <StyledPaper elevation={2}>
      <StyledHeader>
        <TitleBox>
          <ExpandButton
            onClick={handleToggleExpand}
            className={isExpanded ? 'expanded' : ''}
            size={isMobile ? "small" : "large"}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ExpandButton>
          <Typography variant={isMobile ? "subtitle1" : "h6"} fontWeight="bold">
            Recovery Information
          </Typography>
        </TitleBox>
        <AnimatedButton
          onClick={isEditing ? handleSave : handleEdit}
          startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
          size={isMobile ? "small" : "medium"}
        >
          {isEditing ? 'Save' : 'Edit'}
        </AnimatedButton>
      </StyledHeader>
      <Collapse in={isExpanded}>
        <StyledContent>
          <Grid container spacing={isMobile ? 2 : 3}>
            <InfoItem
              icon={<SobrietyIcon />}
              label="Sobriety Date"
              value={editedFields.sobrietyDate}
              isEditing={isEditing}
              onChange={handleChange('sobrietyDate')}
              InputProps={{
                inputComponent: DateMaskInput,
              }}
            />
            <InfoItem
              icon={<CakeIcon />}
              label="Date of First Use"
              value={editedFields.dateOfFirstUse}
              isEditing={isEditing}
              onChange={handleChange('dateOfFirstUse')}
              InputProps={{
                inputComponent: DateMaskInput,
              }}
            />
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold" color="primary.main" gutterBottom>
                Drug(s) of Choice
              </Typography>
              {isEditing ? (
                <FormControl fullWidth>
                  <InputLabel id="drugs-of-choice-label">Drugs of Choice</InputLabel>
                  <Select
                    labelId="drugs-of-choice-label"
                    id="drugs-of-choice"
                    multiple
                    value={drugsOfChoice}
                    onChange={handleChange('drugsOfChoice')}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                  >
                    {drugOptions.map((drug) => (
                      <MenuItem key={drug} value={drug}>
                        {drug}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <Box mb={2}>
                  {drugsOfChoice.map((drug, index) => (
                    <Chip
                      key={index}
                      label={drug}
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))}
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight="bold" color="primary.main" gutterBottom>
                Recovery Programs
              </Typography>
              <Box mb={2}>
                {editedFields.recoveryPrograms.map((program, index) => (
                  <Chip
                    key={index}
                    label={program}
                    onDelete={isEditing ? () => handleDeleteProgram(index) : undefined}
                    sx={{ mr: 1, mb: 1 }}
                  />
                ))}
                {isEditing && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setAddingProgram(true)}
                    sx={{ mt: 1 }}
                  >
                    Add Program
                  </Button>
                )}
              </Box>
            </Grid>
            <InfoItem
              icon={<PersonIcon />}
              label="Level of Care"
              value={editedFields.levelOfCare}
              isEditing={isEditing}
              onChange={handleChange('levelOfCare')}
            />
          </Grid>
        </StyledContent>
      </Collapse>

      <Dialog open={addingProgram} onClose={() => setAddingProgram(false)}>
        <DialogTitle>Add Recovery Program</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Program Name"
            fullWidth
            value={newProgram}
            onChange={(e) => setNewProgram(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddingProgram(false)}>Cancel</Button>
          <Button onClick={handleAddProgram}>Add</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StyledPaper>
  );
};

export default RecoveryInformation;
import React, { useState } from 'react';
import axios from 'axios';
import {
  Typography, Button, Paper, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextField,
  Box, Collapse, Snackbar, Grid
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@mui/lab';
import { Edit as EditIcon, Add as AddIcon, ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Save as SaveIcon } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parseISO } from 'date-fns';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  overflow: 'hidden',
  transition: 'all 0.3s',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2, 3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
  },
  transition: 'all 0.3s',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  border: `2px solid ${theme.palette.primary.contrastText}`,
}));

const ExpandButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.dark,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  marginRight: theme.spacing(2),
  transition: 'transform 0.3s',
  '&.expanded': {
    transform: 'rotate(180deg)',
  },
}));

const TitleBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const StyledTimelineItem = styled(TimelineItem)(({ theme }) => ({
  '&:before': {
    display: 'none',
  },
}));

const StyledTimelineContent = styled(TimelineContent)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: theme.spacing(2),
}));

const StyledTimelinePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
  transition: 'box-shadow 0.3s',
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TreatmentHistoryBox = ({ resident, setResident, isMobile }) => {
  const [openTreatmentHistoryDialog, setOpenTreatmentHistoryDialog] = useState(false);
  const [editingTreatmentHistoryIndex, setEditingTreatmentHistoryIndex] = useState(null);
  const [treatmentHistoryForm, setTreatmentHistoryForm] = useState({
    facility: '',
    startDate: null,
    endDate: null,
    type: '',
    notes: '',
  });
  const [isExpanded, setIsExpanded] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleTreatmentHistoryChange = (field, value) => {
    setTreatmentHistoryForm(prev => ({ ...prev, [field]: value }));
  };

  const handleEditTreatmentHistory = (index) => {
    const treatment = resident.treatmentHistory[index];
    setTreatmentHistoryForm({
      ...treatment,
      startDate: treatment.startDate ? parseISO(treatment.startDate) : null,
      endDate: treatment.endDate ? parseISO(treatment.endDate) : null,
    });
    setEditingTreatmentHistoryIndex(index);
    setOpenTreatmentHistoryDialog(true);
  };

  const handleAddTreatmentHistory = async () => {
    try {
      let updatedTreatmentHistory;
      if (editingTreatmentHistoryIndex !== null) {
        updatedTreatmentHistory = [...resident.treatmentHistory];
        updatedTreatmentHistory[editingTreatmentHistoryIndex] = {
          ...treatmentHistoryForm,
          startDate: treatmentHistoryForm.startDate.toISOString(),
          endDate: treatmentHistoryForm.endDate ? treatmentHistoryForm.endDate.toISOString() : null
        };
      } else {
        updatedTreatmentHistory = [
          ...(resident.treatmentHistory || []),
          {
            ...treatmentHistoryForm,
            startDate: treatmentHistoryForm.startDate.toISOString(),
            endDate: treatmentHistoryForm.endDate ? treatmentHistoryForm.endDate.toISOString() : null
          }
        ];
      }
      
      const response = await axios.put(`/api/admin/resident/${resident._id}`, { treatmentHistory: updatedTreatmentHistory }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      
      setResident(response.data);
      setOpenTreatmentHistoryDialog(false);
      setEditingTreatmentHistoryIndex(null);
      setTreatmentHistoryForm({
        facility: '',
        startDate: null,
        endDate: null,
        type: '',
        notes: '',
      });
      setSnackbarMessage('Treatment history updated successfully');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error adding/updating treatment history:', error);
      setSnackbarMessage(`Error updating treatment history: ${error.response?.data?.message || error.message}`);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <StyledPaper elevation={2}>
      <StyledHeader>
        <TitleBox>
          <ExpandButton
            onClick={handleToggleExpand}
            className={isExpanded ? 'expanded' : ''}
            size={isMobile ? "small" : "large"}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ExpandButton>
          <Typography variant={isMobile ? "subtitle1" : "h6"} fontWeight="bold">
            Treatment History
          </Typography>
        </TitleBox>
        <AnimatedButton
          onClick={() => {
            setTreatmentHistoryForm({
              facility: '',
              startDate: null,
              endDate: null,
              type: '',
              notes: '',
            });
            setEditingTreatmentHistoryIndex(null);
            setOpenTreatmentHistoryDialog(true);
          }}
          startIcon={<AddIcon />}
          size={isMobile ? "small" : "medium"}
        >
          Add Treatment
        </AnimatedButton>
      </StyledHeader>
      <Collapse in={isExpanded}>
        <StyledContent>
          <Timeline>
            {resident.treatmentHistory && resident.treatmentHistory.map((treatment, index) => (
              <StyledTimelineItem key={index}>
                <TimelineSeparator>
                  <TimelineDot color="primary" />
                  <TimelineConnector />
                </TimelineSeparator>
                <StyledTimelineContent>
                  <StyledTimelinePaper elevation={3}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs>
                        <Typography variant="h6" component="h3">
                          {treatment.facility}
                        </Typography>
                        <Typography>{treatment.type}</Typography>
                        <Typography variant="body2" color="textSecondary">
                          {new Date(treatment.startDate).toLocaleDateString()} - {treatment.endDate ? new Date(treatment.endDate).toLocaleDateString() : 'Present'}
                        </Typography>
                        {treatment.notes && (
                          <Typography variant="body2" sx={{ mt: 1 }}>
                            Notes: {treatment.notes}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item>
                        <IconButton size="small" onClick={() => handleEditTreatmentHistory(index)}>
                          <EditIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </StyledTimelinePaper>
                </StyledTimelineContent>
              </StyledTimelineItem>
            ))}
          </Timeline>
        </StyledContent>
      </Collapse>

      <Dialog open={openTreatmentHistoryDialog} onClose={() => setOpenTreatmentHistoryDialog(false)}>
        <DialogTitle>{editingTreatmentHistoryIndex !== null ? 'Edit Treatment History' : 'Add Treatment History'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Facility Name"
            fullWidth
            value={treatmentHistoryForm.facility}
            onChange={(e) => handleTreatmentHistoryChange('facility', e.target.value)}
          />
          <TextField
            margin="dense"
            label="Treatment Type"
            fullWidth
            value={treatmentHistoryForm.type}
            onChange={(e) => handleTreatmentHistoryChange('type', e.target.value)}
          />
          <DatePicker
            label="Start Date"
            value={treatmentHistoryForm.startDate}
            onChange={(newDate) => handleTreatmentHistoryChange('startDate', newDate)}
            renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
          />
          <DatePicker
            label="End Date"
            value={treatmentHistoryForm.endDate}
            onChange={(newDate) => handleTreatmentHistoryChange('endDate', newDate)}
            renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
          />
          <TextField
            margin="dense"
            label="Additional Notes"
            fullWidth
            multiline
            rows={4}
            value={treatmentHistoryForm.notes}
            onChange={(e) => handleTreatmentHistoryChange('notes', e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenTreatmentHistoryDialog(false)}>Cancel</Button>
          <Button onClick={handleAddTreatmentHistory} startIcon={<SaveIcon />}>
            {editingTreatmentHistoryIndex !== null ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StyledPaper>
  );
};

export default TreatmentHistoryBox;
/**
 * The `Login` component is responsible for rendering the login page of the application.
 * It handles user authentication by allowing users to enter their email and password,
 * and then calls the `login` function from the `useAuth` hook to authenticate the user.
 * If the authentication is successful, the user is redirected to the appropriate dashboard
 * based on their role (superadmin, admin, or resident).
 * The component also includes error handling and a toggle for password visibility.
 */
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { 
  TextField, Button, Container, Typography, Box, Grid, Paper, 
  CssBaseline, InputAdornment, IconButton, useTheme
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../hooks/useAuth';
import logo from '../assets/hopelinxlogo.png';
import { Button as MuiButton, styled } from '@mui/material';

// Styled component for custom buttons
const StyledButton = styled(MuiButton)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 'bold',
  borderRadius: theme.shape.borderRadius,
  padding: '10px 20px',
  margin: '5px',
  transition: 'all 0.3s',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[4],
  },
}));

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    
    const navigate = useNavigate();
    const { login, user } = useAuth();
    const theme = useTheme();

    const redirectToDashboard = useCallback((role) => {
        switch (role) {
            case 'superadmin':
                navigate('/superadmin');
                break;
            case 'admin':
                navigate('/admin');
                break;
            case 'resident':
                navigate('/resident');
                break;
            default:
                navigate('/');
        }
    }, [navigate]);

    useEffect(() => {
        if (user) {
            redirectToDashboard(user.role);
        }
    }, [user, redirectToDashboard]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const loggedInUser = await login(email, password);
            if (loggedInUser && loggedInUser.role) {
                redirectToDashboard(loggedInUser.role);
            } else {
                setError('Invalid user data received from server');
            }
        } catch (error) {
            console.error('Login error:', error);
            setError(error.response?.data?.message || 'Invalid email or password');
        }
    };

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
            }}
        >
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Paper
                    elevation={6}
                    sx={{
                        padding: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: 2,
                        backgroundColor: 'rgba(255, 255, 255, 0.95)',
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            width: '100%',
                            maxWidth: 250,
                            height: 'auto',
                            mb: 4
                        }}
                        alt="Hopelink logo"
                        src={logo}
                    />
                    
                    <Typography 
                        component="h1" 
                        variant="h5" 
                        sx={{ 
                            mb: 4, 
                            color: theme.palette.primary.main, 
                            fontWeight: 'bold',
                            textAlign: 'center',
                            width: '100%'
                        }}
                    >
                        Sign in to your account
                    </Typography>
                    
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            variant="outlined"
                            sx={{ 
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.dark,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    backgroundColor: 'white',
                                },
                                '& .MuiInputLabel-root': {
                                    color: theme.palette.primary.main,
                                },
                                '& .MuiOutlinedInput-input': {
                                    color: 'black', // Explicitly set input text color to black
                                },
                            }}
                        />
                        
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            variant="outlined"
                            sx={{ 
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.dark,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    backgroundColor: 'white',
                                },
                                '& .MuiInputLabel-root': {
                                    color: theme.palette.primary.main,
                                },
                                '& .MuiOutlinedInput-input': {
                                    color: 'black', // Explicitly set input text color to black
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleTogglePasswordVisibility}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        
                        {error && (
                            <Typography color="error" sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
                                {error}
                            </Typography>
                        )}
                        
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ 
                                mt: 3, 
                                mb: 4,  // Increased bottom margin
                                py: 1.5, 
                                fontSize: '1.1rem',
                                fontWeight: 'bold',
                            }}
                        >
                            Sign In
                        </Button>
                        
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12}>
                                <StyledButton
                                    component={Link}
                                    to="/"
                                    variant="outlined"
                                    fullWidth
                                    sx={{ 
                                        color: theme.palette.primary.main,
                                        borderColor: theme.palette.primary.main,
                                    }}
                                >
                                    Back to Home
                                </StyledButton>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledButton
                                    component={Link}
                                    to="/register/admin"
                                    variant="contained"
                                    fullWidth
                                    sx={{ 
                                        backgroundColor: theme.palette.secondary.main,
                                        color: theme.palette.secondary.contrastText,
                                        '&:hover': {
                                            backgroundColor: theme.palette.secondary.dark,
                                        },
                                    }}
                                >
                                    Register as Admin
                                </StyledButton>
                            </Grid>
                            <Grid item xs={6}>
                                <StyledButton
                                    component={Link}
                                    to="/register/resident"
                                    variant="contained"
                                    fullWidth
                                    sx={{ 
                                        backgroundColor: theme.palette.success.main,
                                        color: theme.palette.success.contrastText,
                                        '&:hover': {
                                            backgroundColor: theme.palette.success.dark,
                                        },
                                    }}
                                >
                                    Register as Resident
                                </StyledButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
};

export default Login;
/**
 * Provides an AuthContext and AuthProvider component for managing user authentication state in a React application.
 * 
 * The AuthProvider component handles loading the user from localStorage, logging in, and logging out. It exposes the
 * following values through the AuthContext:
 * 
 * - `user`: The current authenticated user object, or `null` if the user is not logged in.
 * - `isAuthenticated`: A boolean indicating whether the user is authenticated or not.
 * - `setUser`: A function that updates the user state.
 * - `setIsAuthenticated`: A function that updates the isAuthenticated state.
 * - `loading`: A boolean indicating whether the user is currently being loaded from localStorage.
 * - `login(email, password)`: A function that logs the user in and updates the user state.
 * - `logout()`: A function that logs the user out and clears the user state.
 */
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// Create the AuthContext
export const AuthContext = createContext();

// AuthProvider component
export const AuthProvider = ({ children }) => {
  // State variables
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  // Effect hook to load user data on component mount
  useEffect(() => {
    const loadUser = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        try {
          const res = await axios.get('/api/auth/profile');
          setUser(res.data);
          setIsAuthenticated(true);
        } catch (error) {
          console.error('Error loading user:', error);
          localStorage.removeItem('token');
          delete axios.defaults.headers.common['Authorization'];
        }
      }
      setLoading(false);
    };

    loadUser();
  }, []);

  // Login function
  const login = async (email, password) => {
    try {
      const response = await axios.post('/api/auth/login', { email, password });
      const { token, ...userData } = response.data;
      setUser(userData);
      localStorage.setItem('token', token);
      setIsAuthenticated(true);
      
      // Fetch the full user profile immediately after login
      const profileResponse = await axios.get('/api/auth/profile', {
        headers: { Authorization: `Bearer ${token}` }
      });
      const fullUserData = profileResponse.data;
      setUser(fullUserData);
      localStorage.setItem('user', JSON.stringify(fullUserData));
      
      return fullUserData;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  };

  // Logout function
  const logout = () => {
    setUser(null);
    setIsAuthenticated(false);
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
  };

  // Provide the AuthContext
  return (
    <AuthContext.Provider value={{ user, setUser, isAuthenticated, setIsAuthenticated, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
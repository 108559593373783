import React from 'react';
import {
  Box, Typography, Avatar, Chip,
  useTheme, useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  Dashboard as DashboardIcon,
  Stars as StarsIcon,
  AccessTime as AccessTimeIcon
} from '@mui/icons-material';

const HeaderWrapper = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  borderRadius: theme.shape.borderRadius * 2, // Increased border radius
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'url("/admin-header-bg.jpg")', // Make sure to add this image to your public folder
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    opacity: 0.2,
  }
}));

const StyledLastLogin = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'rgba(255,255,255,0.1)',
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5, 1),
  marginTop: theme.spacing(1),
}));

const getGreeting = () => {
  const hour = new Date().getHours();
  if (hour < 12) return 'Good Morning';
  if (hour < 18) return 'Good Afternoon';
  return 'Good Evening';
};

const StyledChip = styled(Chip)(({ theme }) => ({
  bgcolor: 'rgba(255,255,255,0.2)',
  color: 'white',
  '& .MuiChip-icon': {
    color: 'white',
  },
  '& .MuiChip-label': {
    fontWeight: 'bold',
  },
  margin: theme.spacing(0.5),
  minWidth: 150,
}));

const AdminHeader = ({ 
  adminName = 'Admin', 
  adminAvatarUrl, 
  residentsCount = 0, 
  lastLoginDate = 'N/A',
  subscriptionTier = 'Basic'
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const capitalizedTier = subscriptionTier?.charAt(0).toUpperCase() + subscriptionTier?.slice(1) || 'Basic';

  return (
    <HeaderWrapper>
      <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" mb={isMobile ? 2 : 0}>
          <Avatar
            src={adminAvatarUrl}
            alt={adminName}
            sx={{ width: 80, height: 80, mr: 2, border: '3px solid white' }}
          />
          <Box>
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              {getGreeting()}, {adminName}!
            </Typography>
            <StyledLastLogin>
              <AccessTimeIcon sx={{ fontSize: 16, mr: 1, color: 'rgba(255,255,255,0.7)' }} />
              <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                Last login: {lastLoginDate}
              </Typography>
            </StyledLastLogin>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems={isMobile ? 'center' : 'flex-end'}>
          <StyledChip
            icon={<DashboardIcon />}
            label={`${residentsCount} Residents`}
          />
          <StyledChip
            icon={<StarsIcon />}
            label={`${capitalizedTier} Plan`}
          />
        </Box>
      </Box>
      {/* Box containing icon buttons removed */}
    </HeaderWrapper>
  );
};

export default AdminHeader;

import React from 'react';
import { Container, Typography, Paper, Grid } from '@mui/material';
import SMTPSettings from '../../components/superadmin/SMTPSettings';
import EmailTemplateManager from '../../components/superadmin/EmailTemplateManager';

const SuperAdminSettings = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Super Admin Settings
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <SMTPSettings />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <EmailTemplateManager />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SuperAdminSettings;
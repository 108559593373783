import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const CTATitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Intro Rust", sans-serif',
  fontWeight: 700,
  fontSize: '3rem',
  letterSpacing: '0.05em',
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',
  },
}));

const CTA = () => {
  return (
    <Box sx={{ 
      bgcolor: 'primary.main', 
      color: 'primary.contrastText', 
      py: 12,
      backgroundImage: 'linear-gradient(45deg, #FF8C00 30%, #E91E63 90%)',
      position: 'relative',
      overflow: 'hidden',
    }}
      id="cta"
    >
      <Container maxWidth="lg">
      <Box sx={{ position: 'relative', zIndex: 1 }}>
          <CTATitle variant="h2" align="center">
            Ready to Transform Your Recovery House?
          </CTATitle>
          <Typography variant="h5" align="center" paragraph sx={{ mb: 6, maxWidth: '800px', mx: 'auto' }}>
            Join the HopeLink community and empower your residents on their journey to recovery.
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
            <Button
              component={Link}
              to="/register/admin"
              variant="contained"
              color="secondary"
              size="large"
              sx={{ 
                px: { xs: 3, md: 4 },
                py: 1.5,
                fontSize: { xs: '1rem', md: '1.1rem' },
                fontWeight: 600,
                color: 'common.white',
                transition: 'all 0.3s',
                '&:hover': {
                  backgroundColor: 'secondary.light',
                  transform: 'translateY(-3px)',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              Start Your Free Trial
            </Button>
            <Button
              component={Link}
              to="/contact"
              variant="outlined"
              size="large"
              sx={{ 
                borderColor: 'common.white', 
                color: 'common.white',
                px: { xs: 3, md: 4 },
                py: 1.5,
                fontSize: { xs: '1rem', md: '1.1rem' },
                fontWeight: 600,
                borderWidth: 2,
                transition: 'all 0.3s',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  transform: 'translateY(-3px)',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              Contact Sales
            </Button>
          </Box>
        </Box>
        <Box sx={{
          position: 'absolute',
          top: -100,
          left: -100,
          width: 300,
          height: 300,
          borderRadius: '50%',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        }} />
        <Box sx={{
          position: 'absolute',
          bottom: -50,
          right: -50,
          width: 200,
          height: 200,
          borderRadius: '50%',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
        }} />
      </Container>
    </Box>
  );
};

export default CTA;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Container, Typography, Paper, Box, Button, Grid, 
  List, ListItem, ListItemText, ListItemSecondaryAction, IconButton 
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Visibility as VisibilityIcon, Delete as DeleteIcon } from '@mui/icons-material';
import AccessDenied from '../../components/common/AccessDenied';
import FormBuilder from '../../components/admin/forms/FormBuilder';
import FormPreview from '../../components/admin/forms/FormPreview';
import FormSubmissions from '../../components/admin/forms/FormSubmissions';

const Forms = () => {
  const [formsEnabled, setFormsEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [forms, setForms] = useState([]);
  const [currentForm, setCurrentForm] = useState(null);
  const [mode, setMode] = useState('list'); // 'list', 'create', 'edit', 'preview', 'submissions'

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('/api/admin/forms', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setFormsEnabled(true);
      setForms(response.data.forms);
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        setFormsEnabled(false);
      } else {
        setError('An error occurred while fetching data');
      }
      setLoading(false);
    }
  };

  const handleCreateForm = () => {
    setCurrentForm(null);
    setMode('create');
  };

  const handleEditForm = (form) => {
    setCurrentForm(form);
    setMode('edit');
  };

  const handlePreviewForm = (form) => {
    setCurrentForm(form);
    setMode('preview');
  };

  const handleViewSubmissions = (form) => {
    setCurrentForm(form);
    setMode('submissions');
  };

  const handleDeleteForm = async (formId) => {
    if (window.confirm('Are you sure you want to delete this form?')) {
      try {
        await axios.delete(`/api/admin/forms/${formId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        fetchData(); // Refresh the forms list
      } catch (error) {
        setError('An error occurred while deleting the form');
      }
    }
  };

  const handleSaveForm = async (formData) => {
    try {
      if (mode === 'create') {
        await axios.post('/api/admin/forms', formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
      } else {
        await axios.put(`/api/admin/forms/${currentForm._id}`, formData, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
      }
      fetchData(); // Refresh the forms list
      setMode('list');
    } catch (error) {
      setError('An error occurred while saving the form');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!formsEnabled) return <AccessDenied />;

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" component="h1">
            Forms Management
          </Typography>
          {mode === 'list' && (
            <Button 
              variant="contained" 
              color="primary" 
              startIcon={<AddIcon />}
              onClick={handleCreateForm}
            >
              Create New Form
            </Button>
          )}
          {mode !== 'list' && (
            <Button 
              variant="outlined" 
              onClick={() => setMode('list')}
            >
              Back to List
            </Button>
          )}
        </Box>

        {mode === 'list' && (
          <List>
            {forms.map((form) => (
              <ListItem key={form._id}>
                <ListItemText primary={form.title} secondary={`Created: ${new Date(form.createdAt).toLocaleDateString()}`} />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="edit" onClick={() => handleEditForm(form)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="preview" onClick={() => handlePreviewForm(form)}>
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="submissions" onClick={() => handleViewSubmissions(form)}>
                    <VisibilityIcon />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteForm(form._id)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}

        {(mode === 'create' || mode === 'edit') && (
          <FormBuilder 
            initialForm={currentForm} 
            onSave={handleSaveForm} 
            mode={mode}
          />
        )}

        {mode === 'preview' && currentForm && (
          <FormPreview form={currentForm} />
        )}

        {mode === 'submissions' && currentForm && (
          <FormSubmissions formId={currentForm._id} />
        )}
      </Paper>
    </Container>
  );
};

export default Forms;
/**
 * Handles the registration confirmation process for the application.
 * 
 * This component is responsible for confirming a user's registration by making a request to the server with the provided session ID. If the confirmation is successful, the user's information is stored in the application's state and the user is redirected to the admin dashboard after a short delay.
 * 
 * @component
 * @returns {JSX.Element} The registration confirmation UI, which displays a loading spinner or an error message if the confirmation fails.
 */
import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Typography, CircularProgress } from '@mui/material';
import { AuthContext } from '../../contexts/AuthContext';

const RegistrationConfirmation = () => {
  // State for managing loading and error states
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Hooks for navigation and location
  const location = useLocation();
  const navigate = useNavigate();
  
  // Context for managing authentication state
  const { setUser, setIsAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    const confirmRegistration = async () => {
      // Extract session ID from URL parameters
      const searchParams = new URLSearchParams(location.search);
      const sessionId = searchParams.get('session_id');

      // Check if session ID is present
      if (!sessionId) {
        setError('Invalid session ID');
        setLoading(false);
        return;
      }

      try {
        // Make API call to confirm registration
        const response = await axios.get(`/api/auth/register/confirm?session_id=${sessionId}`);
        
        // Store authentication token
        localStorage.setItem('token', response.data.token);
        
        // Update authentication context
        setUser(response.data.user);
        setIsAuthenticated(true);
        
        setLoading(false);
        
        // Redirect to admin dashboard after a delay
        setTimeout(() => navigate('/admin'), 3000);
      } catch (error) {
        console.error('Confirmation error:', error);
        setError('Failed to confirm registration. Please try again.');
        setLoading(false);
      }
    };

    confirmRegistration();
  }, [location, navigate, setUser, setIsAuthenticated]);

  // Render loading state
  if (loading) {
    return (
      <Container>
        <CircularProgress />
        <Typography>Confirming your registration...</Typography>
      </Container>
    );
  }

  // Render error state
  if (error) {
    return (
      <Container>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  // Render success state
  return (
    <Container>
      <Typography>Registration successful! Redirecting to dashboard...</Typography>
    </Container>
  );
};

export default RegistrationConfirmation;
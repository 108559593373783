import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Grid, TextField, Button, Typography, Snackbar, Alert, 
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material';

const SMTPSettings = () => {
  const [smtpSettings, setSmtpSettings] = useState({
    host: '',
    port: '',
    secure: false,
    auth: {
      user: '',
      pass: ''
    },
    from: ''
  });
  const [testEmail, setTestEmail] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    fetchSMTPSettings();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchSMTPSettings = async () => {
    try {
      const response = await axios.get('/api/superadmin/smtp-settings');
      setSmtpSettings(response.data);
    } catch (error) {
      console.error('Error fetching SMTP settings:', error);
      showSnackbar('Error fetching SMTP settings', 'error');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSmtpSettings(prevSettings => ({
      ...prevSettings,
      [name]: value
    }));
  };

  const handleAuthChange = (event) => {
    const { name, value } = event.target;
    setSmtpSettings(prevSettings => ({
      ...prevSettings,
      auth: {
        ...prevSettings.auth,
        [name]: value
      }
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.post('/api/superadmin/smtp-settings', smtpSettings);
      showSnackbar('SMTP settings saved successfully', 'success');
    } catch (error) {
      console.error('Error saving SMTP settings:', error);
      showSnackbar('Error saving SMTP settings', 'error');
    }
  };

  const handleTestEmail = async () => {
    try {
      await axios.post('/api/superadmin/test-email', { testEmail });
      showSnackbar('Test email sent successfully', 'success');
    } catch (error) {
      console.error('Error sending test email:', error);
      showSnackbar('Error sending test email', 'error');
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">SMTP Settings</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="SMTP Host"
            name="host"
            value={smtpSettings.host}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="SMTP Port"
            name="port"
            value={smtpSettings.port}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Secure</InputLabel>
            <Select
              name="secure"
              value={smtpSettings.secure}
              onChange={handleChange}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="From Email"
            name="from"
            value={smtpSettings.from}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="SMTP Username"
            name="user"
            value={smtpSettings.auth.user}
            onChange={handleAuthChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="SMTP Password"
            name="pass"
            type="password"
            value={smtpSettings.auth.pass}
            onChange={handleAuthChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Save SMTP Settings
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Test Email</Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Test Email Address"
            value={testEmail}
            onChange={(e) => setTestEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button variant="contained" color="secondary" onClick={handleTestEmail}>
            Send Test Email
          </Button>
        </Grid>
      </Grid>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </form>
  );
};

export default SMTPSettings;
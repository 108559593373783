/**
 * The `AdminDetails` component displays detailed information about an admin user, including their account creation date, number of residents, admin code, phone number, address, subscription details, and the ability to toggle the maintenance request feature and rent payment feature.
 *
 * This component is used within the super admin section of the application, and is rendered when the user navigates to the admin details page.
 *
 * @component
 * @example
 * return (
 *   <AdminDetails />
 * );
 */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Container, Typography, Paper, Grid, List, ListItem, ListItemText, Avatar, Divider, Switch, Chip, Box
} from '@mui/material';
import { Person as PersonIcon, AttachMoney as MoneyIcon, CalendarToday as CalendarIcon, CreditCard as CardIcon } from '@mui/icons-material';

const AdminDetails = () => {
  const [admin, setAdmin] = useState(null);
  const { id } = useParams();
  const [maintenanceRequestEnabled, setMaintenanceRequestEnabled] = useState(false);
  const [rentPaymentEnabled, setRentPaymentEnabled] = useState(false);
  const [grievanceRequestEnabled, setGrievanceRequestEnabled] = useState(false);
  const [formsEnabled, setFormsEnabled] = useState(false);

  useEffect(() => {
    const fetchAdminDetails = async () => {
      try {
        const { data } = await axios.get(`/api/superadmin/admin/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setAdmin(data);
        setMaintenanceRequestEnabled(data.maintenanceRequestEnabled);
        setRentPaymentEnabled(data.rentPaymentEnabled);
        setGrievanceRequestEnabled(data.grievanceRequestEnabled);
        setFormsEnabled(data.formsEnabled);
      } catch (error) {
        console.error('Error fetching admin details:', error);
        // TODO: Add user-friendly error handling
      }
    };
    fetchAdminDetails();
  }, [id]);

  const handleMaintenanceRequestToggle = async () => {
    const newState = !maintenanceRequestEnabled;
    try {
      setMaintenanceRequestEnabled(newState); // Optimistic update
      const { data } = await axios.put(`/api/superadmin/admin/${id}/toggle-maintenance`, {
        maintenanceRequestEnabled: newState
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setMaintenanceRequestEnabled(data.maintenanceRequestEnabled);
    } catch (error) {
      console.error('Error toggling maintenance request feature:', error.response?.data || error.message);
      setMaintenanceRequestEnabled(!newState); // Revert on failure
      // TODO: Add user-facing error notification
    }
  };

  const handleRentPaymentToggle = async () => {
    const newState = !rentPaymentEnabled;
    try {
      setRentPaymentEnabled(newState); // Optimistic update
      const { data } = await axios.put(`/api/superadmin/admin/${id}/toggle-rent-payment`, {
        rentPaymentEnabled: newState
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setRentPaymentEnabled(data.rentPaymentEnabled);
    } catch (error) {
      console.error('Error toggling rent payment feature:', error.response?.data || error.message);
      setRentPaymentEnabled(!newState); // Revert on failure
      // TODO: Add user-facing error notification
    }
  };

  const handleGrievanceRequestToggle = async () => {
    const newState = !grievanceRequestEnabled;
    try {
      setGrievanceRequestEnabled(newState); // Optimistic update
      const { data } = await axios.put(`/api/superadmin/admin/${id}/toggle-grievance`, {
        grievanceRequestEnabled: newState
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setGrievanceRequestEnabled(data.grievanceRequestEnabled);
    } catch (error) {
      console.error('Error toggling grievance feature:', error.response?.data || error.message);
      setGrievanceRequestEnabled(!newState); // Revert on failure
      // TODO: Add user-facing error notification
    }
  };

  const handleFormsToggle = async () => {
    const newState = !formsEnabled;
    try {
      setFormsEnabled(newState); // Optimistic update
      const { data } = await axios.put(`/api/superadmin/admin/${id}/toggle-forms`, {
        formsEnabled: newState
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setFormsEnabled(data.formsEnabled);
    } catch (error) {
      console.error('Error toggling forms feature:', error.response?.data || error.message);
      setFormsEnabled(!newState); // Revert on failure
      // TODO: Add user-facing error notification
    }
  };

  if (!admin) return <div>Loading...</div>;

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Avatar sx={{ width: 100, height: 100, mb: 2 }}>
              <PersonIcon fontSize="large" />
            </Avatar>
            <Typography variant="h4" gutterBottom>
              {admin.name}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {admin.email}
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <List>
              <ListItem>
                <ListItemText 
                  primary="Account Created" 
                  secondary={admin.createdAt && !isNaN(new Date(admin.createdAt).getTime())
                    ? new Date(admin.createdAt).toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                      })
                    : 'Date not available'}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Number of Residents" secondary={admin.residents ? admin.residents.length : 0} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Admin Code" secondary={admin.adminCode} />
              </ListItem>
              <Divider sx={{ my: 2 }} />
              <ListItem>
                <ListItemText primary="Phone Number" secondary={admin.phoneNumber || 'Not provided'} />
              </ListItem>
              {admin.address && (
                <ListItem>
                  <ListItemText 
                    primary="Address" 
                    secondary={`${admin.address.street}, ${admin.address.city}, ${admin.address.state} ${admin.address.zipCode}, ${admin.address.country}`} 
                  />
                </ListItem>
              )}
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>Subscription Details</Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                <Chip icon={<MoneyIcon />} label={`Tier: ${admin.subscription?.tier || 'N/A'}`} />
                <Chip 
                  icon={<CalendarIcon />}
                  label={`Status: ${admin.subscription?.status || 'N/A'}`}
                  color={admin.subscription?.status === 'active' ? 'success' : 'error'}
                />
                <Chip 
                  icon={<CardIcon />}
                  label={`Plan: ${admin.subscription?.planAmount} ${admin.subscription?.planCurrency} / month`}
                />
              </Box>
              <ListItem>
                <ListItemText 
                  primary="Current Period" 
                  secondary={admin.subscription?.currentPeriodStart && admin.subscription?.currentPeriodEnd
                    ? `${new Date(admin.subscription.currentPeriodStart).toLocaleDateString()} - ${new Date(admin.subscription.currentPeriodEnd).toLocaleDateString()}`
                    : 'N/A'
                  } 
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Last Payment Date" 
                  secondary={admin.subscription?.lastPaymentDate
                    ? new Date(admin.subscription.lastPaymentDate).toLocaleDateString()
                    : 'N/A'
                  } 
                />
              </ListItem>
              <ListItem>
                <ListItemText 
                  primary="Next Payment Date" 
                  secondary={admin.subscription?.nextPaymentDate
                    ? new Date(admin.subscription.nextPaymentDate).toLocaleDateString()
                    : 'N/A'
                  } 
                />
              </ListItem>
              {admin.subscription?.cancelAtPeriodEnd && (
                <ListItem>
                  <ListItemText 
                    primary="Cancellation" 
                    secondary={`Subscription will be canceled on ${new Date(admin.subscription.cancelAt).toLocaleDateString()}`}
                  />
                </ListItem>
              )}
              {admin.subscription?.canceledAt && (
                <ListItem>
                  <ListItemText 
                    primary="Canceled On" 
                    secondary={new Date(admin.subscription.canceledAt).toLocaleDateString()}
                  />
                </ListItem>
              )}
              <Divider sx={{ my: 2 }} />
              <ListItem>
                <ListItemText primary="Maintenance Request Feature" />
                <Switch
                  checked={maintenanceRequestEnabled}
                  onChange={handleMaintenanceRequestToggle}
                  inputProps={{ 'aria-label': 'toggle maintenance request feature' }}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Rent Payment Feature" />
                <Switch
                  checked={rentPaymentEnabled}
                  onChange={handleRentPaymentToggle}
                  inputProps={{ 'aria-label': 'toggle rent payment feature' }}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Grievance Feature" />
                <Switch
                  checked={grievanceRequestEnabled}
                  onChange={handleGrievanceRequestToggle}
                  inputProps={{ 'aria-label': 'toggle grievance feature' }}
                />
              </ListItem>
              <ListItem>
                <ListItemText primary="Forms Feature" />
                <Switch
                  checked={formsEnabled}
                  onChange={handleFormsToggle}
                  inputProps={{ 'aria-label': 'toggle forms feature' }}
                />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default AdminDetails;
// client/src/components/admin/ResidentList.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemText, Typography, Box, Avatar, ListItemAvatar, TextField, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Search as SearchIcon } from '@mui/icons-material';

const ScrollableList = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '0.4em'
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.1)',
    outline: '1px solid slategrey'
  }
}));

const ResidentList = ({ residents }) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const navigate = useNavigate();

  const filteredResidents = residents.filter(resident =>
    resident.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleResidentClick = (residentId) => {
    navigate(`/admin/resident/${residentId}`);
  };

  if (!residents || residents.length === 0) {
    return <Typography>No residents found.</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Typography variant="h6" component="div" gutterBottom>
        Residents
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search residents..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ mb: 2 }}
      />
      <ScrollableList>
        <List>
          {filteredResidents.map((resident) => (
            <ListItem 
              key={resident._id} 
              button 
              onClick={() => handleResidentClick(resident._id)}
            >
              <ListItemAvatar>
                <Avatar src={resident.avatarUrl} alt={resident.name}>
                  {resident.name.charAt(0)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText 
                primary={resident.name} 
                secondary={resident.email}
                primaryTypographyProps={{ fontWeight: 'medium' }}
              />
            </ListItem>
          ))}
        </List>
      </ScrollableList>
    </Box>
  );
};

export default ResidentList;
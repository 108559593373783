import React, { useState, useRef } from 'react';
import { 
  Box, Typography, Button, Dialog, DialogTitle, DialogContent, 
  DialogActions, IconButton, Tooltip, TextField, useTheme
} from '@mui/material';
import { 
  QrCode2 as QrCodeIcon, 
  ContentCopy as ContentCopyIcon,
  FileDownload as FileDownloadIcon,
  AdminPanelSettings as AdminPanelSettingsIcon,
  Visibility as VisibilityIcon
} from '@mui/icons-material';

const AdminInfo = ({ adminCode, qrCodeUrl, handleDownloadQRCode }) => {
  const [openQRDialog, setOpenQRDialog] = useState(false);
  const [copied, setCopied] = useState(false);
  const adminCodeRef = useRef(null);
  const theme = useTheme();

  const handleCopyCode = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(adminCode).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
      });
    } else {
      // Fallback method
      const textField = document.createElement('textarea');
      textField.innerText = adminCode;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
      setCopied(true);
      setTimeout(() => setCopied(false), 1500);
    }
  };

  return (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <AdminPanelSettingsIcon sx={{ mr: 1 }} /> Admin Information
      </Typography>
      
      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle1" gutterBottom>
          This is your admin code:
        </Typography>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'grey.100', 
          p: { xs: 1, sm: 2 }, 
          borderRadius: 1,
          border: `1px solid ${theme.palette.divider}`
        }}>
          <TextField
            value={adminCode}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              style: { 
                fontWeight: 'bold', 
                fontSize: { xs: '1rem', sm: '1.25rem' },
                color: theme.palette.text.primary
              }
            }}
            fullWidth
            variant="standard"
            inputRef={adminCodeRef}
            sx={{
              '& .MuiInputBase-input': {
                color: theme.palette.text.primary,
              },
            }}
          />
          <Tooltip title={copied ? "Copied!" : "Copy code"}>
            <IconButton onClick={handleCopyCode} color="primary">
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      
      <Box sx={{ mb: 4 }}>
        <Typography variant="subtitle1" gutterBottom>
          QR Code for resident registration:
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'flex-start', gap: 2, mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenQRDialog(true)}
            startIcon={<VisibilityIcon />}
            disabled={!qrCodeUrl}
            fullWidth
          >
            View QR Code
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDownloadQRCode}
            startIcon={<FileDownloadIcon />}
            disabled={!qrCodeUrl}
            fullWidth
          >
            Download QR Code
          </Button>
        </Box>
      </Box>

      <Dialog
        open={openQRDialog}
        onClose={() => setOpenQRDialog(false)}
        aria-labelledby="qr-code-dialog-title"
        aria-describedby="qr-code-dialog-description"
      >
        <DialogTitle id="qr-code-dialog-title">QR Code</DialogTitle>
        <DialogContent id="qr-code-dialog-description">
          <Box sx={{ width: 300, height: 300 }}>
            <img src={qrCodeUrl} alt="QR Code" style={{ width: '100%', height: '100%' }} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenQRDialog(false)} color="primary">
            Close
          </Button>
          <Button onClick={handleDownloadQRCode} color="primary" startIcon={<FileDownloadIcon />}>
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminInfo;
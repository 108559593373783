// client/src/components/resident/DailyMotivation.js

import React, { useState, useEffect } from 'react';
import { Typography, Box, Button } from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';

const quotes = [
  "The only way to do great work is to love what you do. - Steve Jobs",
  "Believe you can and you're halfway there. - Theodore Roosevelt",
  "Your time is limited, don't waste it living someone else's life. - Steve Jobs",
  "Strive not to be a success, but rather to be of value. - Albert Einstein",
  "I attribute my success to this: I never gave or took any excuse. - Florence Nightingale",
  "The future belongs to those who believe in the beauty of their dreams. - Eleanor Roosevelt",
  "The best time to plant a tree was 20 years ago. The second best time is now. - Chinese Proverb",
  "Your life does not get better by chance, it gets better by change. - Jim Rohn",
  "The only person you are destined to become is the person you decide to be. - Ralph Waldo Emerson",
  "Go confidently in the direction of your dreams. Live the life you have imagined. - Henry David Thoreau"
];

const DailyMotivation = () => {
  const [quote, setQuote] = useState('');

  const getRandomQuote = () => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    setQuote(quotes[randomIndex]);
  };

  useEffect(() => {
    getRandomQuote();
  }, []);

  return (
    <Box textAlign="center">
      <Typography variant="h6" gutterBottom>Daily Motivation</Typography>
      <Typography variant="body1" paragraph>
        "{quote}"
      </Typography>
      <Button
        startIcon={<RefreshIcon />}
        onClick={getRandomQuote}
        variant="outlined"
      >
        New Quote
      </Button>
    </Box>
  );
};

export default DailyMotivation;
import React, { useState } from 'react';
import { Box, Container, Typography, Grid, Card, CardContent, Button, Switch, useMediaQuery, Tooltip } from '@mui/material';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { motion } from 'framer-motion';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Intro Rust", sans-serif',
  fontWeight: 700,
  fontSize: '2.5rem',
  letterSpacing: '0.05em',
  marginBottom: theme.spacing(4),
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

const PricingCard = styled(motion(Card))(({ theme, isPopular }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.spacing(3),
  transition: 'all 0.3s ease-in-out',
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  border: isPopular ? `2px solid ${theme.palette.primary.main}` : 'none',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: '0 12px 48px rgba(31, 38, 135, 0.2)',
  },
}));

const PopularBadge = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 16,
  right: 16,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  padding: '4px 12px',
  borderRadius: '20px',
  fontSize: '0.75rem',
  fontWeight: 'bold',
  zIndex: 1,
}));

const FeatureItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const ScrollIndicator = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(4),
  left: '50%',
  transform: 'translateX(-50%)',
  cursor: 'pointer',
  color: theme.palette.text.primary,
}));

const BillingSwitch = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(4),
}));

const Pricing = ({ yearlyBilling, setYearlyBilling }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [hoveredPlan, setHoveredPlan] = useState(null);

  const plans = [
    { 
      title: 'Micro House', 
      price: yearlyBilling ? '$768' : '$80', 
      period: yearlyBilling ? '/year' : '/month', 
      features: ['Up to 10 residents', 'Basic management tools', 'Community features', '24/7 support'],
      color: theme.palette.info.light
    },
    { 
      title: 'Standard House', 
      price: yearlyBilling ? '$1,728' : '$180', 
      period: yearlyBilling ? '/year' : '/month', 
      features: ['Up to 40 residents', 'Advanced management tools', 'Analytics dashboard', 'Priority support'],
      isPopular: true,
      color: theme.palette.primary.main
    },
    { 
      title: 'Large House', 
      price: yearlyBilling ? '$2,496' : '$260', 
      period: yearlyBilling ? '/year' : '/month', 
      features: ['Up to 80 residents', 'Full feature set', 'Custom integrations', 'Dedicated account manager'],
      color: theme.palette.secondary.light
    },
    { 
      title: 'X-Large House', 
      price: yearlyBilling ? '$11,520' : '$1,200', 
      period: yearlyBilling ? '/year' : '/month', 
      features: ['Up to 150 residents', 'Enterprise-grade security', 'Advanced analytics', 'Custom development'],
      color: theme.palette.error.light
    },
  ];

  const scrollToNextSection = () => {
    const faqSection = document.getElementById('faq');
    if (faqSection) {
      faqSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box 
      sx={{ 
        background: 'linear-gradient(135deg, #FFF5EA 0%, #FFE6CC 100%)',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        py: 8,
      }} 
      id="pricing"
    >
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <SectionTitle variant="h2">
            Choose Your Plan
          </SectionTitle>
        </motion.div>
        
        <BillingSwitch>
          <Typography variant="body1" color="text.secondary">Monthly</Typography>
          <Switch 
            checked={yearlyBilling}
            onChange={() => setYearlyBilling(!yearlyBilling)}
            color="primary"
            sx={{ mx: 2 }}
          />
          <Typography variant="body1" color="text.secondary">
            Yearly
            <Tooltip title="Save 20% with yearly billing" arrow>
              <InfoOutlinedIcon sx={{ ml: 1, fontSize: 18, verticalAlign: 'text-bottom', cursor: 'pointer' }} />
            </Tooltip>
          </Typography>
        </BillingSwitch>

        <Grid container spacing={3} justifyContent="center">
          {plans.map((plan, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
              >
                <PricingCard 
                  isPopular={plan.isPopular}
                  onMouseEnter={() => setHoveredPlan(index)}
                  onMouseLeave={() => setHoveredPlan(null)}
                >
                  {plan.isPopular && <PopularBadge>Most Popular</PopularBadge>}
                  <CardContent sx={{ flexGrow: 1, p: 3, pt: plan.isPopular ? 5 : 3 }}>
                    <Typography variant="h5" component="h2" gutterBottom fontWeight="bold" color={plan.color}>
                      {plan.title}
                    </Typography>
                    <Typography variant="h3" gutterBottom fontWeight="bold">
                      {plan.price}
                      <Typography variant="body1" component="span" color="text.secondary">
                        {plan.period}
                      </Typography>
                    </Typography>
                    {plan.features.map((feature, idx) => (
                      <FeatureItem key={idx}>
                        <CheckCircleIcon sx={{ mr: 1, color: plan.color }} fontSize="small" />
                        <Typography variant="body2">{feature}</Typography>
                      </FeatureItem>
                    ))}
                  </CardContent>
                  <Box sx={{ p: 3, pt: 0 }}>
                    <Button 
                      variant="contained" 
                      fullWidth
                      sx={{
                        py: 1.5,
                        backgroundColor: plan.color,
                        '&:hover': {
                          backgroundColor: alpha(plan.color, 0.8),
                        },
                      }}
                    >
                      Get Started
                    </Button>
                  </Box>
                </PricingCard>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
      <ScrollIndicator
        animate={{ y: [0, 10, 0] }}
        transition={{ repeat: Infinity, duration: 1.5 }}
        onClick={scrollToNextSection}
      >
        <KeyboardArrowDownIcon sx={{ fontSize: 40 }} />
      </ScrollIndicator>
    </Box>
  );
};

export default Pricing;
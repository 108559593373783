/**
 * The `AdminDashboard` component is the main administrative dashboard for the application. It displays information about the residents, the subscription status, and provides functionality to manage the subscription.
 *
 * The component fetches data from the server, including the list of residents, the admin's profile information, and the subscription details. It then renders this data in a tabbed interface, with one tab for the resident list and another for the subscription information.
 *
 * The subscription management features include the ability to open the subscription management portal and upgrade the subscription to a higher tier, if available.
 *
 * The component also displays any error or warning messages that may occur during the data fetching or subscription management processes.
 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container, Grid, Typography, Box, Card, Alert,
  Dialog, DialogTitle, DialogContent, DialogActions, FormControl,
  InputLabel, Select, MenuItem, Button, Avatar, Chip
} from '@mui/material';
import { Dashboard as DashboardIcon, Person as PersonIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import AdminHeader from '../../components/admin/dashboard/AdminHeader';
import QuickActions from '../../components/admin/dashboard/QuickActions';
import AdminInfo from '../../components/admin/dashboard/AdminInfo';
import ResidentList from '../../components/admin/ResidentList';
import SubscriptionInfo from '../../components/admin/dashboard/SubscriptionInfo';
import { format } from 'date-fns';

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s',
  borderRadius: theme.shape.borderRadius * 2, // Increased border radius
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

const AdminDashboard = () => {
  const [residents, setResidents] = useState([]);
  const [adminName, setAdminName] = useState('');
  const [adminAvatarUrl, setAdminAvatarUrl] = useState('');
  const [adminCode, setAdminCode] = useState('');
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [subscriptionInfo, setSubscriptionInfo] = useState(null);
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const [newTier, setNewTier] = useState('');
  const [maintenanceRequestEnabled, setMaintenanceRequestEnabled] = useState(false);
  const [rentPaymentEnabled, setRentPaymentEnabled] = useState(false);
  const [grievanceRequestEnabled, setGrievanceRequestEnabled] = useState(false);
  const [formsEnabled, setFormsEnabled] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [residentsRes, profileRes, subscriptionRes] = await Promise.all([
          axios.get('/api/admin/residents', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          }),
          axios.get('/api/auth/profile', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          }),
          axios.get('/api/admin/subscription', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          })
        ]);

        setResidents(residentsRes.data.residents);
        setWarning(residentsRes.data.warning);
        setAdminName(profileRes.data.name);
        setAdminAvatarUrl(profileRes.data.avatarUrl);
        setAdminCode(profileRes.data.adminCode);
        setQrCodeUrl(profileRes.data.qrCodeUrl);
        setSubscriptionInfo(subscriptionRes.data);
        setMaintenanceRequestEnabled(profileRes.data.maintenanceRequestEnabled);
        setRentPaymentEnabled(profileRes.data.rentPaymentEnabled);
        setGrievanceRequestEnabled(profileRes.data.grievanceRequestEnabled);
        setFormsEnabled(profileRes.data.formsEnabled);
        // Simulating fetching unread notifications
        setUnreadNotifications(Math.floor(Math.random() * 10));
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data. Please try again later.');
      }
    };

    fetchData();
  }, []);

  const handleManageSubscription = async () => {
    try {
      const response = await axios.post('/api/admin/create-portal-session', {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      if (response.data && response.data.url) {
        window.location.href = response.data.url;
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error creating portal session:', error);
      setError(error.response?.data?.message || 'Error opening subscription management portal. Please try again later.');
    }
  };

  const handleUpgradeSubscription = async () => {
    try {
      const response = await axios.post('/api/admin/upgrade-subscription', 
        { newTier },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setSubscriptionInfo({ ...subscriptionInfo, tier: response.data.newTier });
      setOpenUpgradeDialog(false);
    } catch (error) {
      console.error('Error upgrading subscription:', error);
      setError(error.response?.data?.message || 'Error upgrading subscription. Please try again later.');
    }
  };

  const handleDownloadQRCode = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    
    img.onload = () => {
      canvas.width = 1000;
      canvas.height = 1000;
      
      ctx.drawImage(img, 0, 0, 1000, 1000);
      
      const link = document.createElement('a');
      link.href = canvas.toDataURL('image/png');
      link.download = 'resident_registration_qr_code.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };
    
    img.src = qrCodeUrl;
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* New Admin Header */}
        <Grid item xs={12}>
          <AdminHeader
            adminName={adminName}
            adminAvatarUrl={adminAvatarUrl}
            residentsCount={residents.length}
            unreadNotifications={unreadNotifications}
            lastLoginDate={format(new Date(), 'MMMM dd, yyyy')}
            subscriptionTier={subscriptionInfo?.tier}
          />
        </Grid>

        {/* Admin Info */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            <AdminInfo
              adminCode={adminCode}
              qrCodeUrl={qrCodeUrl}
              handleDownloadQRCode={handleDownloadQRCode}
            />
          </StyledCard>
        </Grid>

        {/* Subscription Info */}
        <Grid item xs={12} md={6}>
          <StyledCard>
            {subscriptionInfo && (
              <SubscriptionInfo
                subscriptionInfo={subscriptionInfo}
                residentsCount={residents.length}
                handleManageSubscription={handleManageSubscription}
                handleUpgradeSubscription={() => setOpenUpgradeDialog(true)}
              />
            )}
          </StyledCard>
        </Grid>

        {/* Quick Actions and Residents List side by side */}
        <Grid item xs={12} container spacing={3}>
          <Grid item xs={12} md={6} sx={{ height: { xs: 'auto', md: '100%' }, mb: { xs: 3, md: 0 } }}>
            <StyledCard sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <QuickActions
                maintenanceRequestEnabled={maintenanceRequestEnabled}
                rentPaymentEnabled={rentPaymentEnabled}
                grievanceRequestEnabled={grievanceRequestEnabled}
                formsEnabled={formsEnabled}
              />
            </StyledCard>
          </Grid>
          <Grid item xs={12} md={6} sx={{ height: { xs: '400px', md: 'calc(100vh - 400px)' } }}>
            <StyledCard sx={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
              <ResidentList residents={residents} />
            </StyledCard>
          </Grid>
        </Grid>

        {/* Upgrade Subscription Dialog */}
        <Dialog open={openUpgradeDialog} onClose={() => setOpenUpgradeDialog(false)}>
          <DialogTitle>Upgrade Subscription</DialogTitle>
          <DialogContent>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="new-tier-label">New Tier</InputLabel>
              <Select
                labelId="new-tier-label"
                value={newTier}
                onChange={(e) => setNewTier(e.target.value)}
              >
                {subscriptionInfo && subscriptionInfo.tier !== 'standard' && <MenuItem value="standard">Standard</MenuItem>}
                {subscriptionInfo && subscriptionInfo.tier !== 'large' && <MenuItem value="large">Large</MenuItem>}
                {subscriptionInfo && subscriptionInfo.tier !== 'xlarge' && <MenuItem value="xlarge">XLarge</MenuItem>}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenUpgradeDialog(false)}>Cancel</Button>
            <Button onClick={handleUpgradeSubscription} color="primary">
              Upgrade
            </Button>
          </DialogActions>
        </Dialog>

        {error && <Alert severity="error" sx={{ mt: 2 }}>{error}</Alert>}
        {warning && <Alert severity="warning" sx={{ mt: 2 }}>{warning}</Alert>}
      </Grid>
    </Container>
  );
};

export default AdminDashboard;

import React, { useState, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { 
  AppBar, 
  Toolbar, 
  IconButton, 
  Avatar, 
  Box, 
  Menu, 
  MenuItem, 
  ListItemIcon,
  Divider,
  Typography,
  Tooltip,
  Breadcrumbs,
  Switch,
  useMediaQuery
} from '@mui/material';
import { 
  Menu as MenuIcon,
  NavigateNext as NavigateNextIcon,
  Settings,
  Logout,
  PersonAdd,
  Brightness4 as DarkModeIcon,
  Brightness7 as LightModeIcon,
  Home as HomeIcon
} from '@mui/icons-material';
import { useAuth } from '../../hooks/useAuth';
import { styled, alpha, useTheme } from '@mui/material/styles';
import { ColorModeContext } from '../../contexts/ColorModeContext';

const drawerWidth = 280;

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer - 1, // Reduce zIndex to be below the Sidebar
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: alpha(theme.palette.background.paper, 0.8),
  color: theme.palette.text.primary,
  boxShadow: 'none',
  borderBottom: 'none',
  backdropFilter: 'blur(8px)',
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  transition: theme.transitions.create(['transform'], {
    duration: theme.transitions.duration.short,
  }),
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const Navbar = ({ onMenuClick, isSidebarOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    navigate('/profile');
    handleClose();
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
    handleClose();
  };

  const getBreadcrumbs = () => {
    const pathnames = location.pathname.split('/').filter((x) => x);
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    if (pathnames.length === 1) {
      return (
        <Typography color="textPrimary" noWrap>
          {capitalizeFirstLetter(user.role)} Dashboard
        </Typography>
      );
    }

    return (
      <Breadcrumbs 
        separator={<NavigateNextIcon fontSize="small" />} 
        aria-label="breadcrumb"
        sx={{
          '& .MuiBreadcrumbs-ol': {
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }
        }}
      >
        <Link 
          color="inherit" 
          to={`/${user.role}`} 
          style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center' }}
        >
          <HomeIcon sx={{ mr: 0.5, width: 20, height: 20 }} />
          {!isMobile && capitalizeFirstLetter(user.role)}
        </Link>
        {pathnames.slice(1).map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 2).join('/')}`;
          const isLast = index === pathnames.length - 2;

          if (name === 'residents') {
            return isLast ? (
              <Typography key={name} color="textPrimary">
                Residents
              </Typography>
            ) : (
              <Link key={name} color="inherit" to={routeTo} style={{ textDecoration: 'none', color: 'inherit' }}>
                Residents
              </Link>
            );
          }

          if (name === 'resident') {
            return (
              <Link key={name} color="inherit" to={`/${user.role}/residents`} style={{ textDecoration: 'none', color: 'inherit' }}>
                Residents
              </Link>
            );
          }

          const displayName = capitalizeFirstLetter(name.replace(/-/g, ' '));
          
          return isLast ? (
            <Typography key={name} color="textPrimary" noWrap>
              {displayName}
            </Typography>
          ) : (
            <Link key={name} color="inherit" to={routeTo} style={{ textDecoration: 'none', color: 'inherit' }}>
              {displayName}
            </Link>
          );
        })}
      </Breadcrumbs>
    );
  };

  if (!user) return null;

  return (
    <StyledAppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        ...(isMobile && isSidebarOpen && {
          visibility: 'hidden', // Hide navbar when sidebar is open on mobile
        }),
      }}
    >
      <Toolbar>
        <StyledIconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onMenuClick}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </StyledIconButton>
        <Box sx={{ flexGrow: 1, overflow: 'hidden' }}>
          {getBreadcrumbs()}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <Tooltip title={theme.palette.mode === 'dark' ? 'Light mode' : 'Dark mode'}>
            <Switch
              checked={theme.palette.mode === 'dark'}
              onChange={colorMode.toggleColorMode}
              icon={<LightModeIcon />}
              checkedIcon={<DarkModeIcon />}
            />
          </Tooltip>
          <Tooltip title="Account settings">
            <StyledIconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <StyledAvatar sx={{ width: 32, height: 32 }}>{user.name.charAt(0)}</StyledAvatar>
            </StyledIconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleProfile}>
            <Avatar /> Profile
          </MenuItem>
          <MenuItem onClick={handleProfile}>
            <Avatar /> My account
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleProfile}>
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Add another account
          </MenuItem>
          <MenuItem onClick={handleProfile}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Navbar;
// client/src/components/admin/TaskManager.js

import React, { useState } from 'react';
import axios from 'axios';
import { Typography, List, ListItem, ListItemText, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, Snackbar, Box, IconButton, Card, CardContent, CardActions, Chip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const TaskManager = ({ tasks, setTasks, residents }) => {
  const [open, setOpen] = useState(false);
  const [newTask, setNewTask] = useState({ description: '', dueDate: new Date(), resident: '' });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [editingTask, setEditingTask] = useState(null);

  const handleCreate = async () => {
    try {
      const taskData = {
        ...newTask,
        createdBy: localStorage.getItem('userId')
      };
      const res = await axios.post('/api/admin/tasks', taskData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setTasks([...tasks, res.data]);
      setOpen(false);
      setNewTask({ description: '', dueDate: new Date(), resident: '' });
      setSnackbar({ open: true, message: 'Task created successfully', severity: 'success' });
    } catch (error) {
      console.error('Error creating task:', error);
      setSnackbar({ open: true, message: 'Error creating task', severity: 'error' });
    }
  };

  const handleEdit = (task) => {
    setEditingTask(task);
    setNewTask({...task, dueDate: new Date(task.dueDate)});
    setOpen(true);
  };

  const handleUpdate = async () => {
    try {
      const res = await axios.put(`/api/admin/tasks/${editingTask._id}`, newTask, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setTasks(tasks.map(task => task._id === editingTask._id ? res.data : task));
      setOpen(false);
      setEditingTask(null);
      setNewTask({ description: '', dueDate: new Date(), resident: '' });
      setSnackbar({ open: true, message: 'Task updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Error updating task:', error);
      setSnackbar({ open: true, message: 'Error updating task', severity: 'error' });
    }
  };

  const handleDelete = async (taskId) => {
    try {
      await axios.delete(`/api/admin/tasks/${taskId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setTasks(tasks.filter(task => task._id !== taskId));
      setSnackbar({ open: true, message: 'Task deleted successfully', severity: 'success' });
    } catch (error) {
      console.error('Error deleting task:', error);
      setSnackbar({ open: true, message: 'Error deleting task', severity: 'error' });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Tasks</Typography>
        <Button startIcon={<AddIcon />} variant="contained" onClick={() => setOpen(true)}>
          Create Task
        </Button>
      </Box>
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        <List>
          {tasks.map((task) => (
            <Card key={task._id} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6">{task.description}</Typography>
                <Typography color="textSecondary">
                  Due: {new Date(task.dueDate).toLocaleDateString()}
                </Typography>
                <Chip 
                  label={task.resident.name} 
                  variant="outlined" 
                  size="small" 
                  sx={{ mt: 1 }}
                />
              </CardContent>
              <CardActions>
                <IconButton onClick={() => handleEdit(task)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDelete(task._id)}>
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Card>
          ))}
        </List>
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{editingTask ? 'Edit Task' : 'Create New Task'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Description"
            value={newTask.description}
            onChange={(e) => setNewTask({ ...newTask, description: e.target.value })}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Due Date"
              value={newTask.dueDate}
              onChange={(newDate) => setNewTask({ ...newTask, dueDate: newDate })}
              renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
            />
          </LocalizationProvider>
          <Select
            value={newTask.resident}
            onChange={(e) => setNewTask({ ...newTask, resident: e.target.value })}
            fullWidth
            margin="normal"
          >
            {residents.map((resident) => (
              <MenuItem key={resident._id} value={resident._id}>{resident.name}</MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={editingTask ? handleUpdate : handleCreate}>
            {editingTask ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Box>
  );
};

export default TaskManager;
import React, { useState, useEffect } from 'react';
import { 
  Box, TextField, Button, Grid, Typography, 
  Select, MenuItem, IconButton, FormControl, InputLabel 
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';

const FormBuilder = ({ initialForm, onSave, mode }) => {
  const [form, setForm] = useState(initialForm || { title: '', fields: [] });

  useEffect(() => {
    if (initialForm) {
      setForm(initialForm);
    }
  }, [initialForm]);

  const handleAddField = () => {
    setForm(prevForm => ({
      ...prevForm,
      fields: [...prevForm.fields, { type: 'text', label: '', required: false }]
    }));
  };

  const handleRemoveField = (index) => {
    setForm(prevForm => ({
      ...prevForm,
      fields: prevForm.fields.filter((_, i) => i !== index)
    }));
  };

  const handleFieldChange = (index, key, value) => {
    setForm(prevForm => ({
      ...prevForm,
      fields: prevForm.fields.map((field, i) => 
        i === index ? { ...field, [key]: value } : field
      )
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(form);
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <TextField
        fullWidth
        label="Form Title"
        value={form.title}
        onChange={(e) => setForm({ ...form, title: e.target.value })}
        margin="normal"
      />
      {form.fields.map((field, index) => (
        <Grid container spacing={2} key={index} alignItems="center">
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>Field Type</InputLabel>
              <Select
                value={field.type}
                onChange={(e) => handleFieldChange(index, 'type', e.target.value)}
              >
                <MenuItem value="text">Text</MenuItem>
                <MenuItem value="number">Number</MenuItem>
                <MenuItem value="date">Date</MenuItem>
                <MenuItem value="select">Select</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Field Label"
              value={field.label}
              onChange={(e) => handleFieldChange(index, 'label', e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel>Required</InputLabel>
              <Select
                value={field.required}
                onChange={(e) => handleFieldChange(index, 'required', e.target.value)}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => handleRemoveField(index)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button startIcon={<AddIcon />} onClick={handleAddField} sx={{ mt: 2 }}>
        Add Field
      </Button>
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
        <Button type="submit" variant="contained" color="primary">
          {mode === 'create' ? 'Create Form' : 'Update Form'}
        </Button>
      </Box>
    </Box>
  );
};

export default FormBuilder;
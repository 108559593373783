import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Box, useScrollTrigger, Fade, Fab } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import theme from '../components/LandingPage/styles/theme';
import Header from '../components/LandingPage/Header';
import Hero from '../components/LandingPage/Hero';
import Features from '../components/LandingPage/Features';
import Pricing from '../components/LandingPage/Pricing';
import FAQ from '../components/LandingPage/FAQ';
import CTA from '../components/LandingPage/CTA';
import Footer from '../components/LandingPage/Footer';

const LandingPage = () => {
  const [yearlyBilling, setYearlyBilling] = useState(false);
  const [scrollY, setScrollY] = useState(0);

  const navItems = [
    { text: 'Home', link: '/' },
    { text: 'Features', link: '#features' },
    { text: 'Pricing', link: '#pricing' },
    { text: 'FAQ', link: '#faq' },
    { text: 'Contact', link: '#contact' },
    { text: 'Login', link: '/login', isButton: true },
  ];

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const ScrollTop = () => {
    const handleClick = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
      <Fade in={trigger}>
        <Box
          onClick={handleClick}
          role="presentation"
          sx={{ position: 'fixed', bottom: 16, right: 16 }}
        >
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </Box>
      </Fade>
    );
  };

  const scrollToSection = (sectionId) => {
    const section = document.querySelector(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Header trigger={trigger} scrollToSection={scrollToSection} navItems={navItems} />
      <Hero scrollY={scrollY} />
      <Features />
      <Pricing yearlyBilling={yearlyBilling} setYearlyBilling={setYearlyBilling} />
      <FAQ />
      <CTA />
      <Footer />
      <ScrollTop />
    </ThemeProvider>
  );
};

export default LandingPage;
import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Container, Grid, useTheme, useMediaQuery, Snackbar, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { motion, AnimatePresence } from 'framer-motion';
import hopelLogo from '../assets/hopelinxlogo.png';
import SecurityIcon from '@mui/icons-material/Security';
import GroupIcon from '@mui/icons-material/Group';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EventNoteIcon from '@mui/icons-material/EventNote';
import BarChartIcon from '@mui/icons-material/BarChart';
import HomeIcon from '@mui/icons-material/Home';
import BuildIcon from '@mui/icons-material/Build';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import FeedbackIcon from '@mui/icons-material/Feedback';
import DescriptionIcon from '@mui/icons-material/Description';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SendIcon from '@mui/icons-material/Send';
import LockIcon from '@mui/icons-material/Lock';
import axios from 'axios';
import MuiAlert from '@mui/material/Alert';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { keyframes } from '@emotion/react';

const API_BASE_URL = process.env.REACT_APP_API_URL || '';

const PageWrapper = styled(Box)({
  position: 'relative',
  overflow: 'hidden',
  background: 'linear-gradient(135deg, #F5F5F5 0%, #E6F3FF 25%, #FFF0F5 50%, #F0F8FF 75%, #F5F5F5 100%)',
  backgroundSize: '400% 400%',
  animation: 'gradientShift 30s ease infinite',
  '@keyframes gradientShift': {
    '0%': { backgroundPosition: '0% 50%' },
    '50%': { backgroundPosition: '100% 50%' },
    '100%': { backgroundPosition: '0% 50%' },
  },
});

const gradientAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const floatAnimation = keyframes`
  0% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(180deg);
  }
  100% {
    transform: translateY(0) rotate(360deg);
  }
`;

const AnimatedBackgroundSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  padding: theme.spacing(10, 0),
  background: 'linear-gradient(135deg, rgba(255, 140, 0, 0.05) 0%, rgba(255, 20, 147, 0.05) 100%)',

  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(45deg, #ff9a9e, #fad0c4, #ffecd2)',
    backgroundSize: '400% 400%',
    animation: `${gradientAnimation} 15s ease infinite`,
    opacity: 0.1,
    zIndex: -1,
  },
}));

const FloatingShape = styled(Box)`
  position: absolute;
  background: linear-gradient(45deg, #FF8C00, #FF1493);
  border-radius: 50%;
  opacity: 0.1;
  animation: ${floatAnimation} 6s ease-in-out infinite;
`;

const SectionWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(10, 0),
  position: 'relative',
  zIndex: 1,
}));

const ContentContainer = styled(Container)(({ theme }) => ({
  position: 'relative',
  zIndex: 2,
}));

const Logo = styled('img')(({ theme }) => ({
  width: '400px', // Increased from 300px
  maxWidth: '90%', // Ensures the logo doesn't overflow on smaller screens
  marginBottom: theme.spacing(6), // Increased spacing below the logo
  [theme.breakpoints.down('md')]: {
    width: '350px', // Slightly smaller on medium screens
  },
  [theme.breakpoints.down('sm')]: {
    width: '300px', // Even smaller on mobile screens
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root': {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  '& .MuiInputBase-input': {
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF8C00 30%, #FF1493 90%)',
  border: 0,
  borderRadius: 3,
  boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  color: 'white',
  height: 48,
  padding: '0 30px',
  transition: 'all 0.3s',
  '&:hover': {
    background: 'linear-gradient(45deg, #FF1493 30%, #FF8C00 90%)',
    transform: 'translateY(-2px)',
    boxShadow: '0 6px 10px 4px rgba(255, 105, 135, .3)',
  },
}));

const FeatureCard = styled(motion.div)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: '0 20px 40px rgba(0, 0, 0, 0.2)',
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Intro Rust", sans-serif',
  fontWeight: 700,
  fontSize: '3rem',
  letterSpacing: '0.05em',
  marginBottom: theme.spacing(6),
  textAlign: 'center',
  color: '#333', // Darker color for better contrast
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',
  },
}));

const SubSectionTitle = styled(SectionTitle)(({ theme }) => ({
  fontSize: '2.5rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

const FeatureSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  padding: theme.spacing(10, 0),
  background: 'linear-gradient(135deg, rgba(255, 140, 0, 0.05) 0%, rgba(255, 20, 147, 0.05) 100%)',
}));

const FeatureIconWrapper = styled(Box)(({ theme }) => ({
  fontSize: '4rem',
  marginBottom: theme.spacing(3),
  color: '#FF8C00',
  transition: 'all 0.3s ease-in-out',
  '${FeatureCard}:hover &': {
    transform: 'scale(1.1) rotate(5deg)',
    color: '#FF1493',
  },
}));

const FeatureTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '1.5rem',
  marginBottom: theme.spacing(2),
  color: '#1a1a1a',
  textAlign: 'center',
}));

const FeatureDescription = styled(Typography)(({ theme }) => ({
  color: '#4a4a4a',
  textAlign: 'center',
  flexGrow: 1,
  display: '-webkit-box',
  '-webkit-line-clamp': 4,
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
}));

const DescriptionBox = styled(motion.div)(({ theme }) => ({
  background: 'linear-gradient(135deg, rgba(255, 140, 0, 0.05) 0%, rgba(255, 20, 147, 0.05) 100%)',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(4),
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  marginBottom: theme.spacing(6),
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: 'linear-gradient(90deg, #FF8C00, #FF1493)',
  },
}));

const DescriptionText = styled(Typography)(({ theme }) => ({
  color: '#333',
  textAlign: 'center',
  maxWidth: '800px',
  margin: '0 auto',
  lineHeight: 1.6,
  fontSize: '1.1rem',
}));

const FAQSection = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(135deg, rgba(255, 140, 0, 0.05) 0%, rgba(255, 20, 147, 0.05) 100%)',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const FAQItem = styled(Box)(({ theme }) => ({
  borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(2, 0),
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const FAQQuestion = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
  padding: theme.spacing(1, 0),
}));

const FAQAnswer = styled(motion.div)(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(1, 0),
  color: 'rgba(0, 0, 0, 0.7)',
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(2),
    background: 'white', // Change this to a solid color
    boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF8C00, #FF1493)',
  color: 'white',
  padding: theme.spacing(3),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: theme.spacing(4),
  background: 'white', // Ensure the content area is also white
}));

const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2, 3),
}));

const PrivacyPolicyContent = () => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <Box sx={{ '& > *': { mb: 2 } }}>
      <Typography variant="h6">Privacy Policy for HopeLinx Beta Testing and Waitlist</Typography>
      <Typography variant="body2">Last updated: 10/05/2024</Typography>

      <Box>
        <Typography variant="subtitle1">1. Introduction</Typography>
        <Typography variant="body2">
          Welcome to HopeLinx. This Privacy Policy explains how we collect, use, and protect your personal information during our beta testing phase and when you sign up for our waitlist.
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle1">2. Information We Collect</Typography>
        <Typography variant="body2">
          - For Beta Testers: Name, email address, and usage data of the HopeLinx platform.
          <br />
          - For Waitlist: Email address.
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle1">3. How We Use Your Information</Typography>
        <Typography variant="body2">
          - To provide access to our beta platform.
          <br />
          - To communicate updates, feedback requests, and launch information.
          <br />
          - To improve and refine our services based on your feedback and usage patterns.
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle1">4. Data Protection</Typography>
        <Typography variant="body2">
          We implement industry-standard security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction.
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle1">5. Data Sharing</Typography>
        <Typography variant="body2">
          We do not sell or share your personal information with third parties, except as required by law or with your explicit consent.
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle1">6. Your Rights</Typography>
        <Typography variant="body2">
          You have the right to access, correct, or delete your personal information. You may also withdraw from the beta testing or waitlist at any time.
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle1">7. Changes to This Policy</Typography>
        <Typography variant="body2">
          We may update this policy as our service evolves. We will notify you of any significant changes via email.
        </Typography>
      </Box>

      <Box>
        <Typography variant="subtitle1">8. Contact Us</Typography>
        <Typography variant="body2">
          If you have any questions about this Privacy Policy, please contact us at info@hopelinx.com.
        </Typography>
      </Box>

      <Typography variant="body2">
        By participating in our beta testing or joining our waitlist, you agree to the terms outlined in this Privacy Policy.
      </Typography>
    </Box>
  </motion.div>
);

const ComingSoonPage = ({ onAuthenticate }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [expandedIndex, setExpandedIndex] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // Add these new state variables
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [expandedFAQ, setExpandedFAQ] = useState(null);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    if (authStatus === 'true') {
      onAuthenticate();
    }
  }, [onAuthenticate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === '8675309') {
      localStorage.setItem('isAuthenticated', 'true');
      onAuthenticate();
    } else {
      setError('Incorrect password. Please try again.');
    }
  };

  const features = [
    { 
      icon: <SecurityIcon />, 
      title: 'Secure & Compliant', 
      description: 'HIPAA-compliant platform with robust user authentication and data protection.'
    },
    { 
      icon: <GroupIcon />, 
      title: 'Resident Management', 
      description: 'Comprehensive tools for admins to manage resident profiles, assignments, and recovery progress.'
    },
    { 
      icon: <TrendingUpIcon />, 
      title: 'Recovery Tracking', 
      description: 'Detailed tracking of sobriety dates, treatment history, and recovery milestones for residents.'
    },
    { 
      icon: <AssignmentIcon />, 
      title: 'Task Management', 
      description: 'Assign and track tasks for residents, helping them stay accountable in their recovery journey.'
    },
    { 
      icon: <EventNoteIcon />, 
      title: 'Event Scheduling', 
      description: 'Organize and manage house events, appointments, and activities for residents.'
    },
    { 
      icon: <BarChartIcon />, 
      title: 'Medication Management', 
      description: 'Track and manage resident medications, ensuring proper administration and compliance.'
    },
    {
      icon: <HomeIcon />,
      title: 'House Management',
      description: 'Efficiently manage multiple recovery houses, rooms, and bed assignments.'
    },
    {
      icon: <BuildIcon />,
      title: 'Maintenance Requests',
      description: 'Allow residents to submit and admins to manage maintenance requests for the facility.'
    },
    {
      icon: <AttachMoneyIcon />,
      title: 'Rent Payments',
      description: 'Integrated system for residents to make rent payments and admins to track financial transactions.'
    },
    {
      icon: <FeedbackIcon />,
      title: 'Grievance System',
      description: 'Structured process for residents to submit grievances and for admins to address concerns.'
    },
    {
      icon: <DescriptionIcon />,
      title: 'Custom Forms',
      description: 'Create and manage custom forms for various purposes, from intake to progress tracking.'
    },
    {
      icon: <DashboardIcon />,
      title: 'Resident Dashboard',
      description: 'Personalized dashboard for residents to track their progress, tasks, and upcoming events.'
    }
  ];

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const featureVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.5,
      },
    }),
  };

  const handleWaitlistSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      console.log('Submitting to waitlist:', `${API_BASE_URL}/api/superadmin/waitlist`);
      const response = await axios.post(`${API_BASE_URL}/api/superadmin/waitlist`, { email });
      console.log('Waitlist response:', response.data);
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting to waitlist:', error.response || error);
      // You might want to show an error message here
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const faqs = [
    { 
      question: "What is HopeLinx?", 
      answer: "HopeLinx is a comprehensive digital platform designed to streamline the management of recovery houses. It offers tools for resident tracking, task management, and administrative operations, all aimed at improving the quality of care and efficiency in recovery environments."
    },
    { 
      question: "Who is HopeLinx for?", 
      answer: "HopeLinx is tailored for recovery house managers, administrators, and staff who are looking to enhance their operational efficiency, improve resident care, and streamline their administrative tasks. It's ideal for those managing single or multiple recovery houses."
    },
    { 
      question: "When will HopeLinx be available?", 
      answer: "We're currently in beta testing with select partners. Our target for public launch is Q4 2024. Join our waitlist to be among the first to know when HopeLinx becomes available and to potentially participate in our beta program."
    },
    { 
      question: "What features does HopeLinx offer?", 
      answer: "HopeLinx offers a wide range of features including resident management, task assignment and tracking, event scheduling, medication management, maintenance request handling, and customizable reporting tools. We're constantly adding new features based on user feedback."
    },
    { 
      question: "Is HopeLinx compliant with healthcare regulations?", 
      answer: "Yes, HopeLinx is designed with privacy and compliance in mind. We adhere to HIPAA guidelines to ensure the security and confidentiality of all resident information."
    },
  ];

  const toggleFAQ = (index) => {
    setExpandedFAQ(expandedFAQ === index ? null : index);
  };

  const handlePrivacyPolicyOpen = () => {
    setOpenPrivacyPolicy(true);
  };

  const handlePrivacyPolicyClose = () => {
    setOpenPrivacyPolicy(false);
  };

  return (
    <PageWrapper>
      <AnimatedBackgroundSection>
        <FloatingShape 
          sx={{ 
            width: 100, 
            height: 100, 
            top: '10%', 
            left: '10%', 
            animationDelay: '0s' 
          }} 
        />
        <FloatingShape 
          sx={{ 
            width: 50, 
            height: 50, 
            top: '70%', 
            right: '10%', 
            animationDelay: '2s' 
          }} 
        />
        <FloatingShape 
          sx={{ 
            width: 75, 
            height: 75, 
            bottom: '20%', 
            left: '20%', 
            animationDelay: '4s' 
          }} 
        />
        <ContentContainer maxWidth="md">
          <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Logo src={hopelLogo} alt="Hopel Logo" />
              <SubSectionTitle variant="h4">
                Join the Waitlist
              </SubSectionTitle>
              <Typography variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.6)', mb: 4 }}>
                Be the first to know when HopeLinx is available to the public. Sign up for our waitlist today!
              </Typography>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              style={{ width: '100%', maxWidth: '400px' }}
            >
              {!submitted ? (
                <form onSubmit={handleWaitlistSubmit}>
                  <StyledTextField
                    fullWidth
                    label="Your Email"
                    variant="outlined"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ mb: 2 }}
                    required
                    disabled={isSubmitting}
                  />
                  <StyledButton
                    type="submit"
                    variant="contained"
                    fullWidth
                    endIcon={isSubmitting ? <CircularProgress size={24} color="inherit" /> : <SendIcon />}
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Joining...' : 'Join Waitlist'}
                  </StyledButton>
                </form>
              ) : (
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Typography variant="h6" sx={{ color: 'rgba(0, 0, 0, 0.87)', textAlign: 'center' }}>
                    Thank you for joining our waitlist!
                  </Typography>
                  <Typography variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.6)', textAlign: 'center', mt: 2 }}>
                    We'll notify you as soon as HopeLinx is available to the public.
                  </Typography>
                </motion.div>
              )}
            </motion.div>
          </Box>
        </ContentContainer>
      </AnimatedBackgroundSection>

      <FeatureSection>
        <ContentContainer maxWidth="lg">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <SectionTitle variant="h2">
              Empowering Features
            </SectionTitle>
            <DescriptionBox
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              viewport={{ once: true }}
            >
              <DescriptionText variant="body1">
                HopeLinx is a groundbreaking web application at the forefront of addiction recovery technology, designed to transform the management of recovery houses while simultaneously empowering residents on their journey to lasting sobriety. This innovative platform seamlessly integrates cutting-edge technology with evidence-based recovery practices, creating a robust ecosystem that supports both administrators and residents throughout the recovery process.
              </DescriptionText>
            </DescriptionBox>
          </motion.div>
          <Grid container spacing={4}>
            {features.map((feature, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  viewport={{ once: true }}
                  style={{ height: '100%' }}
                >
                  <FeatureCard>
                    <FeatureIconWrapper>
                      {React.cloneElement(feature.icon, { fontSize: 'inherit' })}
                    </FeatureIconWrapper>
                    <FeatureTitle variant="h5">{feature.title}</FeatureTitle>
                    <FeatureDescription variant="body1">{feature.description}</FeatureDescription>
                  </FeatureCard>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </ContentContainer>
      </FeatureSection>

      {/* FAQ Section */}
      <SectionWrapper>
        <ContentContainer maxWidth="md">
          <SectionTitle>Frequently Asked Questions</SectionTitle>
          <FAQSection>
            {faqs.map((faq, index) => (
              <FAQItem key={index}>
                <FAQQuestion onClick={() => toggleFAQ(index)}>
                  <Typography variant="h6">{faq.question}</Typography>
                  {expandedFAQ === index ? <RemoveIcon /> : <AddIcon />}
                </FAQQuestion>
                <AnimatePresence>
                  {expandedFAQ === index && (
                    <FAQAnswer
                      initial={{ opacity: 0, height: 0 }}
                      animate={{ opacity: 1, height: 'auto' }}
                      exit={{ opacity: 0, height: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Typography variant="body1">{faq.answer}</Typography>
                    </FAQAnswer>
                  )}
                </AnimatePresence>
              </FAQItem>
            ))}
          </FAQSection>
        </ContentContainer>
      </SectionWrapper>

      {/* Beta Access Section */}
      <SectionWrapper>
        <ContentContainer maxWidth="sm">
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
          >
            <SubSectionTitle variant="h4">
              Beta Access
            </SubSectionTitle>
            <Typography variant="body1" sx={{ color: 'rgba(0, 0, 0, 0.6)', mb: 4, textAlign: 'center' }}>
              Enter the password to get a sneak peek of what we're working on!
            </Typography>
            <form onSubmit={handleSubmit}>
              <StyledTextField
                fullWidth
                type="password"
                label="Enter Password"
                variant="outlined"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={!!error}
                helperText={error}
                sx={{ mb: 2 }}
              />
              <StyledButton
                type="submit"
                variant="contained"
                fullWidth
                endIcon={<LockIcon />}
              >
                Access Beta
              </StyledButton>
            </form>
          </motion.div>
        </ContentContainer>
      </SectionWrapper>

      {/* Contact and Social Media Section */}
      <SectionWrapper>
        <ContentContainer maxWidth="sm">
          <Typography variant="h6" align="center" gutterBottom>
            Connect With Us
          </Typography>
          <Box display="flex" justifyContent="center" gap={2}>
            <FacebookIcon />
            <TwitterIcon />
            <LinkedInIcon />
            <YouTubeIcon />
          </Box>
          <Typography variant="body2" align="center" sx={{ mt: 2 }}>
            Have questions? Contact us at info@hopelinx.com
          </Typography>
          <Typography variant="body2" align="center" sx={{ mt: 1 }}>
            <Button onClick={handlePrivacyPolicyOpen} color="primary">
              Privacy Policy
            </Button>
          </Typography>
        </ContentContainer>
      </SectionWrapper>

      <AnimatePresence>
        {openPrivacyPolicy && (
          <StyledDialog
            open={openPrivacyPolicy}
            onClose={handlePrivacyPolicyClose}
            aria-labelledby="privacy-policy-dialog-title"
            aria-describedby="privacy-policy-dialog-description"
            fullWidth
            maxWidth="sm"
            components={{
              Backdrop: motion.div
            }}
            BackdropProps={{
              initial: { opacity: 0 },
              animate: { opacity: 1 },
              exit: { opacity: 0 },
            }}
          >
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 0.3 }}
            >
              <StyledDialogTitle id="privacy-policy-dialog-title">
                Privacy Policy
              </StyledDialogTitle>
              <StyledDialogContent dividers>
                <Box sx={{ maxHeight: '60vh', overflowY: 'auto', pr: 2 }}>
                  <PrivacyPolicyContent />
                </Box>
              </StyledDialogContent>
              <StyledDialogActions>
                <StyledButton onClick={handlePrivacyPolicyClose} color="primary">
                  Close
                </StyledButton>
              </StyledDialogActions>
            </motion.div>
          </StyledDialog>
        )}
      </AnimatePresence>
    </PageWrapper>
  );
};

export default ComingSoonPage;
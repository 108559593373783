/**
 * The `ResidentDashboard` component represents the main dashboard view for a resident user.
 * It renders the `Navbar` component with the "resident" role, and displays a heading for the resident dashboard.
 * It also includes a tabbed interface with a "Dashboard" tab.
 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Container, Typography, Grid, Paper, Box, CircularProgress, 
  Button, Card, CardContent, Avatar, Chip, List, ListItem, ListItemIcon, ListItemText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  CalendarToday as CalendarIcon,
  EmojiEvents as AchievementIcon,
  Assignment as TaskIcon,
  LocalHospital as MedicationIcon,
  Build as MaintenanceIcon,
  AttachMoney as RentIcon,
  Feedback as GrievanceIcon,
  Description as FormIcon
} from '@mui/icons-material';
import SobrietyTracker from '../../components/resident/SobrietyTracker';
import UpcomingEvents from '../../components/resident/UpcomingEvents';
import DailyMotivation from '../../components/resident/DailyMotivation';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

const FeatureButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const ResidentDashboard = () => {
  const [resident, setResident] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchResidentData = async () => {
      try {
        const response = await axios.get('/api/resident/dashboard', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setResident(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching resident data:', error);
        setError('Failed to load dashboard data. Please try again later.');
        setLoading(false);
      }
    };

    fetchResidentData();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!resident) {
    return <Typography>No resident data available. Please try again later.</Typography>;
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Resident Welcome and Quick Stats */}
        <Grid item xs={12}>
          <StyledPaper>
            <Box display="flex" alignItems="center" mb={2}>
              <Avatar 
                src={resident.avatarUrl} 
                alt={resident.name} 
                sx={{ width: 80, height: 80, mr: 2 }}
              />
              <Box>
                <Typography variant="h4" gutterBottom>
                  Welcome, {resident.name}!
                </Typography>
                <Box display="flex" gap={1}>
                  <Chip icon={<CalendarIcon />} label={`Move-in: ${new Date(resident.moveInDate).toLocaleDateString()}`} />
                  <Chip icon={<AchievementIcon />} label={`${resident.achievements.length} Achievements`} color="primary" />
                </Box>
              </Box>
            </Box>
          </StyledPaper>
        </Grid>

        {/* Achievements */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>
              <AchievementIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              Achievements
            </Typography>
            {resident.achievements.length > 0 ? (
              <List>
                {resident.achievements.map((achievement) => (
                  <ListItem key={achievement._id}>
                    <ListItemIcon>
                      <AchievementIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText 
                      primary={achievement.title}
                      secondary={achievement.description}
                    />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography>No achievements yet. Keep up the good work!</Typography>
            )}
          </StyledPaper>
        </Grid>

        {/* Sobriety Tracker */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <SobrietyTracker sobrietyDate={resident.sobrietyDate} />
          </StyledPaper>
        </Grid>

        {/* Daily Motivation */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <DailyMotivation />
          </StyledPaper>
        </Grid>

        {/* Quick Access Features */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>Quick Access</Typography>
          <Grid container spacing={2}>
            {resident.features.maintenanceRequestEnabled && (
              <Grid item xs={6} sm={3}>
                <FeatureButton
                  variant="outlined"
                  color="primary"
                  startIcon={<MaintenanceIcon />}
                  href="/resident/maintenance-requests"
                >
                  Maintenance Requests
                </FeatureButton>
              </Grid>
            )}
            {resident.features.rentPaymentEnabled && (
              <Grid item xs={6} sm={3}>
                <FeatureButton
                  variant="outlined"
                  color="primary"
                  startIcon={<RentIcon />}
                  href="/resident/rent-payments"
                >
                  Rent Payments
                </FeatureButton>
              </Grid>
            )}
            {resident.features.grievanceRequestEnabled && (
              <Grid item xs={6} sm={3}>
                <FeatureButton
                  variant="outlined"
                  color="primary"
                  startIcon={<GrievanceIcon />}
                  href="/resident/grievances"
                >
                  Grievances
                </FeatureButton>
              </Grid>
            )}
            {resident.features.formsEnabled && (
              <Grid item xs={6} sm={3}>
                <FeatureButton
                  variant="outlined"
                  color="primary"
                  startIcon={<FormIcon />}
                  href="/resident/forms"
                >
                  Forms
                </FeatureButton>
              </Grid>
            )}
          </Grid>
        </Grid>

        {/* Upcoming Events */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <UpcomingEvents events={resident.upcomingEvents} />
          </StyledPaper>
        </Grid>

        {/* Tasks and Reminders */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>
              <TaskIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              Tasks & Reminders
            </Typography>
            {resident.tasks.length > 0 ? (
              resident.tasks.map((task) => (
                <Card key={task._id} sx={{ mb: 1 }}>
                  <CardContent>
                    <Typography variant="body1">{task.description}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      Due: {new Date(task.dueDate).toLocaleDateString()}
                    </Typography>
                  </CardContent>
                </Card>
              ))
            ) : (
              <Typography>No tasks or reminders at the moment.</Typography>
            )}
          </StyledPaper>
        </Grid>

        {/* Medication Schedule */}
        <Grid item xs={12}>
          <StyledPaper>
            <Typography variant="h6" gutterBottom>
              <MedicationIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
              Medication Schedule
            </Typography>
            {resident.medications.length > 0 ? (
              <Grid container spacing={2}>
                {resident.medications.map((medication) => (
                  <Grid item xs={12} sm={6} md={4} key={medication._id}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6">{medication.name}</Typography>
                        <Typography variant="body2">Dosage: {medication.dosage}</Typography>
                        <Typography variant="body2">Time: {medication.time}</Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography>No medications scheduled.</Typography>
            )}
          </StyledPaper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ResidentDashboard;
/**
 * A custom React hook that provides access to the AuthContext.
 * 
 * This hook simplifies the process of accessing authentication-related data and functions
 * throughout the application by wrapping the useContext hook with the AuthContext.
 * 
 * @returns {Object} The value from the AuthContext, which typically includes:
 *   - user: The current user object
 *   - isAuthenticated: A boolean indicating if the user is authenticated
 *   - login: A function to log in the user
 *   - logout: A function to log out the user
 *   - loading: A boolean indicating if authentication state is being loaded
 *   - error: Any error related to authentication
 */
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

export const useAuth = () => {
  // Use the useContext hook to access the AuthContext
  return useContext(AuthContext);
};
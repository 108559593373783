import React from 'react';
import { 
  Box, TextField, Typography, Select, MenuItem, 
  FormControl, InputLabel, FormControlLabel, Checkbox 
} from '@mui/material';

const FormPreview = ({ form }) => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>{form.title}</Typography>
      {form.fields.map((field, index) => (
        <Box key={index} sx={{ mb: 2 }}>
          {field.type === 'text' && (
            <TextField
              fullWidth
              label={field.label}
              required={field.required}
            />
          )}
          {field.type === 'number' && (
            <TextField
              fullWidth
              label={field.label}
              type="number"
              required={field.required}
            />
          )}
          {field.type === 'date' && (
            <TextField
              fullWidth
              label={field.label}
              type="date"
              InputLabelProps={{ shrink: true }}
              required={field.required}
            />
          )}
          {field.type === 'select' && (
            <FormControl fullWidth>
              <InputLabel>{field.label}</InputLabel>
              <Select required={field.required}>
                {field.options && field.options.map((option, i) => (
                  <MenuItem key={i} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default FormPreview;
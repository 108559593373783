/**
 * The `MaintenanceRequests` component is a React component that displays a list of maintenance requests for a resident user. It allows the user to create new maintenance requests and view the status of existing requests.
 *
 * The component fetches the user's profile and maintenance requests from the server when it mounts, and stores the data in state. It also handles the submission of new maintenance requests and updates the list of requests accordingly.
 *
 * The component renders a form for creating new maintenance requests, as well as a list of existing requests. The list displays the title, status, and description of each request.
 *
 * If the user's profile indicates that maintenance requests are not enabled, the component will render an `AccessDenied` component instead.
 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, List, ListItem, ListItemText, Button, TextField } from '@mui/material';
import AccessDenied from '../../components/common/AccessDenied';

const MaintenanceRequests = () => {
  // State variables
  const [requests, setRequests] = useState([]);
  const [newRequest, setNewRequest] = useState({ title: '', description: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [maintenanceRequestEnabled, setMaintenanceRequestEnabled] = useState(false);

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch profile and maintenance requests concurrently
        const [profileRes, requestsRes] = await Promise.all([
          axios.get('/api/auth/profile', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          }),
          axios.get('/api/resident/maintenance-requests', {
            headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
          }),
        ]);
        setMaintenanceRequestEnabled(profileRes.data.assignedAdmin.maintenanceRequestEnabled);
        setRequests(requestsRes.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.response?.data?.message || 'An error occurred');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Loading and error states
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!maintenanceRequestEnabled) return <AccessDenied />;

  // Fetch maintenance requests
  const fetchRequests = async () => {
    try {
      const { data } = await axios.get('/api/resident/maintenance-requests', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setRequests(data);
    } catch (error) {
      console.error('Error fetching maintenance requests:', error);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('/api/resident/maintenance-requests', newRequest, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setNewRequest({ title: '', description: '' });
      fetchRequests();
    } catch (error) {
      console.error('Error creating maintenance request:', error);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Maintenance Requests</Typography>
      {/* Form for new maintenance request */}
      <form onSubmit={handleSubmit}>
        <TextField
          label="Title"
          value={newRequest.title}
          onChange={(e) => setNewRequest({ ...newRequest, title: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Description"
          value={newRequest.description}
          onChange={(e) => setNewRequest({ ...newRequest, description: e.target.value })}
          fullWidth
          multiline
          rows={4}
          margin="normal"
        />
        <Button type="submit" variant="contained" color="primary">
          Submit Request
        </Button>
      </form>
      {/* List of existing maintenance requests */}
      <List>
        {requests.map((request) => (
          <ListItem key={request._id}>
            <ListItemText
              primary={request.title}
              secondary={`Status: ${request.status} | Description: ${request.description}`}
            />
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default MaintenanceRequests;
import React from 'react';
import { Box, Container, Typography, Grid, Card, CardContent } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import { motion } from 'framer-motion';
import SecurityIcon from '@mui/icons-material/Security';
import GroupIcon from '@mui/icons-material/Group';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EventNoteIcon from '@mui/icons-material/EventNote';
import BarChartIcon from '@mui/icons-material/BarChart';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const FeatureCard = styled(motion(Card))(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.spacing(2),
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-10px)',
    boxShadow: theme.shadows[10],
  },
}));

const FeatureIcon = styled(Box)(({ theme }) => ({
  width: 60,
  height: 60,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  background: theme.palette.primary.main,
  color: theme.palette.common.white,
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Intro Rust", sans-serif',
  fontWeight: 700,
  fontSize: '3rem',
  letterSpacing: '0.05em',
  marginBottom: theme.spacing(6),
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',
  },
}));

const ScrollIndicator = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(4),
  left: '50%',
  transform: 'translateX(-50%)',
  cursor: 'pointer',
  color: theme.palette.text.primary,
}));

const Features = () => {
  const features = [
    { icon: <SecurityIcon />, title: 'Secure & Compliant', description: 'HIPAA-compliant platform with state-of-the-art encryption.' },
    { icon: <GroupIcon />, title: 'Community Building', description: 'Foster connections and support networks within your recovery community.' },
    { icon: <TrendingUpIcon />, title: 'Progress Tracking', description: 'Intuitive tools for residents to monitor their recovery journey.' },
    { icon: <AssignmentIcon />, title: 'Efficient Management', description: 'Streamline operations with our comprehensive admin dashboard.' },
    { icon: <EventNoteIcon />, title: 'Smart Scheduling', description: 'Easily manage appointments, events, and house activities.' },
    { icon: <BarChartIcon />, title: 'Insightful Analytics', description: 'Data-driven insights to improve recovery outcomes.' },
  ];

  const scrollToNextSection = () => {
    const pricingSection = document.getElementById('pricing');
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box 
      sx={{ 
        background: 'linear-gradient(180deg, #FFF0F5 0%, #FFF5EA 100%)',
        minHeight: '100vh', 
        display: 'flex', 
        flexDirection: 'column',
        position: 'relative',
      }} 
      id="features"
    >
      <Container maxWidth="lg" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', py: 12 }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <SectionTitle variant="h2">
            Features That Make a Difference
          </SectionTitle>
        </motion.div>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
              >
                <FeatureCard>
                  <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <FeatureIcon>
                      {React.cloneElement(feature.icon, { fontSize: 'large' })}
                    </FeatureIcon>
                    <Typography variant="h5" component="h3" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
                      {feature.title}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {feature.description}
                    </Typography>
                  </CardContent>
                </FeatureCard>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
      <ScrollIndicator
        animate={{ y: [0, 10, 0] }}
        transition={{ repeat: Infinity, duration: 1.5 }}
        onClick={scrollToNextSection}
      >
        <KeyboardArrowDownIcon sx={{ fontSize: 40 }} />
      </ScrollIndicator>
    </Box>
  );
};

export default Features;
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import {
  Container, Typography, Box, Button, Grid, TextField, Paper, Avatar, Chip, Fab,
  Menu, MenuItem, ListItemIcon, ListItemText, Dialog, DialogActions, 
  DialogContent, DialogContentText, DialogTitle, InputAdornment, Select, FormControl, InputLabel
} from '@mui/material';
import {
  Save, Add as AddIcon, Delete as DeleteIcon,
  Home as HomeIcon, CloudUpload as CloudUploadIcon,
  Edit as EditIcon, MoreVert as MoreVertIcon,
  Hotel as HotelIcon, SingleBed as SingleBedIcon, Person as PersonIcon
} from '@mui/icons-material';
import { ColorModeContext } from '../../contexts/ColorModeContext';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const StyledPaper = styled(Paper)(({ theme }) => ({
  overflow: 'hidden',
  transition: 'all 0.3s',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
  width: '100%',
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2, 3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const SquareAvatar = styled(Avatar)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  aspectRatio: '1 / 1',
  borderRadius: 0,
}));

const HouseName = styled(Typography)(({ theme }) => ({
  fontSize: '2.5rem',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
  marginBottom: theme.spacing(2),
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)',
    textDecoration: 'underline',
  },
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
  },
  transition: 'all 0.3s',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  border: `2px solid ${theme.palette.primary.contrastText}`,
}));

const StyledTextField = styled(TextField)(({ theme, isediting }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: isediting === 'true' ? theme.palette.background.paper : 'transparent',
    transition: theme.transitions.create(['background-color', 'box-shadow']),
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: isediting === 'true' ? `1px solid ${theme.palette.primary.main}` : 'none',
  },
  '& .MuiInputBase-input': {
    padding: isediting === 'true' ? theme.spacing(1) : 0,
    fontWeight: 'normal',
    color: theme.palette.text.primary,
  },
  '& .MuiInputLabel-root': {
    display: isediting === 'true' ? 'block' : 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: isediting === 'true' ? theme.palette.primary.main : 'transparent',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: isediting === 'true' ? theme.palette.primary.main : 'transparent',
  },
}));

const ResidentsBox = ({ residents, rooms, isMobile, houseId, fetchResidents }) => {
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedResident, setSelectedResident] = useState(null);
  const [availableResidents, setAvailableResidents] = useState([]);
  const [newResidentId, setNewResidentId] = useState('');
  const [editRoomId, setEditRoomId] = useState('');

  useEffect(() => {
    fetchAvailableResidents();
  }, []);

  const fetchAvailableResidents = async () => {
    try {
      const response = await axios.get('/api/admin/available-residents', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setAvailableResidents(response.data);
    } catch (error) {
      console.error('Error fetching available residents:', error);
    }
  };

  const handleAddResident = async () => {
    try {
      await axios.post(`/api/admin/houses/${houseId}/residents`, 
        { residentId: newResidentId },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setOpenAddDialog(false);
      fetchResidents();
      fetchAvailableResidents();
    } catch (error) {
      console.error('Error adding resident:', error);
    }
  };

  const handleEditRoom = async () => {
    try {
      await axios.put(`/api/admin/houses/${houseId}/residents/${selectedResident._id}`,
        { roomId: editRoomId },
        { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
      );
      setOpenEditDialog(false);
      fetchResidents();
    } catch (error) {
      console.error('Error updating resident room:', error);
    }
  };

  return (
    <StyledPaper elevation={3}>
      <StyledHeader>
        <Typography variant={isMobile ? "subtitle1" : "h6"} fontWeight="bold">
          Residents
        </Typography>
        <AnimatedButton
          onClick={() => setOpenAddDialog(true)}
          startIcon={<AddIcon />}
          variant="contained"
          color="primary"
          size={isMobile ? "small" : "medium"}
        >
          Add Resident
        </AnimatedButton>
      </StyledHeader>
      <StyledContent>
        {residents.length > 0 ? (
          <Grid container spacing={2}>
            {residents.map((resident) => (
              <Grid item xs={12} sm={6} key={resident._id}>
                <Box display="flex" alignItems="center" mb={2}>
                  <Avatar src={resident.avatarUrl} alt={resident.name} sx={{ mr: 2 }} />
                  <Box flexGrow={1}>
                    <Typography variant="subtitle1">{resident.name}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      Room: {rooms.find(room => room._id === resident.assignedRoom)?.name || 'Not assigned'}
                    </Typography>
                  </Box>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setSelectedResident(resident);
                      setEditRoomId(resident.assignedRoom || '');
                      setOpenEditDialog(true);
                    }}
                  >
                    Edit Room
                  </Button>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>No residents assigned to this house.</Typography>
        )}
      </StyledContent>

      {/* Add Resident Dialog */}
      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
        <DialogTitle>Add Resident to House</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Select Resident</InputLabel>
            <Select
              value={newResidentId}
              onChange={(e) => setNewResidentId(e.target.value)}
              label="Select Resident"
            >
              {availableResidents.map((resident) => (
                <MenuItem key={resident._id} value={resident._id}>
                  {resident.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddDialog(false)}>Cancel</Button>
          <Button onClick={handleAddResident} color="primary">Add</Button>
        </DialogActions>
      </Dialog>

      {/* Edit Room Dialog */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>Edit Resident Room</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Select Room</InputLabel>
            <Select
              value={editRoomId}
              onChange={(e) => setEditRoomId(e.target.value)}
              label="Select Room"
            >
              {rooms.map((room) => (
                <MenuItem key={room._id} value={room._id}>
                  {room.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenEditDialog(false)}>Cancel</Button>
          <Button onClick={handleEditRoom} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </StyledPaper>
  );
};

const AdminHouseDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [house, setHouse] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const fileInputRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [residents, setResidents] = useState([]);

  useEffect(() => {
    fetchHouseDetails();
    fetchResidents();
  }, [id]);

  const fetchHouseDetails = async () => {
    try {
      const response = await axios.get(`/api/admin/houses/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setHouse(response.data);
    } catch (error) {
      console.error('Error fetching house details:', error);
    }
  };

  const fetchResidents = async () => {
    try {
      const response = await axios.get(`/api/admin/houses/${id}/residents`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setResidents(response.data);
    } catch (error) {
      console.error('Error fetching residents:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHouse(prevHouse => ({ ...prevHouse, [name]: value }));
  };

  const handleRoomChange = (index, field, value) => {
    const updatedRooms = house.rooms.map((room, i) =>
      i === index ? { ...room, [field]: value } : room
    );
    setHouse(prevHouse => ({ ...prevHouse, rooms: updatedRooms }));
  };

  const handleAddRoom = () => {
    setHouse(prevHouse => ({
      ...prevHouse,
      rooms: [...prevHouse.rooms, { name: '', beds: 1 }]
    }));
  };

  const handleRemoveRoom = (index) => {
    setHouse(prevHouse => ({
      ...prevHouse,
      rooms: prevHouse.rooms.filter((_, i) => i !== index)
    }));
  };

  const handleSave = async () => {
    try {
      const response = await axios.put(`/api/admin/houses/${id}`, house, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setHouse(response.data);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating house:', error);
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('image', file);

      try {
        const response = await axios.post('/api/admin/upload-house-image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        
        // Update the house state with the new image URL
        const updatedHouse = { ...house, imageUrl: response.data.imageUrl };
        setHouse(updatedHouse);

        // Save the updated house details to the server
        await axios.put(`/api/admin/houses/${id}`, updatedHouse, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });

        // Optionally, you can show a success message here
      } catch (error) {
        console.error('Error uploading image:', error);
        // Optionally, you can show an error message here
      }
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/admin/houses/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      navigate('/admin/houses');
    } catch (error) {
      console.error('Error deleting house:', error);
    }
  };

  if (!house) return <Typography>Loading...</Typography>;

  const RoomItem = ({ room, index }) => (
    <Grid item xs={12} sm={6}>
      <Box display="flex" alignItems="center" mb={2}>
        <InputAdornment position="start" sx={{ mr: 1, color: 'primary.main' }}>
          <HotelIcon />
        </InputAdornment>
        <Box flexGrow={1}>
          {!isEditing && (
            <Typography variant="body1" fontWeight="bold" color="primary.main">
              Room {index + 1}
            </Typography>
          )}
          <StyledTextField
            fullWidth
            label={isEditing ? `Room ${index + 1} Name` : ''}
            value={room.name}
            onChange={(e) => handleRoomChange(index, 'name', e.target.value)}
            InputProps={{
              readOnly: !isEditing,
            }}
            variant={isEditing ? "outlined" : "standard"}
            size="small"
            isediting={isEditing.toString()}
          />
        </Box>
      </Box>
      <Box display="flex" alignItems="center" mb={2} ml={4}>
        <InputAdornment position="start" sx={{ mr: 1, color: 'primary.main' }}>
          <SingleBedIcon />
        </InputAdornment>
        <Box flexGrow={1}>
          <StyledTextField
            fullWidth
            label={isEditing ? "Beds" : ""}
            type="number"
            value={room.beds}
            onChange={(e) => handleRoomChange(index, 'beds', parseInt(e.target.value))}
            InputProps={{
              readOnly: !isEditing,
            }}
            variant={isEditing ? "outlined" : "standard"}
            size="small"
            isediting={isEditing.toString()}
          />
        </Box>
      </Box>
    </Grid>
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4, px: isMobile ? 1 : 2 }}>
      <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 3 }}>
        {/* House Profile */}
        <Box sx={{ 
          width: isMobile ? '100%' : '40%', 
          position: isMobile ? 'static' : 'sticky', 
          top: isMobile ? 0 : 16, 
          alignSelf: isMobile ? 'auto' : 'flex-start',
          marginBottom: isMobile ? 3 : 0
        }}>
          <StyledPaper elevation={3}>
            <StyledHeader>
              <Typography variant={isMobile ? "subtitle1" : "h6"} fontWeight="bold">
                House Profile
              </Typography>
              <AnimatedButton
                onClick={isEditing ? handleSave : () => setIsEditing(true)}
                startIcon={isEditing ? <Save /> : <EditIcon />}
                variant="contained"
                color={isEditing ? "success" : "primary"}
                size={isMobile ? "small" : "medium"}
              >
                {isEditing ? 'Save' : 'Edit'}
              </AnimatedButton>
            </StyledHeader>
            <Box sx={{ position: 'relative' }}>
              <SquareAvatar
                src={house.imageUrl}
                alt={house.name}
                variant="square"
              >
                {!house.imageUrl && <HomeIcon sx={{ fontSize: '4rem' }} />}
              </SquareAvatar>
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                sx={{
                  position: 'absolute',
                  bottom: 8,
                  right: 8,
                  minWidth: 'auto',
                  px: 2,
                }}
              >
                Upload
                <input 
                  type="file" 
                  hidden 
                  onChange={handleImageUpload} 
                  accept="image/*" 
                  ref={fileInputRef}
                />
              </Button>
            </Box>
            <StyledContent>
              {isEditing ? (
                <TextField
                  fullWidth
                  name="name"
                  value={house.name}
                  onChange={handleInputChange}
                  variant="outlined"
                  sx={{ mb: 2, maxWidth: 400 }}
                />
              ) : (
                <Typography variant="h5" sx={{ mb: 2 }}>{house.name}</Typography>
              )}

              <Box mb={2} width="100%">
                {isEditing ? (
                  <TextField
                    fullWidth
                    name="address"
                    value={house.address}
                    onChange={handleInputChange}
                    variant="outlined"
                    sx={{ mb: 2, maxWidth: 400 }}
                  />
                ) : (
                  <Chip 
                    icon={<HomeIcon />} 
                    label={house.address}
                    color="primary" 
                    variant="outlined"
                  />
                )}
              </Box>
            </StyledContent>
          </StyledPaper>
        </Box>

        {/* Rooms */}
        <Box sx={{ width: isMobile ? '100%' : '60%' }}>
          <StyledPaper elevation={3}>
            <StyledHeader>
              <Typography variant={isMobile ? "subtitle1" : "h6"} fontWeight="bold">
                Rooms
              </Typography>
              <AnimatedButton
                onClick={isEditing ? handleSave : () => setIsEditing(true)}
                startIcon={isEditing ? <Save /> : <EditIcon />}
                variant="contained"
                color={isEditing ? "success" : "primary"}
                size={isMobile ? "small" : "medium"}
              >
                {isEditing ? 'Save' : 'Edit'}
              </AnimatedButton>
            </StyledHeader>
            <StyledContent>
              <Grid container spacing={2}>
                {house.rooms.map((room, index) => (
                  <RoomItem key={index} room={room} index={index} />
                ))}
                {isEditing && (
                  <Grid item xs={12}>
                    <Button
                      startIcon={<AddIcon />}
                      onClick={handleAddRoom}
                      variant="outlined"
                      fullWidth
                    >
                      Add Room
                    </Button>
                  </Grid>
                )}
              </Grid>
            </StyledContent>
          </StyledPaper>
          
          {/* New Residents Box */}
          <Box sx={{ mt: 3 }}>
            <ResidentsBox 
              residents={residents} 
              rooms={house.rooms} 
              isMobile={isMobile} 
              houseId={id}
              fetchResidents={fetchResidents}
            />
          </Box>
        </Box>
      </Box>

      {/* Floating Action Button */}
      <Fab
        color="primary"
        aria-label="more actions"
        onClick={handleMenuOpen}
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
      >
        <MoreVertIcon />
      </Fab>

      {/* Menu for FAB */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => {
          handleMenuClose();
          setOpenDeleteDialog(true);
        }}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>Delete House</ListItemText>
        </MenuItem>
      </Menu>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Delete House
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this house? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button 
            onClick={() => {
              handleDelete();
              setOpenDeleteDialog(false);
            }} 
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminHouseDetails;
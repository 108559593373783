/**
 * Renders the details of a resident, including their personal information and emergency contact details.
 * This component is used in the admin section of the application to display detailed information about a specific resident.
 * 
 * @component
 * @returns {JSX.Element} The rendered resident details component.
 */
import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container, Typography, Grid, Button,
  Card, CardContent, ClickAwayListener, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, 
  Fab, Menu, MenuItem, ListItemIcon, ListItemText, Box
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format, parseISO } from 'date-fns';
import {
  Delete as DeleteIcon, Archive as ArchiveIcon, Unarchive as UnarchiveIcon,
  MoreVert as MoreVertIcon
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ResidentHeader from '../../components/admin/residentdetails/ResidentHeader';
import GeneralInformation from '../../components/admin/residentdetails/GeneralInformation';
import RecoveryInformation from '../../components/admin/residentdetails/RecoveryInformation';
import LegalInformation from '../../components/admin/residentdetails/LegalInformation';
import MedicationsBox from '../../components/admin/residentdetails/MedicationsBox';
import TreatmentHistoryBox from '../../components/admin/residentdetails/TreatmentHistoryBox';
import EmergencyContactBox from '../../components/admin/residentdetails/EmergencyContactBox';
import Masonry from '@mui/lab/Masonry';

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

const ResidentDetails = () => {
  const [resident, setResident] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const [editValue, setEditValue] = useState('');
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [houses, setHouses] = useState([]);
  const [housesLoading, setHousesLoading] = useState(true);
  const [medications, setMedications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchResidentDetails = useCallback(async () => {
    try {
      const response = await axios.get(`/api/admin/resident/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      console.log('API Response:', response.data);
      setResident(response.data);
    } catch (error) {
      console.error('Error fetching resident details:', error);
    }
  }, [id]);

  const fetchHouses = useCallback(async () => {
    setHousesLoading(true);
    try {
      const response = await axios.get('/api/admin/houses', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      console.log('Fetched houses:', response.data);
      setHouses(response.data || []);
    } catch (error) {
      console.error('Error fetching houses:', error);
      setHouses([]);
    } finally {
      setHousesLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchResidentDetails();
    fetchHouses();
  }, [fetchResidentDetails, fetchHouses]);

  useEffect(() => {
    if (resident && resident.medications) {
      setMedications(resident.medications);
    }
  }, [resident]);

  const handleEditStart = (field, value) => {
    setEditingField(field);
    setEditValue(value);
  };

  const handleEditSave = async (updatedFields) => {
    if (!resident || !resident._id) {
      console.error('Resident or resident ID is undefined');
      return;
    }

    try {
      const updatedData = { ...resident, ...updatedFields };
      const response = await axios.put(`/api/admin/resident/${resident._id}`, updatedData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      const updatedResident = response.data;
      setResident(updatedResident);
      return updatedResident;
    } catch (error) {
      console.error('Error updating resident fields:', error);
      throw error;
    }
  };

  const handleArchive = async () => {
    try {
      await axios.put(`/api/admin/resident/${id}/archive`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setResident(prevResident => ({ ...prevResident, isArchived: true }));
      setOpenArchiveDialog(false);
    } catch (error) {
      console.error('Error archiving resident:', error);
    }
  };
  
  const handleUnarchive = async () => {
    try {
      await axios.put(`/api/admin/resident/${id}/unarchive`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setResident(prevResident => ({ ...prevResident, isArchived: false }));
      setOpenArchiveDialog(false);
    } catch (error) {
      console.error('Error unarchiving resident:', error);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this resident? This action cannot be undone.')) {
      try {
        await axios.delete(`/api/admin/resident/${id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        navigate('/admin/residents');
      } catch (error) {
        console.error('Error deleting resident:', error);
      }
    }
  };

  const handleHouseChange = async (event) => {
    const newHouseId = event.target.value;
    try {
      const response = await axios.put(`/api/admin/resident/${id}`, { assignedHouse: newHouseId, assignedRoom: null }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setResident(response.data);
    } catch (error) {
      console.error('Error updating resident house:', error);
    }
  };

  const handleRoomChange = async (event) => {
    const newRoomId = event.target.value;
    try {
      const response = await axios.put(`/api/admin/resident/${id}`, { assignedRoom: newRoomId }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setResident(response.data);
    } catch (error) {
      console.error('Error updating resident room:', error);
    }
  };

  const handleAvatarUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('avatar', file);

      try {
        const response = await axios.post(`/api/admin/resident/${id}/avatar`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setResident(prevResident => ({ ...prevResident, avatarUrl: response.data.avatarUrl }));
      } catch (error) {
        console.error('Error uploading avatar:', error);
      }
    }
  };

  const renderEditableField = (field, value, label) => {
    const isEditing = editingField === field;

    if (isEditing) {
      return (
        <ClickAwayListener onClickAway={() => handleEditSave(field, editValue)}>
          <TextField
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleEditSave(field, editValue);
              }
            }}
            variant="standard"
            fullWidth
            autoFocus
          />
        </ClickAwayListener>
      );
    }

    return (
      <Typography 
        variant="body1" 
        onClick={() => handleEditStart(field, value)}
        sx={{ 
          '&:hover': { 
            backgroundColor: 'rgba(0, 0, 0, 0.04)', 
            cursor: 'pointer' 
          },
          padding: '4px',
          borderRadius: '4px',
        }}
      >
        {value || 'Not provided'}
      </Typography>
    );
  };

  const handleDateChange = async (field, newDate) => {
    try {
      const updatedData = { ...resident, [field]: newDate };
      const response = await axios.put(`/api/admin/resident/${id}`, updatedData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setResident(response.data);
    } catch (error) {
      console.error('Error updating date:', error);
    }
  };

  const handleAddDrug = async (newDrug) => {
    if (newDrug) {
      try {
        const updatedDrugs = [...(resident.drugsOfChoice || []), newDrug];
        const response = await axios.put(`/api/admin/resident/${id}`, { drugsOfChoice: updatedDrugs }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setResident(response.data);
      } catch (error) {
        console.error('Error adding drug:', error);
      }
    }
  };

  const handleDeleteDrug = async (index) => {
    try {
      const updatedDrugs = resident.drugsOfChoice.filter((_, i) => i !== index);
      const response = await axios.put(`/api/admin/resident/${id}`, { drugsOfChoice: updatedDrugs }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setResident(response.data);
    } catch (error) {
      console.error('Error deleting drug:', error);
    }
  };

  const handleAddProgram = async (newProgram) => {
    if (newProgram) {
      try {
        const updatedPrograms = [...(resident.recoveryPrograms || []), newProgram];
        const response = await axios.put(`/api/admin/resident/${id}`, { recoveryPrograms: updatedPrograms }, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setResident(response.data);
      } catch (error) {
        console.error('Error adding program:', error);
      }
    }
  };

  const handleDeleteProgram = async (index) => {
    try {
      const updatedPrograms = resident.recoveryPrograms.filter((_, i) => i !== index);
      const response = await axios.put(`/api/admin/resident/${id}`, { recoveryPrograms: updatedPrograms }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setResident(response.data);
    } catch (error) {
      console.error('Error deleting program:', error);
    }
  };

  const updateResidentField = async (field, value) => {
    if (!resident || !resident._id) {
      console.error('Resident or resident ID is undefined');
      return;
    }

    try {
      let updatedData = { ...resident, [field]: value };
      const response = await axios.put(`/api/admin/resident/${resident._id}`, updatedData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setResident(response.data);
    } catch (error) {
      console.error('Error updating resident field:', error);
    }
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  if (!resident) return <div>Loading...</div>;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, px: isMobile ? 1 : 2 }}>
        <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 3 }}>
          {/* ResidentHeader */}
          <Box sx={{ 
            width: isMobile ? '100%' : '40%', 
            position: isMobile ? 'static' : 'sticky', 
            top: isMobile ? 0 : 16, 
            alignSelf: isMobile ? 'auto' : 'flex-start',
            marginBottom: isMobile ? 3 : 0
          }}>
            <ResidentHeader 
              resident={resident}
              houses={houses}
              housesLoading={housesLoading}
              handleAvatarUpload={handleAvatarUpload}
              handleEditSave={handleEditSave}
              handleHouseChange={handleHouseChange}
              handleRoomChange={handleRoomChange}
              handleDateChange={handleDateChange}
              isMobile={isMobile}
              setResident={setResident}
            />
          </Box>

          {/* Right side content */}
          <Box sx={{ width: isMobile ? '100%' : '60%' }}>
            <Masonry columns={1} spacing={3}>
              <GeneralInformation 
                resident={resident}
                handleEditStart={handleEditStart}
                renderEditableField={renderEditableField}
                updateResidentField={updateResidentField}
                isMobile={isMobile}
              />
              <RecoveryInformation 
                resident={resident}
                handleEditStart={handleEditStart}
                renderEditableField={renderEditableField}
                handleDeleteDrug={handleDeleteDrug}
                handleDeleteProgram={handleDeleteProgram}
                handleAddDrug={handleAddDrug}
                handleAddProgram={handleAddProgram}
                isMobile={isMobile}
              />
              <LegalInformation 
                resident={resident}
                setResident={setResident}
                handleEditStart={handleEditStart}
                renderEditableField={renderEditableField}
                isMobile={isMobile}
              />
              <MedicationsBox 
                medications={medications} 
                setMedications={setMedications} 
                residentId={id}
                isMobile={isMobile}
              />
              <TreatmentHistoryBox 
                resident={resident} 
                setResident={setResident}
                isMobile={isMobile}
              />
              <EmergencyContactBox 
                resident={resident}
                handleEditStart={handleEditStart}
                renderEditableField={renderEditableField}
                isMobile={isMobile}
              />
            </Masonry>
          </Box>
        </Box>

        {/* Floating Action Button */}
        <Fab
          color="primary"
          aria-label="more actions"
          onClick={handleMenuOpen}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
          }}
        >
          <MoreVertIcon />
        </Fab>

        {/* Menu for FAB */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={() => {
            handleMenuClose();
            setOpenArchiveDialog(true);
          }}>
            <ListItemIcon>
              {resident.isArchived ? <UnarchiveIcon /> : <ArchiveIcon />}
            </ListItemIcon>
            <ListItemText>
              {resident.isArchived ? "Unarchive" : "Archive"} Resident
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {
            handleMenuClose();
            handleDelete();
          }}>
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            <ListItemText>Delete Resident</ListItemText>
          </MenuItem>
        </Menu>

        {/* Archive/Unarchive Dialog */}
        <Dialog
          open={openArchiveDialog}
          onClose={() => setOpenArchiveDialog(false)}
          aria-labelledby="archive-dialog-title"
          aria-describedby="archive-dialog-description"
        >
          <DialogTitle id="archive-dialog-title">
            {resident.isArchived ? "Unarchive Resident" : "Archive Resident"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="archive-dialog-description">
              Are you sure you want to {resident.isArchived ? "unarchive" : "archive"} this resident?
              {!resident.isArchived && " This action can be undone later."}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenArchiveDialog(false)}>Cancel</Button>
            <Button 
              onClick={resident.isArchived ? handleUnarchive : handleArchive} 
              color={resident.isArchived ? "primary" : "warning"}
            >
              {resident.isArchived ? "Unarchive" : "Archive"}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </LocalizationProvider>
  );
};

export default ResidentDetails;
/**
 * The `AdminOverview` component displays a table of admin information for super admins.
 * It fetches the list of admins from the API and renders a table with the following columns:
 * - Admin Name
 * - Email
 * - Subscription Plan
 * - Subscription Status
 * - Number of Residents
 * - Last Payment Date
 * - Actions (with a "View Details" button to navigate to the individual admin details page)
 *
 * The component uses the `useState` and `useEffect` hooks to manage the state of the admins list and fetch the data when the component mounts.
 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Container, Typography, Paper, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Button
} from '@mui/material';

// AdminOverview component: Displays a table of admin information for super admins
const AdminOverview = () => {
  // State to store the list of admins
  const [admins, setAdmins] = useState([]);

  // Fetch admin data when component mounts
  useEffect(() => {
    fetchAdminsData();
  }, []);

  // Function to fetch admin data from the API
  const fetchAdminsData = async () => {
    try {
      const response = await axios.get('/api/superadmin/admins-subscriptions', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setAdmins(response.data);
    } catch (error) {
      console.error('Error fetching admins data:', error);
      // TODO: Add user-friendly error handling
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Admin Overview</Typography>
      
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Admin Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Subscription Plan</TableCell>
              <TableCell>Subscription Status</TableCell>
              <TableCell>Number of Residents</TableCell>
              <TableCell>Last Payment Date</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Map through admins array and create a row for each admin */}
            {admins.map((admin) => (
              <TableRow key={admin._id}>
                <TableCell>{admin.name}</TableCell>
                <TableCell>{admin.email}</TableCell>
                <TableCell>{admin.subscriptionTier}</TableCell>
                <TableCell>{admin.subscriptionStatus}</TableCell>
                <TableCell>{admin.residentsCount}</TableCell>
                <TableCell>{admin.lastPaymentDate}</TableCell>
                <TableCell>
                  {/* Link to individual admin details page */}
                  <Button
                    component={Link}
                    to={`/superadmin/admin/${admin._id}`}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    View Details
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default AdminOverview;
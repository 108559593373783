/**
 * Renders a centered and card-like component that displays an "Access Denied" message.
 * This component should be used when the user is not authorized to access a certain feature.
 */
import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

const AccessDenied = () => {
  return (
    // Container to center the content and limit its width
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      {/* Paper component for a card-like appearance */}
      <Paper sx={{ p: 3, textAlign: 'center' }}>
        {/* Main heading */}
        <Typography variant="h4" gutterBottom>
          Access Denied
        </Typography>
        {/* Explanatory text */}
        <Typography variant="body1">
          Please contact the Super Admin to have this feature enabled.
        </Typography>
      </Paper>
    </Container>
  );
};

export default AccessDenied;
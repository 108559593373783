import React, { useState } from 'react';
import axios from 'axios';
import { Typography, Box, Paper, TextField, Button, Grid, Snackbar, InputAdornment, Collapse, IconButton } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { ContactPhone as EmergencyContactIcon, Edit as EditIcon, Save as SaveIcon, ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  overflow: 'hidden',
  transition: 'all 0.3s',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2, 3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const StyledTextField = styled(TextField)(({ theme, isediting }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: isediting === 'true' ? theme.palette.background.paper : 'transparent',
    transition: theme.transitions.create(['background-color', 'box-shadow']),
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: isediting === 'true' ? `1px solid ${theme.palette.primary.main}` : 'none',
  },
  '& .MuiInputBase-input': {
    padding: isediting === 'true' ? theme.spacing(1) : 0,
    fontWeight: 'normal',
    color: theme.palette.text.primary,
  },
  '& .MuiInputLabel-root': {
    display: isediting === 'true' ? 'block' : 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: isediting === 'true' ? theme.palette.primary.main : 'transparent',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: isediting === 'true' ? theme.palette.primary.main : 'transparent',
  },
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
  },
  transition: 'all 0.3s',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  border: `2px solid ${theme.palette.primary.contrastText}`,
}));

const ExpandButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.dark,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  marginRight: theme.spacing(2),
  transition: 'transform 0.3s',
  '&.expanded': {
    transform: 'rotate(180deg)',
  },
}));

const TitleBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const InfoItem = ({ icon, label, value, isEditing, onChange }) => (
  <Grid item xs={12}>
    <Box display="flex" alignItems="center" mb={2}>
      <InputAdornment position="start" sx={{ mr: 1, color: 'primary.main' }}>
        {icon}
      </InputAdornment>
      <Box flexGrow={1}>
        {!isEditing && (
          <Typography variant="body1" fontWeight="bold" color="primary.main">
            {label}
          </Typography>
        )}
        <StyledTextField
          fullWidth
          label={isEditing ? label : ''}
          value={value}
          onChange={onChange}
          InputProps={{
            readOnly: !isEditing,
          }}
          variant={isEditing ? "outlined" : "standard"}
          size="small"
          isediting={isEditing.toString()}
        />
      </Box>
    </Box>
  </Grid>
);

const EmergencyContactBox = ({ resident, setResident, isMobile }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [editedFields, setEditedFields] = useState({
    name: resident.emergencyContact?.name || '',
    relationship: resident.emergencyContact?.relationship || '',
    phoneNumber: resident.emergencyContact?.phoneNumber || '',
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleChange = (field) => (event) => {
    setEditedFields((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      const dataToSend = {
        emergencyContact: editedFields,
      };

      console.log('Sending data:', dataToSend);
      const response = await axios.put(
        `/api/admin/resident/${resident._id}`,
        dataToSend,
        {
          headers: { 
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        }
      );
      
      console.log('Response:', response.data);
      if (typeof setResident === 'function') {
        const updatedResident = {
          ...resident,
          emergencyContact: response.data.emergencyContact,
        };
        setResident(updatedResident);
      } else {
        console.warn('setResident is not a function. Unable to update resident state.');
      }
      setIsEditing(false);
      setSnackbarMessage('Emergency contact information updated successfully');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error updating emergency contact information:', error);
      console.error('Error response:', error.response);
      setSnackbarMessage(`Error updating emergency contact information: ${error.response?.data?.message || error.message}`);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <StyledPaper elevation={2}>
      <StyledHeader>
        <TitleBox>
          <ExpandButton
            onClick={handleToggleExpand}
            className={isExpanded ? 'expanded' : ''}
            size={isMobile ? "small" : "large"}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ExpandButton>
          <Typography variant={isMobile ? "subtitle1" : "h6"} fontWeight="bold">
            Emergency Contact
          </Typography>
        </TitleBox>
        <AnimatedButton
          onClick={isEditing ? handleSave : handleEdit}
          startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
          size={isMobile ? "small" : "medium"}
        >
          {isEditing ? 'Save' : 'Edit'}
        </AnimatedButton>
      </StyledHeader>
      <Collapse in={isExpanded}>
        <StyledContent>
          <Grid container spacing={isMobile ? 2 : 3}>
            <InfoItem
              icon={<EmergencyContactIcon />}
              label="Name"
              value={editedFields.name}
              isEditing={isEditing}
              onChange={handleChange('name')}
            />
            <InfoItem
              icon={<EmergencyContactIcon />}
              label="Relationship"
              value={editedFields.relationship}
              isEditing={isEditing}
              onChange={handleChange('relationship')}
            />
            <InfoItem
              icon={<EmergencyContactIcon />}
              label="Phone Number"
              value={editedFields.phoneNumber}
              isEditing={isEditing}
              onChange={handleChange('phoneNumber')}
            />
          </Grid>
        </StyledContent>
      </Collapse>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StyledPaper>
  );
};

export default EmergencyContactBox;
/**
 * Renders the user's profile page, including a form for editing their profile information.
 * 
 * The `Profile` component is responsible for displaying the user's profile information and providing a way for the user to update their profile. It uses the `ProfileForm` component to render the form for editing the user's profile.
 * 
 * The component is wrapped in a `Container` component from Material-UI to provide a responsive layout, and the profile information is displayed within a `Paper` component.
 */
import React from 'react';
import { Container, Typography, Paper } from '@mui/material';
import ProfileForm from '../components/common/ProfileForm';

// Profile component: Renders the user's profile page
const Profile = () => {
    return (
        // Container component for responsive layout
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Paper sx={{ p: 2 }}>
                <Typography variant="h4" gutterBottom>
                    Your Profile
                </Typography>
                <ProfileForm />
            </Paper>
        </Container>
    );
};

export default Profile;
/**
 * A React component that displays a card with the total monthly revenue.
 *
 * @param {object} props - The component props.
 * @param {number} props.revenue - The total monthly revenue to display.
 * @returns {JSX.Element} - The TotalRevenueCard component.
 */
import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

const TotalRevenueCard = ({ revenue }) => {
  return (
    <Card>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Total Monthly Revenue
        </Typography>
        <Typography variant="h5" component="h2">
          ${revenue.toFixed(2)}
        </Typography>
        <MonetizationOnIcon color="primary" />
      </CardContent>
    </Card>
  );
};

export default TotalRevenueCard;
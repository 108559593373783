/**
 * The `AdminRegister` component is a React component that renders a registration form for admin users.
 * It allows users to enter their name, email, password, and select a subscription tier.
 * When the form is submitted, it sends a POST request to the `/api/auth/register` endpoint with the user's information.
 * If the registration is successful, the user is redirected to the payment URL received from the server.
 * Additionally, a QR code is generated and stored in the database for the admin to share with their residents.
 */
import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { 
  TextField, Button, Container, Typography, Box, RadioGroup, 
  FormControlLabel, Radio, Paper, CssBaseline, Grid, useTheme
} from '@mui/material';
import QRCode from 'qrcode';
import logo from '../../assets/hopelinxlogo.png';

const AdminRegister = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [tier, setTier] = useState('');
    const [error, setError] = useState('');
    const theme = useTheme();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const response = await axios.post('/api/auth/register', { role: 'admin', name, email, password, tier });
            const { adminCode } = response.data;

            const qrCodeUrl = await QRCode.toDataURL(`${window.location.origin}/register/resident?adminCode=${adminCode}`);
            
            await axios.post('/api/auth/save-qr-code', { adminCode, qrCodeUrl });

            window.location.href = response.data.paymentUrl;
        } catch (error) {
            console.error('Registration error:', error.response?.data || error.message);
            setError(error.response?.data?.message || 'An error occurred during registration');
        }
    };

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
            }}
        >
            <Container component="main" maxWidth="xs"> {/* Change this line */}
                <CssBaseline />
                <Paper
                    elevation={6}
                    sx={{
                        padding: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: 2,
                        backgroundColor: 'rgba(255, 255, 255, 0.95)',
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            width: '100%',
                            maxWidth: 250,
                            height: 'auto',
                            mb: 4
                        }}
                        alt="Hopelink logo"
                        src={logo}
                    />
                    
                    <Typography 
                        component="h1" 
                        variant="h5" 
                        sx={{ 
                            mb: 4, 
                            color: theme.palette.primary.main, 
                            fontWeight: 'bold',
                            textAlign: 'center',
                            width: '100%'
                        }}
                    >
                        Register Admin
                    </Typography>
                    
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            autoComplete="name"
                            autoFocus
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={{ 
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.dark,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    backgroundColor: 'white',
                                },
                                '& .MuiInputLabel-root': {
                                    color: theme.palette.primary.main,
                                },
                                '& .MuiOutlinedInput-input': {
                                    color: 'black',
                                },
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            sx={{ 
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.dark,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    backgroundColor: 'white',
                                },
                                '& .MuiInputLabel-root': {
                                    color: theme.palette.primary.main,
                                },
                                '& .MuiOutlinedInput-input': {
                                    color: 'black',
                                },
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            sx={{ 
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.dark,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    backgroundColor: 'white',
                                },
                                '& .MuiInputLabel-root': {
                                    color: theme.palette.primary.main,
                                },
                                '& .MuiOutlinedInput-input': {
                                    color: 'black',
                                },
                            }}
                        />
                        <RadioGroup
                            aria-label="subscription-tier"
                            name="subscription-tier"
                            value={tier}
                            onChange={(e) => setTier(e.target.value)}
                            sx={{ 
                                mb: 3,
                                width: '100%',
                                '& .MuiFormControlLabel-root': {
                                    marginBottom: 1,
                                },
                                '& .MuiRadio-root': {
                                    color: theme.palette.primary.main,
                                },
                                '& .MuiFormControlLabel-label': {
                                    color: 'black',
                                },
                            }}
                        >
                            <FormControlLabel value="micro" control={<Radio />} label="Micro House: $25/month (up to 10 residents)" />
                            <FormControlLabel value="standard" control={<Radio />} label="Standard House: $45/month (11-25 residents)" />
                            <FormControlLabel value="large" control={<Radio />} label="Large House: $80/month (26-50 residents)" />
                            <FormControlLabel value="xlarge" control={<Radio />} label="XLarge House: $150/month (51-150 residents)" />
                        </RadioGroup>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ 
                                mt: 3, 
                                mb: 2, 
                                py: 1.5, 
                                fontSize: '1.1rem',
                                fontWeight: 'bold',
                            }}
                        >
                            Register Admin
                        </Button>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <Link to="/login" style={{ textDecoration: 'none', color: theme.palette.primary.main, fontWeight: 'bold' }}>
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
};

export default AdminRegister;
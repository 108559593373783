// client/src/pages/resident/FormSubmission.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Container, Typography, Paper, Box, TextField, Button,
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material';

const FormSubmission = () => {
  const [form, setForm] = useState(null);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const response = await axios.get(`/api/resident/forms/${id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setForm(response.data);
        setLoading(false);
      } catch (error) {
        setError('An error occurred while fetching the form');
        setLoading(false);
      }
    };

    fetchForm();
  }, [id]);

  const handleInputChange = (fieldName, value) => {
    setFormData(prevData => ({
      ...prevData,
      [fieldName]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`/api/resident/forms/${id}/submit`, formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      navigate('/resident/forms');
    } catch (error) {
      setError('An error occurred while submitting the form');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!form) return <div>Form not found</div>;

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h4" gutterBottom>{form.title}</Typography>
        <Box component="form" onSubmit={handleSubmit}>
          {form.fields.map((field, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              {field.type === 'text' && (
                <TextField
                  fullWidth
                  label={field.label}
                  required={field.required}
                  onChange={(e) => handleInputChange(field.label, e.target.value)}
                />
              )}
              {field.type === 'number' && (
                <TextField
                  fullWidth
                  label={field.label}
                  type="number"
                  required={field.required}
                  onChange={(e) => handleInputChange(field.label, e.target.value)}
                />
              )}
              {field.type === 'date' && (
                <TextField
                  fullWidth
                  label={field.label}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  required={field.required}
                  onChange={(e) => handleInputChange(field.label, e.target.value)}
                />
              )}
              {field.type === 'select' && (
                <FormControl fullWidth>
                  <InputLabel>{field.label}</InputLabel>
                  <Select
                    required={field.required}
                    onChange={(e) => handleInputChange(field.label, e.target.value)}
                  >
                    {field.options && field.options.map((option, i) => (
                      <MenuItem key={i} value={option}>{option}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>
          ))}
          <Button type="submit" variant="contained" color="primary">
            Submit Form
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default FormSubmission;
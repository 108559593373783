import React, { useState } from 'react';
import axios from 'axios';
import {
  Typography, Paper, Grid, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Select, MenuItem, FormControl, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box, Collapse, IconButton, InputAdornment, Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Person as PersonIcon, Phone as PhoneIcon, Email as EmailIcon, Add as AddIcon, Edit as EditIcon, Save as SaveIcon, ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { parseISO, isValid, format } from 'date-fns';

const StyledPaper = styled(Paper)(({ theme }) => ({
  overflow: 'hidden',
  transition: 'all 0.3s',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2, 3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const StyledTextField = styled(TextField)(({ theme, isediting }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: isediting === 'true' ? theme.palette.background.paper : 'transparent',
    transition: theme.transitions.create(['background-color', 'box-shadow']),
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: isediting === 'true' ? `1px solid ${theme.palette.primary.main}` : 'none',
  },
  '& .MuiInputBase-input': {
    padding: isediting === 'true' ? theme.spacing(1) : 0,
    fontWeight: 'normal',
    color: theme.palette.text.primary,
  },
  '& .MuiInputLabel-root': {
    display: isediting === 'true' ? 'block' : 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: isediting === 'true' ? theme.palette.primary.main : 'transparent',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: isediting === 'true' ? theme.palette.primary.main : 'transparent',
  },
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
  },
  transition: 'all 0.3s',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  border: `2px solid ${theme.palette.primary.contrastText}`,
}));

const ExpandButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.dark,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  marginRight: theme.spacing(2),
  transition: 'transform 0.3s',
  '&.expanded': {
    transform: 'rotate(180deg)',
  },
}));

const TitleBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const InfoItem = ({ icon, primary, secondary, onEdit }) => (
  <Grid container spacing={2} alignItems="center">
    <Grid item>{icon}</Grid>
    <Grid item xs>
      <Typography variant="body2" color="textSecondary">{primary}</Typography>
      <Typography variant="body1" onClick={onEdit} sx={{ cursor: 'pointer' }}>{secondary}</Typography>
    </Grid>
  </Grid>
);

const ResponsiveTableContainer = styled(TableContainer)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '& table, & thead, & tbody, & th, & td, & tr': {
      display: 'block',
    },
    '& thead tr': {
      position: 'absolute',
      top: '-9999px',
      left: '-9999px',
    },
    '& tr': {
      marginBottom: '1rem',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& td': {
      border: 'none',
      position: 'relative',
      paddingLeft: '50%',
      textAlign: 'right',
      '&:before': {
        content: 'attr(data-label)',
        position: 'absolute',
        left: 6,
        width: '45%',
        paddingRight: 10,
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        textAlign: 'left',
      },
    },
  },
}));

const LegalInformation = ({ resident, setResident, handleEditStart, renderEditableField, isMobile }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [openCriminalHistoryDialog, setOpenCriminalHistoryDialog] = useState(false);
  const [criminalHistoryForm, setCriminalHistoryForm] = useState({
    offense: '',
    type: 'Misdemeanor',
    date: null,
    location: '',
    outcome: ''
  });
  const [editingCriminalHistoryIndex, setEditingCriminalHistoryIndex] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const handleCriminalHistoryChange = (field, value) => {
    setCriminalHistoryForm(prev => ({ ...prev, [field]: value }));
  };

  const handleLegalInfoChange = async (field, value) => {
    if (!resident || !resident._id) {
      console.error('Resident or resident ID is undefined');
      return;
    }

    try {
      const updatedData = {
        ...resident,
        legalInformation: {
          ...resident.legalInformation,
          [field]: value
        }
      };
      const response = await axios.put(`/api/admin/resident/${resident._id}`, updatedData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setResident(response.data);
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
    }
  };

  const handleAddCriminalHistory = async () => {
    if (!resident || !resident._id) {
      console.error('Resident or resident ID is undefined');
      return;
    }

    try {
      let updatedCriminalHistory;
      if (editingCriminalHistoryIndex !== null) {
        updatedCriminalHistory = [...resident.legalInformation.criminalHistory];
        updatedCriminalHistory[editingCriminalHistoryIndex] = criminalHistoryForm;
      } else {
        updatedCriminalHistory = [...(resident.legalInformation?.criminalHistory || []), criminalHistoryForm];
      }
      
      const response = await axios.put(`/api/admin/resident/${resident._id}`, {
        'legalInformation.criminalHistory': updatedCriminalHistory
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setResident(response.data);
      setOpenCriminalHistoryDialog(false);
      setCriminalHistoryForm({
        offense: '',
        type: 'Misdemeanor',
        date: null,
        location: '',
        outcome: ''
      });
      setEditingCriminalHistoryIndex(null);
    } catch (error) {
      console.error('Error adding/updating criminal history:', error);
    }
  };

  const handleEditCriminalHistory = (index) => {
    const history = resident.legalInformation.criminalHistory[index];
    setCriminalHistoryForm({
      ...history,
      date: history.date ? parseISO(history.date) : null
    });
    setEditingCriminalHistoryIndex(index);
    setOpenCriminalHistoryDialog(true);
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = parseISO(dateString);
    return isValid(date) ? date.toLocaleDateString() : 'Invalid Date';
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      // Implement the save logic here
      setIsEditing(false);
      setSnackbarMessage('Legal information updated successfully');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error updating legal information:', error);
      setSnackbarMessage(`Error updating legal information: ${error.message}`);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <StyledPaper elevation={2}>
      <StyledHeader>
        <TitleBox>
          <ExpandButton
            onClick={handleToggleExpand}
            className={isExpanded ? 'expanded' : ''}
            size={isMobile ? "small" : "large"}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ExpandButton>
          <Typography variant={isMobile ? "subtitle1" : "h6"} fontWeight="bold">
            Legal Information
          </Typography>
        </TitleBox>
        <AnimatedButton
          onClick={isEditing ? handleSave : handleEdit}
          startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
          size={isMobile ? "small" : "medium"}
        >
          {isEditing ? 'Save' : 'Edit'}
        </AnimatedButton>
      </StyledHeader>
      <Collapse in={isExpanded}>
        <StyledContent>
          <Grid container spacing={isMobile ? 2 : 3}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="subtitle1">Criminal History</Typography>
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    setCriminalHistoryForm({
                      offense: '',
                      type: 'Misdemeanor',
                      date: null,
                      location: '',
                      outcome: ''
                    });
                    setEditingCriminalHistoryIndex(null);
                    setOpenCriminalHistoryDialog(true);
                  }}
                >
                  Add Criminal History
                </Button>
              </Box>
              <ResponsiveTableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Offense</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Location</TableCell>
                      <TableCell>Outcome</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resident.legalInformation?.criminalHistory?.map((history, index) => (
                      <TableRow 
                        key={index}
                        hover
                        onClick={() => handleEditCriminalHistory(index)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell data-label="Offense">{history.offense}</TableCell>
                        <TableCell data-label="Type">{history.type}</TableCell>
                        <TableCell data-label="Date">{formatDate(history.date)}</TableCell>
                        <TableCell data-label="Location">{history.location}</TableCell>
                        <TableCell data-label="Outcome">{history.outcome}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </ResponsiveTableContainer>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" gutterBottom>Sex Offender Status</Typography>
              <StyledTextField
                select
                fullWidth
                value={resident.legalInformation?.isSexOffender || false}
                onChange={(e) => handleLegalInfoChange('isSexOffender', e.target.value)}
                InputProps={{
                  readOnly: !isEditing,
                }}
                variant={isEditing ? "outlined" : "standard"}
                size="small"
                isediting={isEditing.toString()}
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </StyledTextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" gutterBottom>On Probation/Parole</Typography>
              <StyledTextField
                select
                fullWidth
                value={resident.legalInformation?.isOnProbationOrParole || false}
                onChange={(e) => handleLegalInfoChange('isOnProbationOrParole', e.target.value)}
                InputProps={{
                  readOnly: !isEditing,
                }}
                variant={isEditing ? "outlined" : "standard"}
                size="small"
                isediting={isEditing.toString()}
              >
                <MenuItem value={false}>No</MenuItem>
                <MenuItem value={true}>Yes</MenuItem>
              </StyledTextField>
            </Grid>
            {resident.legalInformation?.isOnProbationOrParole && (
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>Probation/Parole Officer Information</Typography>
                <Paper elevation={1} sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <InfoItem 
                        icon={<PersonIcon color="primary" />}
                        primary="Name"
                        secondary={renderEditableField('legalInformation.probationParoleOfficer.name', resident.legalInformation?.probationParoleOfficer?.name, 'Name')}
                        onEdit={() => handleEditStart('legalInformation.probationParoleOfficer.name', resident.legalInformation?.probationParoleOfficer?.name || '')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InfoItem 
                        icon={<PhoneIcon color="primary" />}
                        primary="Phone Number"
                        secondary={renderEditableField('legalInformation.probationParoleOfficer.phoneNumber', resident.legalInformation?.probationParoleOfficer?.phoneNumber, 'Phone Number')}
                        onEdit={() => handleEditStart('legalInformation.probationParoleOfficer.phoneNumber', resident.legalInformation?.probationParoleOfficer?.phoneNumber || '')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InfoItem 
                        icon={<EmailIcon color="primary" />}
                        primary="Email"
                        secondary={renderEditableField('legalInformation.probationParoleOfficer.email', resident.legalInformation?.probationParoleOfficer?.email, 'Email')}
                        onEdit={() => handleEditStart('legalInformation.probationParoleOfficer.email', resident.legalInformation?.probationParoleOfficer?.email || '')}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            )}
          </Grid>
        </StyledContent>
      </Collapse>

      <Dialog 
        open={openCriminalHistoryDialog} 
        onClose={() => setOpenCriminalHistoryDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{editingCriminalHistoryIndex !== null ? 'Edit Criminal History' : 'Add Criminal History'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                label="Offense"
                fullWidth
                value={criminalHistoryForm.offense}
                onChange={(e) => handleCriminalHistoryChange('offense', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth margin="dense">
                <Select
                  value={criminalHistoryForm.type}
                  onChange={(e) => handleCriminalHistoryChange('type', e.target.value)}
                >
                  <MenuItem value="Misdemeanor">Misdemeanor</MenuItem>
                  <MenuItem value="Felony">Felony</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <DatePicker
                label="Date"
                value={criminalHistoryForm.date}
                onChange={(newDate) => handleCriminalHistoryChange('date', newDate)}
                renderInput={(params) => <TextField {...params} fullWidth margin="dense" />}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Location"
                fullWidth
                value={criminalHistoryForm.location}
                onChange={(e) => handleCriminalHistoryChange('location', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Outcome"
                fullWidth
                value={criminalHistoryForm.outcome}
                onChange={(e) => handleCriminalHistoryChange('outcome', e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCriminalHistoryDialog(false)}>Cancel</Button>
          <Button onClick={handleAddCriminalHistory}>{editingCriminalHistoryIndex !== null ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StyledPaper>
  );
};

export default LegalInformation;
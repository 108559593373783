import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Container, Typography, Grid, Card, CardContent, CardActions, 
  Button, Chip, TextField, InputAdornment, Dialog, DialogTitle, 
  DialogContent, DialogActions, IconButton, Box, useTheme
} from '@mui/material';
import { 
  Search as SearchIcon,
  ErrorOutline as ErrorOutlineIcon,
  AccessTime as AccessTimeIcon,
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import AccessDenied from '../../components/common/AccessDenied';

const GrievanceRequests = () => {
  const [grievances, setGrievances] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [grievanceRequestEnabled, setGrievanceRequestEnabled] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGrievance, setSelectedGrievance] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [profileRes, grievancesRes] = await Promise.all([
          axios.get('/api/auth/profile', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          }),
          axios.get('/api/admin/grievances', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          }),
        ]);
        setGrievanceRequestEnabled(profileRes.data.grievanceRequestEnabled);
        setGrievances(grievancesRes.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.response?.data?.message || 'An error occurred');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!grievanceRequestEnabled) return <AccessDenied />;

  const fetchGrievances = async () => {
    try {
      const { data } = await axios.get('/api/admin/grievances', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setGrievances(data);
    } catch (error) {
      console.error('Error fetching grievances:', error);
    }
  };

  const updateGrievanceStatus = async (id, status) => {
    try {
      await axios.put(`/api/admin/grievances/${id}`, { status }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      fetchGrievances();
      setSelectedGrievance(null);
    } catch (error) {
      console.error('Error updating grievance:', error);
    }
  };

  const getStatusColor = (status) => {
    switch(status) {
      case 'pending': return theme.palette.warning.main;
      case 'in-progress': return theme.palette.info.main;
      case 'closed': return theme.palette.success.main;
      default: return theme.palette.grey[500];
    }
  };

  const getStatusIcon = (status) => {
    switch(status) {
      case 'pending': return <ErrorOutlineIcon />;
      case 'in-progress': return <AccessTimeIcon />;
      case 'closed': return <CheckCircleIcon />;
      default: return null;
    }
  };

  const filteredGrievances = grievances.filter(grievance => 
    grievance.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    grievance.resident.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>Grievances</Typography>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search grievances..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 4 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Grid container spacing={3}>
        {filteredGrievances.map((grievance) => (
          <Grid item xs={12} sm={6} md={4} key={grievance._id}>
            <Card 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                transition: 'all 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: theme.shadows[4],
                },
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" component="div" gutterBottom>
                  {grievance.title}
                </Typography>
                <Chip
                  icon={getStatusIcon(grievance.status)}
                  label={grievance.status.charAt(0).toUpperCase() + grievance.status.slice(1)}
                  sx={{ 
                    bgcolor: getStatusColor(grievance.status),
                    color: 'white',
                    mb: 2
                  }}
                />
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Resident: {grievance.resident.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {grievance.description.slice(0, 100)}...
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={() => setSelectedGrievance(grievance)}>
                  View Details
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      <Dialog open={!!selectedGrievance} onClose={() => setSelectedGrievance(null)} maxWidth="sm" fullWidth>
        {selectedGrievance && (
          <>
            <DialogTitle sx={{ m: 0, p: 2 }}>
              {selectedGrievance.title}
              <IconButton
                aria-label="close"
                onClick={() => setSelectedGrievance(null)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                Status: 
                <Chip
                  icon={getStatusIcon(selectedGrievance.status)}
                  label={selectedGrievance.status.charAt(0).toUpperCase() + selectedGrievance.status.slice(1)}
                  sx={{ 
                    bgcolor: getStatusColor(selectedGrievance.status),
                    color: 'white',
                    ml: 1
                  }}
                />
              </Typography>
              <Typography gutterBottom>Resident: {selectedGrievance.resident.name}</Typography>
              <Typography gutterBottom>Description: {selectedGrievance.description}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => updateGrievanceStatus(selectedGrievance._id, 'in-progress')} color="primary">
                Mark In Progress
              </Button>
              <Button onClick={() => updateGrievanceStatus(selectedGrievance._id, 'closed')} color="secondary">
                Mark Closed
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Container>
  );
};

export default GrievanceRequests;
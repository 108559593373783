import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  Container, Typography, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Button, TextField, Box
} from '@mui/material';
import AccessDenied from '../../components/common/AccessDenied';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentForm = ({ amount, onPaymentSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    setError(null);

    if (!stripe || !elements) {
      setError('Stripe has not been initialized.');
      setProcessing(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        throw new Error(error.message);
      }

      const { data } = await axios.post('/api/resident/rent-payments', {
        amount,
        paymentMethodId: paymentMethod.id
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });

      if (data.requires_action) {
        const { error: confirmError } = await stripe.confirmCardPayment(data.payment_intent_client_secret);
        if (confirmError) {
          throw new Error(confirmError.message);
        }
        // After successful confirmation, fetch the updated payment details
        const { data: confirmedPayment } = await axios.get(`/api/resident/rent-payments/${data.savedPayment._id}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        onPaymentSuccess(confirmedPayment);
      } else {
        onPaymentSuccess(data);
      }

      setProcessing(false);
      cardElement.clear();
    } catch (err) {
      console.error('Payment error:', err);
      setError(err.response?.data?.message || err.message || 'Payment failed. Please try again.');
      setProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={2}>
        <CardElement options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
          },
        }}/>
      </Box>
      {error && <Typography color="error" mb={2}>{error}</Typography>}
      <Button type="submit" disabled={!stripe || processing} variant="contained" color="primary">
        Pay ${amount}
      </Button>
    </form>
  );
};

const RentPayments = () => {
  const [rentPayments, setRentPayments] = useState([]);
  const [newPayment, setNewPayment] = useState({ amount: '' });
  const [rentPaymentEnabled, setRentPaymentEnabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const [profileRes, paymentsRes] = await Promise.all([
        axios.get('/api/auth/profile', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }),
        axios.get('/api/resident/rent-payments', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }),
      ]);
      setRentPaymentEnabled(profileRes.data.rentPaymentEnabled);
      setRentPayments(paymentsRes.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 403) {
        setRentPaymentEnabled(false);
      } else {
        setError(error.response?.data?.message || 'An error occurred');
      }
      setLoading(false);
    }
  };

  const handlePaymentSuccess = (payment) => {
    setRentPayments([...rentPayments, payment]);
    setNewPayment({ amount: '' });
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!rentPaymentEnabled) return <AccessDenied />;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Rent Payments</Typography>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>Make a Payment</Typography>
        <TextField
          label="Amount"
          type="number"
          value={newPayment.amount}
          onChange={(e) => setNewPayment({ ...newPayment, amount: e.target.value })}
          required
        />
        <Elements stripe={stripePromise}>
          <PaymentForm amount={newPayment.amount} onPaymentSuccess={handlePaymentSuccess} />
        </Elements>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Paid Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rentPayments.map((payment) => (
              <TableRow key={payment._id}>
                <TableCell>${payment.amount}</TableCell>
                <TableCell>{payment.status}</TableCell>
                <TableCell>{payment.paidDate ? new Date(payment.paidDate).toLocaleDateString() : 'N/A'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default RentPayments;
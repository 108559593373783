import React from 'react';
import { Box, Container, Grid, Typography, TextField, Button, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import logoDark from '../../assets/hopelinxdark.png';

const Footer = () => {
  return (
    <Box 
      component="footer"
      sx={{ 
        bgcolor: 'background.paper', 
        py: 8,
        borderTop: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Box sx={{ mb: 4 }}>
              <img src={logoDark} alt="HopeLink Logo" style={{ height: 40 }} />
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3, maxWidth: 300 }}>
              Empowering recovery houses and residents with cutting-edge management tools and personalized support.
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
              {['Facebook', 'Twitter', 'LinkedIn', 'Instagram'].map((network) => (
                <IconButton key={network} color="primary" aria-label={network} sx={{ 
                  '&:hover': { bgcolor: 'primary.light' }
                }}>
                  {network === 'Facebook' && <FacebookIcon />}
                  {network === 'Twitter' && <TwitterIcon />}
                  {network === 'LinkedIn' && <LinkedInIcon />}
                  {network === 'Instagram' && <InstagramIcon />}
                </IconButton>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" color="text.primary" gutterBottom fontWeight="bold">
              Quick Links
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Link href="#" color="text.secondary" sx={{ mb: 1, '&:hover': { color: 'primary.main' } }}>
                About Us
              </Link>
              <Link href="#" color="text.secondary" sx={{ mb: 1, '&:hover': { color: 'primary.main' } }}>
                Features
              </Link>
              <Link href="#" color="text.secondary" sx={{ mb: 1, '&:hover': { color: 'primary.main' } }}>
                Pricing
              </Link>
              <Link href="#" color="text.secondary" sx={{ mb: 1, '&:hover': { color: 'primary.main' } }}>
                Contact Us
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" color="text.primary" gutterBottom fontWeight="bold">
              Subscribe
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Stay updated with our newsletter
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <TextField
                variant="outlined"
                size="small"
                placeholder="Your email"
                sx={{ mr: 1, flexGrow: 1 }}
              />
              <Button variant="contained" color="primary" sx={{ minWidth: 'auto', px: 2 }}>
                <ArrowForwardIcon />
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box mt={5} pt={3} borderTop={1} borderColor="divider">
          <Typography variant="body2" color="text.secondary" align="center">
            © {new Date().getFullYear()} HopeLink. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
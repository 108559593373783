/**
 * The main React component that serves as the entry point for the application.
 * It sets up the routing, theme, and authentication context for the application.
 * 
 * The App component is responsible for rendering the appropriate pages based on the current URL and user's authentication status and role.
 * It uses the `PrivateRoute` component to ensure that only authorized users can access certain pages.
 * 
 * The available routes are:
 * - `/`: The landing page
 * - `/login`: The login page
 * - `/register/admin`: The admin registration page
 * - `/register/resident`: The resident registration page
 * - `/register/superadmin`: The super admin registration page
 * - `/admin`: The admin dashboard page (accessible only to admin users)
 * - `/admin/residents`: The admin residents list page (accessible only to admin users)
 * - `/admin/resident/:id`: The individual resident details page (accessible only to admin users)
 * - `/admin/settings`: The admin settings page (accessible only to admin users)
 * - `/admin/rent-payments`: The admin rent payments page (accessible only to admin users)
 * - `/admin/maintenance-requests`: The admin maintenance requests page (accessible only to admin users)
 * - `/admin/grievances`: The admin grievances page (accessible only to admin users)
 * - `/resident`: The resident dashboard page (accessible only to resident users)
 * - `/resident/maintenance-requests`: The resident maintenance requests page (accessible only to resident users)
 * - `/resident/grievances`: The resident grievances page (accessible only to resident users)
 * - `/resident/rent-payments`: The resident rent payments page (accessible only to resident users)
 * - `/superadmin`: The super admin dashboard page (accessible only to super admin users)
 * - `/superadmin/admin-overview`: The admin overview page (accessible only to super admin users)
 * - `/superadmin/admin/:id`: The individual admin details page (accessible only to super admin users)
 * - `/superadmin/settings`: The super admin settings page (accessible only to super admin users)
 * - `/logout`: Redirects the user to the login page
 * - `/register/confirm`: The registration confirmation page
 * - `/profile`: The profile page (accessible to all authenticated users)
 * - `/access-denied`: The access denied page (accessible to all users)
 * 
 * The App component uses the `ThemeProvider` and `CssBaseline` components from Material-UI to set up a consistent theme and baseline styles for the application.
 * The `AuthProvider` component is used to manage the user's authentication state and provide it to the rest of the application.
 */
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { AuthProvider } from './contexts/AuthContext';
import { ColorModeProvider } from './contexts/ColorModeContext';
import PrivateRoute from './components/common/PrivateRoute';
import MainLayout from './components/layout/MainLayout';
import LandingPage from './pages/LandingPage';
import Login from './pages/Login';
import AdminRegister from './pages/admin/AdminRegister';
import ResidentRegister from './pages/resident/ResidentRegister';
import SuperAdminRegister from './pages/superadmin/SuperAdminRegister';
import AdminDashboard from './pages/admin/AdminDashboard';
import ResidentDashboard from './pages/resident/ResidentDashboard';
import SuperAdminDashboard from './pages/superadmin/SuperAdminDashboard';
import AdminOverview from './pages/superadmin/AdminOverview';
import AdminDetails from './pages/superadmin/AdminDetails';
import RegistrationConfirmation from './pages/admin/RegistrationConfirmation';
import Profile from './pages/Profile';
import AdminMaintenanceRequests from './pages/admin/MaintenanceRequests';
import ResidentMaintenanceRequests from './pages/resident/MaintenanceRequests';
import AccessDenied from './components/common/AccessDenied';
import ResidentsList from './pages/admin/ResidentsList';
import ResidentDetails from './pages/admin/ResidentDetails';
import SuperAdminSettings from './pages/superadmin/SuperAdminSettings';
import AdminSettings from './pages/admin/AdminSettings';
import AdminRentPayments from './pages/admin/RentPayments';
import ResidentRentPayments from './pages/resident/RentPayments';
import AdminGrievances from './pages/admin/GrievanceRequests';
import ResidentGrievances from './pages/resident/GrievanceRequests';
import AdminHouses from './pages/admin/AdminHouses';
import Organization from './pages/admin/Organization';
import AdminForms from './pages/admin/Forms';
import ResidentForms from './pages/resident/Forms';
import FormSubmission from './pages/resident/FormSubmission';
import AdminHouseDetails from './pages/admin/AdminHouseDetails';
import ResidentManagement from './pages/admin/ResidentManagement';
import ComingSoonPage from './pages/ComingSoonPage';

// Add this line near the top of your file, after the imports
const SHOW_COMING_SOON = process.env.REACT_APP_SHOW_COMING_SOON === 'true';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    if (authStatus === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  const handleAuthentication = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true');
  };

  return (
    <ColorModeProvider>
      <CssBaseline />
      <AuthProvider>
        <Router>
          {SHOW_COMING_SOON && !isAuthenticated ? (
            <ComingSoonPage onAuthenticate={handleAuthentication} />
          ) : (
            <Routes>
              {/* Public routes */}
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register/admin" element={<AdminRegister />} />
              <Route path="/register/resident" element={<ResidentRegister />} />
              <Route path="/register/superadmin" element={<SuperAdminRegister />} />
              <Route path="/register/confirm" element={<RegistrationConfirmation />} />
              
              {/* Admin-only routes */}
              <Route element={<PrivateRoute role="admin" />}>
                <Route path="/admin" element={<MainLayout><AdminDashboard /></MainLayout>} />
                <Route path="/admin/residents" element={<MainLayout><ResidentsList /></MainLayout>} />
                <Route path="/admin/resident/:id" element={<MainLayout><ResidentDetails /></MainLayout>} />
                <Route path="/admin/maintenance-requests" element={<MainLayout><AdminMaintenanceRequests /></MainLayout>} />
                <Route path="/admin/grievances" element={<MainLayout><AdminGrievances /></MainLayout>} />
                <Route path="/admin/settings" element={<MainLayout><AdminSettings /></MainLayout>} />
                <Route path="/admin/rent-payments" element={<MainLayout><AdminRentPayments /></MainLayout>} />
                <Route path="/admin/houses" element={<MainLayout><AdminHouses /></MainLayout>} />
                <Route path="/admin/houses/:id" element={<MainLayout><AdminHouseDetails /></MainLayout>} />
                <Route path="/admin/organization" element={<MainLayout><Organization /></MainLayout>} />
                <Route path="/admin/forms" element={<MainLayout><AdminForms /></MainLayout>} />
                <Route path="/admin/resident-management" element={<MainLayout><ResidentManagement /></MainLayout>} />
              </Route>
              
              {/* Resident-only routes */}
              <Route element={<PrivateRoute role="resident" />}>
                <Route path="/resident" element={<MainLayout><ResidentDashboard /></MainLayout>} />
                <Route path="/resident/maintenance-requests" element={<MainLayout><ResidentMaintenanceRequests /></MainLayout>} />
                <Route path="/resident/grievances" element={<MainLayout><ResidentGrievances /></MainLayout>} />
                <Route path="/resident/rent-payments" element={<MainLayout><ResidentRentPayments /></MainLayout>} />
                <Route path="/resident/forms" element={<MainLayout><ResidentForms /></MainLayout>} />
                <Route path="/resident/forms/:id" element={<FormSubmission />} />
              </Route>
              
              {/* Super admin-only routes */}
              <Route element={<PrivateRoute role="superadmin" />}>
                <Route path="/superadmin" element={<MainLayout><SuperAdminDashboard /></MainLayout>} />
                <Route path="/superadmin/admin-overview" element={<MainLayout><AdminOverview /></MainLayout>} />
                <Route path="/superadmin/admin/:id" element={<MainLayout><AdminDetails /></MainLayout>} />
                <Route path="/superadmin/settings" element={<MainLayout><SuperAdminSettings /></MainLayout>} />
              </Route>
              
              {/* Route accessible to all authenticated users */}
              <Route element={<PrivateRoute />}>
                <Route path="/profile" element={<MainLayout><Profile /></MainLayout>} />
              </Route>
              
              {/* Logout route (redirects to login) */}
              <Route path="/logout" element={<Navigate to="/login" />} />
              {/* Access denied page */}
              <Route path="/access-denied" element={<AccessDenied />} />
            </Routes>
          )}
        </Router>
      </AuthProvider>
    </ColorModeProvider>
  );
}

export default App;
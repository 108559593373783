/**
 * A React component that renders a private route, ensuring that only authenticated users with the specified role can access the route.
 *
 * @param {Object} props - The component props.
 * @param {string} props.role - The required role for the user to access the route.
 * @returns {JSX.Element} - The rendered component, or a redirect to the login page or the home page if the user is not authenticated or does not have the required role.
 */
import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

const PrivateRoute = ({ role }) => {
  // Destructure authentication data from AuthContext
  const { isAuthenticated, user, loading } = useContext(AuthContext);

  // Show loading state while authentication is being checked
  if (loading) {
    return <div>Loading...</div>;
  }

  // Redirect to login page if user is not authenticated
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Redirect to home page if a role is required and user doesn't have it
  if (role && user.role !== role) {
    return <Navigate to="/" />;
  }

  // Render the protected route content
  return <Outlet />;
};

export default PrivateRoute;
/**
 * React component that renders a form for registering a resident.
 * 
 * The component handles the state for the form fields (name, email, password, adminCode) and
 * submits the form data to the server using an axios POST request to the '/api/auth/register'
 * endpoint. If the registration is successful, the user is navigated to the '/login' page.
 * If there is an error, an error message is displayed.
 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { 
  TextField, Button, Container, Typography, Box, Alert, Paper, 
  CssBaseline, Grid, useTheme
} from '@mui/material';
import logo from '../../assets/hopelinxlogo.png';

const ResidentRegister = () => {
    // State variables for form inputs and error handling
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [adminCode, setAdminCode] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();

    // Extract admin code from URL query parameters
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const adminCodeFromUrl = queryParams.get('adminCode');
        if (adminCodeFromUrl) {
            setAdminCode(adminCodeFromUrl);
        }
    }, [location.search]);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            // Send POST request to register endpoint
            const response = await axios.post('/api/auth/register', { role: 'resident', name, email, password, adminCode });
            console.log('Registration successful:', response.data);
            // Redirect to login page on successful registration
            navigate('/login');
        } catch (error) {
            console.error('Registration error:', error.response?.data || error.message);
            // Set error message for display
            setError(error.response?.data?.message || 'An error occurred during registration');
        }
    };

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
            }}
        >
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Paper
                    elevation={6}
                    sx={{
                        padding: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: 2,
                        backgroundColor: 'rgba(255, 255, 255, 0.95)',
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            width: '100%',
                            maxWidth: 250,
                            height: 'auto',
                            mb: 4
                        }}
                        alt="Hopelink logo"
                        src={logo}
                    />
                    
                    <Typography 
                        component="h1" 
                        variant="h5" 
                        sx={{ 
                            mb: 4, 
                            color: theme.palette.primary.main, 
                            fontWeight: 'bold',
                            textAlign: 'center',
                            width: '100%'
                        }}
                    >
                        Register Resident
                    </Typography>
                    
                    {error && <Alert severity="error" sx={{ mt: 2, width: '100%' }}>{error}</Alert>}
                    
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            autoComplete="name"
                            autoFocus
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={{ 
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.dark,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    backgroundColor: 'white',
                                },
                                '& .MuiInputLabel-root': {
                                    color: theme.palette.primary.main,
                                },
                                '& .MuiOutlinedInput-input': {
                                    color: 'black',
                                },
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            sx={{ 
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.dark,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    backgroundColor: 'white',
                                },
                                '& .MuiInputLabel-root': {
                                    color: theme.palette.primary.main,
                                },
                                '& .MuiOutlinedInput-input': {
                                    color: 'black',
                                },
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            sx={{ 
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.dark,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    backgroundColor: 'white',
                                },
                                '& .MuiInputLabel-root': {
                                    color: theme.palette.primary.main,
                                },
                                '& .MuiOutlinedInput-input': {
                                    color: 'black',
                                },
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="adminCode"
                            label="Admin Code"
                            id="adminCode"
                            value={adminCode}
                            onChange={(e) => setAdminCode(e.target.value)}
                            sx={{ 
                                mb: 3,
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    '&:hover fieldset': {
                                        borderColor: theme.palette.primary.dark,
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: theme.palette.primary.main,
                                    },
                                    backgroundColor: 'white',
                                },
                                '& .MuiInputLabel-root': {
                                    color: theme.palette.primary.main,
                                },
                                '& .MuiOutlinedInput-input': {
                                    color: 'black',
                                },
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ 
                                mt: 3, 
                                mb: 2, 
                                py: 1.5, 
                                fontSize: '1.1rem',
                                fontWeight: 'bold',
                            }}
                        >
                            Register Resident
                        </Button>
                        <Grid container justifyContent="center">
                            <Grid item>
                                <Link to="/login" style={{ textDecoration: 'none', color: theme.palette.primary.main, fontWeight: 'bold' }}>
                                    Already have an account? Sign in
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
            </Container>
        </Box>
    );
};

export default ResidentRegister;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Grid, TextField, Button, Typography, Snackbar, Alert, 
  List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';

const EmailTemplateManager = () => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [editedContent, setEditedContent] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    fetchTemplates();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchTemplates = async () => {
    try {
      const response = await axios.get('/api/superadmin/email-templates');
      setTemplates(response.data);
    } catch (error) {
      console.error('Error fetching email templates:', error);
      showSnackbar('Error fetching email templates', 'error');
    }
  };

  const handleTemplateSelect = async (template) => {
    try {
      const response = await axios.get(`/api/superadmin/email-templates/${template.id}`);
      setSelectedTemplate(template);
      setEditedContent(response.data.content);
      setDialogOpen(true);
    } catch (error) {
      console.error('Error fetching template content:', error);
      showSnackbar('Error fetching template content', 'error');
    }
  };

  const handleSaveTemplate = async () => {
    try {
      await axios.put(`/api/superadmin/email-templates/${selectedTemplate.id}`, {
        content: editedContent
      });
      showSnackbar('Template updated successfully', 'success');
      setDialogOpen(false);
      fetchTemplates();
    } catch (error) {
      console.error('Error updating email template:', error);
      showSnackbar('Error updating email template', 'error');
    }
  };

  const showSnackbar = (message, severity) => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Email Templates</Typography>
      </Grid>
      <Grid item xs={12}>
        <List>
          {templates.map((template) => (
            <ListItem key={template.id} button onClick={() => handleTemplateSelect(template)}>
              <ListItemText primary={template.name} />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>{selectedTemplate?.name}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={20}
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            variant="outlined"
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSaveTemplate} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};

export default EmailTemplateManager;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Typography, List, ListItem, ListItemText, Button, TextField, Box } from '@mui/material';
import AccessDenied from '../../components/common/AccessDenied';

const GrievanceRequests = () => {
    const [grievances, setGrievances] = useState([]);
    const [newGrievance, setNewGrievance] = useState({ title: '', description: '' });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [grievanceRequestEnabled, setGrievanceRequestEnabled] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const [profileRes, grievancesRes] = await Promise.all([
                axios.get('/api/auth/profile', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                }),
                axios.get('/api/resident/grievances', {
                    headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
                }),
            ]);
            setGrievanceRequestEnabled(profileRes.data.grievanceRequestEnabled);
            setGrievances(grievancesRes.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            if (error.response && error.response.status === 403) {
                setGrievanceRequestEnabled(false);
            } else {
                setError(error.response?.data?.message || 'An error occurred');
            }
            setLoading(false);
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!grievanceRequestEnabled) return <AccessDenied />;

    const fetchGrievances = async () => {
        try {
            const { data } = await axios.get('/api/resident/grievances', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setGrievances(data);
        } catch (error) {
            console.error('Error fetching grievances:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/api/resident/grievances', newGrievance, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setNewGrievance({ title: '', description: '' });
            fetchGrievances();
        } catch (error) {
            console.error('Error creating grievance:', error);
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>Grievances</Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Title"
                    value={newGrievance.title}
                    onChange={(e) => setNewGrievance({ ...newGrievance, title: e.target.value })}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Description"
                    value={newGrievance.description}
                    onChange={(e) => setNewGrievance({ ...newGrievance, description: e.target.value })}
                    fullWidth
                    multiline
                    rows={4}
                    margin="normal"
                />
                <Button type="submit" variant="contained" color="primary">
                    Submit Grievance
                </Button>
            </form>
            <List>
                {grievances.map((grievance) => (
                    <ListItem key={grievance._id}>
                        <ListItemText
                            primary={grievance.title}
                            secondary={`Status: ${grievance.status} | Description: ${grievance.description}`}
                        />
                    </ListItem>
                ))}
            </List>
        </Container>
    );
};

export default GrievanceRequests;
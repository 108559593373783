import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  Divider,
  useMediaQuery,
  useTheme,
  Collapse,
  InputBase,
} from '@mui/material';
import {
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  Person as PersonIcon,
  Build as BuildIcon,
  Settings as SettingsIcon,
  Payment as PaymentIcon,
  ExpandLess,
  ExpandMore,
  Feedback as FeedbackIcon,
  Email as EmailIcon,
  HomeWork as HomeWorkIcon,
  Business as BusinessIcon,
  Description as DescriptionIcon,
  Search as SearchIcon,
  ManageAccounts as ManageAccountsIcon,
} from '@mui/icons-material';
import { useAuth } from '../../hooks/useAuth';
import { styled, alpha } from '@mui/material/styles';
import hopelinxLogo from '../../assets/hopel.png';

// Styled components
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  margin: theme.spacing(2),
  width: 'auto',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
}));

const drawerWidth = 280;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    background: theme.palette.mode === 'light'
      ? `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`
      : theme.palette.background.paper,
    color: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.text.primary,
    borderRight: 'none',
    boxShadow: theme.shadows[3],
    overflowX: 'hidden',
    [theme.breakpoints.down('sm')]: {
      zIndex: theme.zIndex.modal + 100, // Increase zIndex for mobile
      height: '100%', // Ensure full height coverage
    },
  },
}));

const NavItem = styled(ListItem)(({ theme, selected }) => ({
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(0.5, 1),
  padding: theme.spacing(1, 2),
  color: selected ? theme.palette.secondary.main : alpha(theme.palette.common.white, 0.7),
  backgroundColor: selected ? alpha(theme.palette.common.white, 0.1) : 'transparent',
  transition: theme.transitions.create(['background-color', 'color'], {
    duration: theme.transitions.duration.short,
  }),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.2),
    color: theme.palette.common.white,
  },
}));

const NavItemIcon = styled(ListItemIcon)(({ theme, selected }) => ({
  minWidth: 40,
  color: selected ? theme.palette.secondary.main : alpha(theme.palette.common.white, 0.7),
}));

const NavItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    fontSize: '0.875rem',
    fontWeight: 500,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const LogoContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Sidebar = ({ mobileOpen, onClose }) => {
  const { user } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [features, setFeatures] = useState({
    maintenanceRequestEnabled: false,
    rentPaymentEnabled: false,
    grievanceRequestEnabled: false,
    formsEnabled: false,
  });
  const [openSettings, setOpenSettings] = useState(false);
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await axios.get('/api/auth/profile', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setFeatures({
          maintenanceRequestEnabled: data.maintenanceRequestEnabled,
          rentPaymentEnabled: data.rentPaymentEnabled,
          grievanceRequestEnabled: data.grievanceRequestEnabled,
          formsEnabled: data.formsEnabled,
        });
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUserData();
  }, [user]);

  const handleSettingsClick = () => {
    setOpenSettings(!openSettings);
  };

  const NavItemLink = ({ to, primary, icon }) => {
    const selected = location.pathname === to;
    return (
      <NavItem
        button
        component={Link}
        to={to}
        onClick={isMobile ? onClose : undefined}
        selected={selected}
      >
        <NavItemIcon selected={selected}>{icon}</NavItemIcon>
        <NavItemText primary={primary} />
      </NavItem>
    );
  };

  const renderNavItems = () => {
    const items = [
      { to: `/${user.role}`, primary: 'Dashboard', icon: <DashboardIcon />, roles: ['superadmin', 'admin'] },
      { to: '/superadmin/admin-overview', primary: 'Admin Overview', icon: <PeopleIcon />, roles: ['superadmin'] },
      { to: '/admin/residents', primary: 'My Residents', icon: <PeopleIcon />, roles: ['admin'] },
      { to: '/admin/houses', primary: 'Houses', icon: <HomeWorkIcon />, roles: ['admin'] },
      { to: '/admin/organization', primary: 'Organization', icon: <BusinessIcon />, roles: ['admin'] },
      { to: '/resident', primary: 'My Dashboard', icon: <PersonIcon />, roles: ['resident'] },
      // Add the new ResidentManagement page for admin users
      { to: '/admin/resident-management', primary: 'Resident Management', icon: <ManageAccountsIcon />, roles: ['admin'] },
    ];

    if (features.maintenanceRequestEnabled) {
      items.push({ to: `/${user.role}/maintenance-requests`, primary: 'Maintenance', icon: <BuildIcon />, roles: ['admin', 'resident'] });
    }
    if (features.rentPaymentEnabled) {
      items.push({ to: `/${user.role}/rent-payments`, primary: 'Rent Payments', icon: <PaymentIcon />, roles: ['admin', 'resident'] });
    }
    if (features.grievanceRequestEnabled) {
      items.push({ to: `/${user.role}/grievances`, primary: 'Grievances', icon: <FeedbackIcon />, roles: ['admin', 'resident'] });
    }
    if (features.formsEnabled) {
      items.push({ to: `/${user.role}/forms`, primary: 'Forms', icon: <DescriptionIcon />, roles: ['admin', 'resident'] });
    }

    return items
      .filter(item => item.roles.includes(user.role))
      .map((item, index) => <NavItemLink key={index} {...item} />);
  };

  const handleSearch = async (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (term.length > 2) {
      try {
        const response = await axios.get(`/api/${user.role}/search?term=${term}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setSearchResults(response.data);
      } catch (error) {
        console.error('Error searching:', error);
      }
    } else {
      setSearchResults([]);
    }
  };

  const handleSearchResultClick = (result) => {
    setSearchTerm('');
    setSearchResults([]);
    if (result.type === 'resident') {
      navigate(`/${user.role}/resident/${result.id}`);
    } else if (result.type === 'house') {
      navigate(`/${user.role}/houses/${result.id}`);
    }
    // Add more conditions for other types as needed
  };

  const drawer = (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflowX: 'hidden' }}>
      <LogoContainer>
        <img src={hopelinxLogo} alt="Hopelinx Logo" style={{ height: '40px' }} />
      </LogoContainer>
      
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search..."
          inputProps={{ 'aria-label': 'search' }}
          value={searchTerm}
          onChange={handleSearch}
        />
      </Search>
      
      {searchResults.length > 0 && (
        <List sx={{ mt: 1 }}>
          {searchResults.map((result) => (
            <ListItem 
              button 
              key={result.id} 
              onClick={() => handleSearchResultClick(result)}
            >
              <ListItemText 
                primary={result.name} 
                secondary={result.type.charAt(0).toUpperCase() + result.type.slice(1)}
              />
            </ListItem>
          ))}
        </List>
      )}

      <List component="nav" sx={{ flexGrow: 1, py: 2 }}>
        {renderNavItems()}
        <NavItem button onClick={handleSettingsClick}>
          <NavItemIcon><SettingsIcon /></NavItemIcon>
          <NavItemText primary="Settings" />
          {openSettings ? <ExpandLess /> : <ExpandMore />}
        </NavItem>
        <Collapse in={openSettings} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 2 }}>
            <NavItemLink to={`/${user.role}/settings/profile`} primary="Profile" icon={<PersonIcon />} />
            <NavItemLink to={`/${user.role}/settings/account`} primary="Account" icon={<SettingsIcon />} />
            {(user.role === 'admin' || user.role === 'superadmin') && (
              <NavItemLink to={`/${user.role}/settings`} primary="Email Settings" icon={<EmailIcon />} />
            )}
          </List>
        </Collapse>
      </List>
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="caption" color="textSecondary">
          © 2024 Hopelinx
        </Typography>
      </Box>
    </Box>
  );

  return (
    <StyledDrawer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={isMobile ? mobileOpen : true}
      onClose={onClose}
      ModalProps={{
        keepMounted: true,
      }}
      PaperProps={{
        style: {
          position: 'fixed', // Ensure it's fixed positioned
          top: 0, // Start from the top of the screen
          left: 0, // Align to the left
          height: '100%', // Full height
        },
      }}
    >
      {drawer}
    </StyledDrawer>
  );
};

export default Sidebar;
/**
 * A React component that displays a breakdown of subscription tiers and their respective counts.
 *
 * @param {Object} breakdown - An object where the keys are the subscription tiers and the values are the counts for each tier.
 * @returns {JSX.Element} - A Material-UI Card component displaying the subscription tier breakdown.
 */
import React from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemText } from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';

const SubscriptionTierBreakdownCard = ({ breakdown }) => {
  return (
    <Card>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Subscription Tier Breakdown
        </Typography>
        <List>
          {Object.entries(breakdown).map(([tier, count]) => (
            <ListItem key={tier}>
              <ListItemText primary={`${tier}: ${count}`} />
            </ListItem>
          ))}
        </List>
        <AssessmentIcon color="primary" />
      </CardContent>
    </Card>
  );
};

export default SubscriptionTierBreakdownCard;
// client/src/components/admin/MedicationManager.js

import React, { useState } from 'react';
import axios from 'axios';
import { Typography, List, ListItem, ListItemText, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem, Snackbar, Box, IconButton, Card, CardContent, CardActions, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const MedicationManager = ({ medications, setMedications, residents }) => {
  const [open, setOpen] = useState(false);
  const [newMedication, setNewMedication] = useState({ name: '', dosage: '', frequency: '', time: '', resident: '' });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [editingMedication, setEditingMedication] = useState(null);

  const handleCreate = async () => {
    try {
      const res = await axios.post('/api/admin/medications', newMedication, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setMedications([...medications, res.data]);
      setOpen(false);
      setNewMedication({ name: '', dosage: '', frequency: '', time: '', resident: '' });
      setSnackbar({ open: true, message: 'Medication added successfully', severity: 'success' });
    } catch (error) {
      console.error('Error creating medication:', error);
      setSnackbar({ open: true, message: 'Error adding medication', severity: 'error' });
    }
  };

  const handleEdit = (medication) => {
    setEditingMedication(medication);
    setNewMedication(medication);
    setOpen(true);
  };

  const handleUpdate = async () => {
    try {
      const res = await axios.put(`/api/admin/medications/${editingMedication._id}`, newMedication, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setMedications(medications.map(med => med._id === editingMedication._id ? res.data : med));
      setOpen(false);
      setEditingMedication(null);
      setNewMedication({ name: '', dosage: '', frequency: '', time: '', resident: '' });
      setSnackbar({ open: true, message: 'Medication updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Error updating medication:', error);
      setSnackbar({ open: true, message: 'Error updating medication', severity: 'error' });
    }
  };

  const handleDelete = async (medicationId) => {
    try {
      await axios.delete(`/api/admin/medications/${medicationId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setMedications(medications.filter(med => med._id !== medicationId));
      setSnackbar({ open: true, message: 'Medication deleted successfully', severity: 'success' });
    } catch (error) {
      console.error('Error deleting medication:', error);
      setSnackbar({ open: true, message: 'Error deleting medication', severity: 'error' });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h6">Medications</Typography>
        <Button startIcon={<AddIcon />} variant="contained" onClick={() => setOpen(true)}>
          Add Medication
        </Button>
      </Box>
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        <List>
          {medications.map((medication) => (
            <Card key={medication._id} sx={{ mb: 2 }}>
              <CardContent>
                <Typography variant="h6">{medication.name}</Typography>
                <Typography color="textSecondary">
                  Dosage: {medication.dosage}
                </Typography>
                <Typography variant="body2">
                  Frequency: {medication.frequency}, Time: {medication.time}
                </Typography>
                <Chip 
                  label={medication.resident.name} 
                  variant="outlined" 
                  size="small" 
                  sx={{ mt: 1 }}
                />
              </CardContent>
              <CardActions>
                <IconButton onClick={() => handleEdit(medication)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDelete(medication._id)}>
                  <DeleteIcon />
                </IconButton>
              </CardActions>
            </Card>
          ))}
        </List>
      </Box>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{editingMedication ? 'Edit Medication' : 'Add New Medication'}</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            value={newMedication.name}
            onChange={(e) => setNewMedication({ ...newMedication, name: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Dosage"
            value={newMedication.dosage}
            onChange={(e) => setNewMedication({ ...newMedication, dosage: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Frequency"
            value={newMedication.frequency}
            onChange={(e) => setNewMedication({ ...newMedication, frequency: e.target.value })}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Time"
            value={newMedication.time}
            onChange={(e) => setNewMedication({ ...newMedication, time: e.target.value })}
            fullWidth
            margin="normal"
          />
          <Select
            value={newMedication.resident}
            onChange={(e) => setNewMedication({ ...newMedication, resident: e.target.value })}
            fullWidth
            margin="normal"
          >
            {residents.map((resident) => (
              <MenuItem key={resident._id} value={resident._id}>{resident.name}</MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button onClick={editingMedication ? handleUpdate : handleCreate}>
            {editingMedication ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
      />
    </Box>
  );
};

export default MedicationManager;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Typography,
  TextField,
  Snackbar,
  Alert,
  Box,
  Grid,
  Avatar,
  Button,
  IconButton,
  Paper,
  Skeleton,
  Fade,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  Business,
  Description,
  LocationOn,
  Phone,
  Email,
  Language,
  Edit,
  Save,
  Cancel,
} from '@mui/icons-material';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(4),
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'url("/organization-header-bg.jpg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    opacity: 0.2,
  }
}));

const Organization = () => {
  const [organizationData, setOrganizationData] = useState({
    name: '',
    description: '',
    address: '',
    phoneNumber: '',
    email: '',
    website: '',
  });
  const [editMode, setEditMode] = useState({});
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchOrganizationData();
  }, []);

  const fetchOrganizationData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('/api/admin/organization', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setOrganizationData(response.data);
    } catch (error) {
      console.error('Error fetching organization data:', error);
      setSnackbar({ open: true, message: 'Error fetching organization data', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setOrganizationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (field) => {
    try {
      await axios.put('/api/admin/organization', organizationData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setSnackbar({ open: true, message: 'Organization details updated successfully', severity: 'success' });
      setEditMode((prev) => ({ ...prev, [field]: false }));
    } catch (error) {
      console.error('Error updating organization data:', error);
      setSnackbar({ open: true, message: 'Error updating organization data', severity: 'error' });
    }
  };

  const toggleEditMode = (field) => {
    setEditMode((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  const renderField = (label, field, icon) => (
    <StyledPaper elevation={1}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        {icon}
        <Typography variant="subtitle1" sx={{ ml: 1, flexGrow: 1 }}>{label}</Typography>
        <IconButton onClick={() => toggleEditMode(field)} size="small">
          {editMode[field] ? <Cancel /> : <Edit />}
        </IconButton>
      </Box>
      <Fade in={!loading}>
        {editMode[field] ? (
          <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
            <TextField
              fullWidth
              name={field}
              value={organizationData[field]}
              onChange={handleInputChange}
              multiline={field === 'description'}
              rows={field === 'description' ? 3 : 1}
              variant="standard"
              sx={{ mr: 1 }}
            />
            <Button
              startIcon={<Save />}
              onClick={() => handleSubmit(field)}
              variant="contained"
              color="primary"
              size="small"
            >
              Save
            </Button>
          </Box>
        ) : (
          <Typography
            variant="body2"
            onClick={() => toggleEditMode(field)}
            sx={{
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'action.hover',
                borderRadius: 1,
              },
              p: 1,
            }}
          >
            {organizationData[field] || 'Click to add'}
          </Typography>
        )}
      </Fade>
      {loading && <Skeleton variant="text" />}
    </StyledPaper>
  );

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <StyledHeader>
        <Avatar sx={{ width: 80, height: 80, mr: 3, bgcolor: 'secondary.main' }}>
          {loading ? (
            <Skeleton variant="circular" width={80} height={80} />
          ) : (
            organizationData.name ? organizationData.name.charAt(0) : <Business fontSize="large" />
          )}
        </Avatar>
        <Box>
          <Typography variant="h4" sx={{ mb: 1 }}>
            {loading ? <Skeleton width={200} /> : (organizationData.name || 'Organization Name')}
          </Typography>
          <Typography variant="subtitle1">
            {loading ? <Skeleton width={300} /> : 'Manage your organization details'}
          </Typography>
        </Box>
      </StyledHeader>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          {renderField('Organization Name', 'name', <Business color="primary" />)}
        </Grid>
        <Grid item xs={12} md={6}>
          {renderField('Description', 'description', <Description color="primary" />)}
        </Grid>
        <Grid item xs={12} md={6}>
          {renderField('Address', 'address', <LocationOn color="primary" />)}
        </Grid>
        <Grid item xs={12} md={6}>
          {renderField('Phone Number', 'phoneNumber', <Phone color="primary" />)}
        </Grid>
        <Grid item xs={12} md={6}>
          {renderField('Email', 'email', <Email color="primary" />)}
        </Grid>
        <Grid item xs={12} md={6}>
          {renderField('Website', 'website', <Language color="primary" />)}
        </Grid>
      </Grid>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Organization;
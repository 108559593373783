import React, { useState } from 'react';
import { Box, Container, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Snackbar } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled, keyframes } from '@mui/material/styles';
import { motion } from 'framer-motion';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import demoVideo from '../../assets/demo1.mp4';
import hopelinxIcon from '../../assets/hopelinxicon.png';
import axios from 'axios';
import MuiAlert from '@mui/material/Alert';

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  minHeight: '100vh',
  display: 'flex',
  alignItems: 'center',
  backgroundImage: 'linear-gradient(45deg, #FF8C00, #E91E63)', // Orange to Pink gradient
  overflow: 'hidden',
}));

const FloatingIcon = styled('img')(({ theme }) => ({
  position: 'absolute',
  width: '150px', // Adjust size as needed
  height: 'auto',
  opacity: 0.2, // Makes the icon transparent but still visible
  animation: `${float} 6s ease-in-out infinite`,
  filter: 'brightness(0) invert(1)', // This makes the icon white
}));

const HeroContent = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  color: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    textAlign: 'left',
    alignItems: 'center', // Changed from 'flex-start' to 'center'
  },
}));

const HeroText = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(4),
    flex: '0 1 40%', // Changed from flex: 1 to give more space to the video
  },
}));

const HeroHeadingWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

const HeroHeadingLine = styled(motion.div)(({ theme }) => ({
  fontFamily: '"Intro Rust", sans-serif',
  fontWeight: 700,
  lineHeight: 1.2,
  letterSpacing: '0.02em',
  marginBottom: theme.spacing(1),
  display: 'inline-block',
}));

const MainHeadingLine = styled(HeroHeadingLine)(({ theme }) => ({
  fontSize: '3rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '2.5rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

const SecondaryHeadingLine = styled(HeroHeadingLine)(({ theme }) => ({
  fontSize: '3.5rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '3rem',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',
  },
}));

const TertiaryHeadingLine = styled(HeroHeadingLine)(({ theme }) => ({
  fontSize: '2rem', // Reduced from 2.5rem
  [theme.breakpoints.down('md')]: {
    fontSize: '1.5rem', // Reduced from 2rem
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.2rem', // Reduced from 1.5rem
  },
}));

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const glow = keyframes`
  0% { box-shadow: 0 0 5px rgba(255, 255, 255, 0.3), 0 0 10px rgba(255, 255, 255, 0.2), 0 0 15px rgba(255, 255, 255, 0.1); }
  50% { box-shadow: 0 0 10px rgba(255, 255, 255, 0.4), 0 0 20px rgba(255, 255, 255, 0.3), 0 0 30px rgba(255, 255, 255, 0.2); }
  100% { box-shadow: 0 0 5px rgba(255, 255, 255, 0.3), 0 0 10px rgba(255, 255, 255, 0.2), 0 0 15px rgba(255, 255, 255, 0.1); }
`;

const EnhancedVideoContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  flex: '0 1 60%', // Changed from flex: 1 to make it larger
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(4),
    width: '100%', // Ensure full width on smaller screens
  },
}));

const EnhancedVideo = styled('video')(({ theme }) => ({
  width: '100%', // Changed from maxWidth to width for full container width
  height: 'auto',
  borderRadius: '16px', // Increased border radius
  boxShadow: '0 10px 30px rgba(0, 0, 0, 0.3), 0 0 30px rgba(255, 255, 255, 0.3)', // Enhanced shadow
  transition: 'all 0.5s ease-out',
  '&:hover': {
    transform: 'scale(1.03) translateY(-10px)', // Subtle lift effect
    boxShadow: '0 20px 40px rgba(0, 0, 0, 0.4), 0 0 50px rgba(255, 255, 255, 0.4)', // Enhanced hover shadow
  },
}));

// Add a new animation for the video container
const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
`;

// New styled component for video wrapper with animation
const AnimatedVideoWrapper = styled(motion.div)(({ theme }) => ({
  width: '100%',
  animation: `${pulse} 4s ease-in-out infinite`,
}));

const ScrollIndicator = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(4),
  left: '50%',
  transform: 'translateX(-50%)',
  cursor: 'pointer',
}));

// Update the EyeCatchingText styled component
const EyeCatchingText = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  bottom: '-35px', // Adjusted for smaller font size
  left: 0,
  right: 0,
  width: '100%',
  textAlign: 'center',
  color: theme.palette.common.white, // Changed to white
  fontFamily: '"Intro Rust", sans-serif',
  fontWeight: 700,
  fontSize: '0.8rem', // Reduced base font size
  letterSpacing: '0.05em',
  textShadow: '0 1px 2px rgba(0,0,0,0.3)', // Reduced shadow for clarity
  padding: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.9rem',
    bottom: '-40px',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
    bottom: '-45px',
  },
}));

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const API_BASE_URL = process.env.REACT_APP_API_URL || '';

const Hero = ({ scrollY }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    organizationName: '',
    numberOfResidents: '',
    message: '',
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const headingVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1,
        duration: 0.5,
      },
    }),
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${API_BASE_URL}/api/superadmin/request-demo`, formData);
      setSnackbar({ open: true, message: 'Demo request submitted successfully!', severity: 'success' });
      handleCloseDialog();
    } catch (error) {
      console.error('Error submitting demo request:', error);
      setSnackbar({ open: true, message: 'Error submitting demo request. Please try again.', severity: 'error' });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <HeroSection>
      <FloatingIcon
        src={hopelinxIcon}
        alt="Hopelinx Icon"
        sx={{
          top: '10%',
          left: '5%',
          animationDelay: '0s',
        }}
      />
      <FloatingIcon
        src={hopelinxIcon}
        alt="Hopelinx Icon"
        sx={{
          bottom: '15%',
          right: '10%',
          animationDelay: '2s',
          width: '100px', // Smaller size for variety
        }}
      />
      <HeroContent maxWidth="lg">
        <HeroText>
          <HeroHeadingWrapper>
            <MainHeadingLine
              variants={headingVariants}
              custom={0}
              initial="hidden"
              animate="visible"
              whileHover={{ scale: 1.05, color: '#FFD700' }}
            >
              Where Hope
            </MainHeadingLine>
            <br />
            <SecondaryHeadingLine
              variants={headingVariants}
              custom={1}
              initial="hidden"
              animate="visible"
              whileHover={{ scale: 1.05, color: '#FFD700' }}
            >
              Connects
            </SecondaryHeadingLine>
            <br />
            <TertiaryHeadingLine
              variants={headingVariants}
              custom={2}
              initial="hidden"
              animate="visible"
              whileHover={{ scale: 1.05, color: '#FFD700' }}
            >
              and Creates Change
            </TertiaryHeadingLine>
          </HeroHeadingWrapper>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
          >
            <Typography 
              variant="h5" 
              sx={{ 
                mb: 4, 
                maxWidth: '600px',
                fontSize: { xs: '1rem', sm: '1.1rem', md: '1.25rem' },
                lineHeight: { xs: 1.6, md: 1.8 },
              }}
            >
              Empowering recovery houses and residents with cutting-edge management tools and personalized support.
            </Typography>
            <Box sx={{ 
              display: 'flex', 
              gap: 2, 
              flexWrap: 'wrap',
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}>
              <Button
                component={Link}
                to="/register/admin"
                variant="contained"
                color="secondary"
                size="large"
                sx={{ 
                  px: { xs: 3, md: 4 },
                  py: 1.5, 
                  fontSize: { xs: '1rem', md: '1.1rem' },
                  transition: 'all 0.3s',
                  '&:hover': { 
                    transform: 'translateY(-3px)',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                Start Free Trial
              </Button>
              <Button
                onClick={handleOpenDialog}
                variant="outlined"
                size="large"
                sx={{ 
                  borderColor: 'common.white', 
                  color: 'common.white',
                  px: { xs: 3, md: 4 },
                  py: 1.5,
                  fontSize: { xs: '1rem', md: '1.1rem' },
                  transition: 'all 0.3s',
                  '&:hover': { 
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    transform: 'translateY(-3px)',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                Request Demo
              </Button>
            </Box>
          </motion.div>
        </HeroText>
        <EnhancedVideoContainer>
          <AnimatedVideoWrapper
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <EnhancedVideo
              autoPlay
              loop
              muted
              playsInline
            >
              <source src={demoVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </EnhancedVideo>
            <EyeCatchingText
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 1 }}
            >
              Designed and Developed by Recovery House Managers
            </EyeCatchingText>
          </AnimatedVideoWrapper>
        </EnhancedVideoContainer>
      </HeroContent>
      <ScrollIndicator
        animate={{ y: [0, 10, 0] }}
        transition={{ repeat: Infinity, duration: 1.5 }}
        onClick={() => window.scrollTo({ top: window.innerHeight, behavior: 'smooth' })}
      >
        <KeyboardArrowDownIcon sx={{ fontSize: 40 }} />
      </ScrollIndicator>

      <StyledDialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Request a Demo</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <StyledTextField
              fullWidth
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            <StyledTextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
            <StyledTextField
              fullWidth
              label="Phone Number"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
              required
            />
            <StyledTextField
              fullWidth
              label="Organization Name"
              name="organizationName"
              value={formData.organizationName}
              onChange={handleInputChange}
              required
            />
            <StyledTextField
              fullWidth
              label="Number of Residents"
              name="numberOfResidents"
              type="number"
              value={formData.numberOfResidents}
              onChange={handleInputChange}
              required
            />
            <StyledTextField
              fullWidth
              label="Message"
              name="message"
              multiline
              rows={4}
              value={formData.message}
              onChange={handleInputChange}
            />
            <DialogActions>
              <Button onClick={handleCloseDialog}>Cancel</Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </StyledDialog>

      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <MuiAlert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </MuiAlert>
      </Snackbar>
    </HeroSection>
  );
};

export default Hero;
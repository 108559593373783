import React, { useState } from 'react';
import axios from 'axios';
import {
  Typography, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Switch, Checkbox, FormGroup, FormControlLabel,
  Box, IconButton, Collapse, Snackbar, Grid
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { Add as AddIcon, ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  overflow: 'hidden',
  transition: 'all 0.3s',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2, 3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'transparent',
  color: theme.palette.primary.contrastText,
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    transform: 'translateY(-2px)',
    boxShadow: theme.shadows[2],
  },
  transition: 'all 0.3s',
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  border: `2px solid ${theme.palette.primary.contrastText}`,
}));

const ExpandButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.dark,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  marginRight: theme.spacing(2),
  transition: 'transform 0.3s',
  '&.expanded': {
    transform: 'rotate(180deg)',
  },
}));

const TitleBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ResponsiveTableContainer = styled(TableContainer)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    '& table, & thead, & tbody, & th, & td, & tr': {
      display: 'block',
    },
    '& thead tr': {
      position: 'absolute',
      top: '-9999px',
      left: '-9999px',
    },
    '& tr': {
      marginBottom: '1rem',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    '& td': {
      border: 'none',
      position: 'relative',
      paddingLeft: '50%',
      textAlign: 'right',
      '&:before': {
        content: 'attr(data-label)',
        position: 'absolute',
        left: 6,
        width: '45%',
        paddingRight: 10,
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        textAlign: 'left',
      },
    },
  },
}));

const MedicationsBox = ({ medications, setMedications, residentId, isMobile }) => {
  const [openMedicationDialog, setOpenMedicationDialog] = useState(false);
  const [editingMedicationIndex, setEditingMedicationIndex] = useState(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [medicationForm, setMedicationForm] = useState({
    name: '',
    doseUnit: '',
    doseCount: '',
    count: '',
    isPRN: false,
    administrationTimes: {
      morning: false,
      afternoon: false,
      evening: false,
      night: false,
    },
    notes: '',
  });

  const handleMedicationChange = (field, value) => {
    setMedicationForm(prev => ({ ...prev, [field]: value }));
  };

  const handleAdministrationTimeChange = (time) => {
    setMedicationForm(prev => ({
      ...prev,
      administrationTimes: {
        ...prev.administrationTimes,
        [time]: !prev.administrationTimes[time],
      },
    }));
  };

  const handleAddMedication = async () => {
    try {
      let updatedMedications;
      if (editingMedicationIndex !== null) {
        updatedMedications = [...medications];
        updatedMedications[editingMedicationIndex] = medicationForm;
      } else {
        updatedMedications = [...medications, medicationForm];
      }
      
      const response = await axios.put(`/api/admin/resident/${residentId}`, { medications: updatedMedications }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      
      setMedications(response.data.medications);
      setOpenMedicationDialog(false);
      setEditingMedicationIndex(null);
      setMedicationForm({
        name: '',
        doseUnit: '',
        doseCount: '',
        count: '',
        isPRN: false,
        administrationTimes: {
          morning: false,
          afternoon: false,
          evening: false,
          night: false,
        },
        notes: '',
      });
      setSnackbarMessage('Medication updated successfully');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error adding/updating medication:', error);
      setSnackbarMessage('Error updating medication');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleEditMedication = (index) => {
    setMedicationForm(medications[index]);
    setEditingMedicationIndex(index);
    setOpenMedicationDialog(true);
  };

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <StyledPaper elevation={2}>
      <StyledHeader>
        <TitleBox>
          <ExpandButton
            onClick={handleToggleExpand}
            className={isExpanded ? 'expanded' : ''}
            size={isMobile ? "small" : "large"}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ExpandButton>
          <Typography variant={isMobile ? "subtitle1" : "h6"} fontWeight="bold">
            Medications
          </Typography>
        </TitleBox>
        <AnimatedButton
          onClick={() => {
            setMedicationForm({
              name: '',
              doseUnit: '',
              doseCount: '',
              count: '',
              isPRN: false,
              administrationTimes: {
                morning: false,
                afternoon: false,
                evening: false,
                night: false,
              },
              notes: '',
            });
            setEditingMedicationIndex(null);
            setOpenMedicationDialog(true);
          }}
          startIcon={<AddIcon />}
          size={isMobile ? "small" : "medium"}
        >
          Add Medication
        </AnimatedButton>
      </StyledHeader>
      <Collapse in={isExpanded}>
        <StyledContent>
          <ResponsiveTableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Dose</TableCell>
                  <TableCell>Count</TableCell>
                  <TableCell>PRN</TableCell>
                  <TableCell>Times</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {medications.map((medication, index) => (
                  <TableRow 
                    key={index}
                    hover
                    onClick={() => handleEditMedication(index)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell data-label="Name">{medication.name}</TableCell>
                    <TableCell data-label="Dose">{`${medication.doseCount} ${medication.doseUnit}`}</TableCell>
                    <TableCell data-label="Count">{medication.count}</TableCell>
                    <TableCell data-label="PRN">{medication.isPRN ? 'Yes' : 'No'}</TableCell>
                    <TableCell data-label="Times">
                      {Object.entries(medication.administrationTimes)
                        .filter(([_, value]) => value)
                        .map(([key]) => key.charAt(0).toUpperCase())
                        .join(', ')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ResponsiveTableContainer>
        </StyledContent>
      </Collapse>

      <Dialog 
        open={openMedicationDialog} 
        onClose={() => setOpenMedicationDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{editingMedicationIndex !== null ? 'Edit Medication' : 'Add Medication'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                label="Medication Name"
                fullWidth
                value={medicationForm.name}
                onChange={(e) => handleMedicationChange('name', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="Dose Unit"
                fullWidth
                value={medicationForm.doseUnit}
                onChange={(e) => handleMedicationChange('doseUnit', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="dense"
                label="Dose Count"
                fullWidth
                type="number"
                value={medicationForm.doseCount}
                onChange={(e) => handleMedicationChange('doseCount', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Count"
                fullWidth
                type="number"
                value={medicationForm.count}
                onChange={(e) => handleMedicationChange('count', e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={medicationForm.isPRN}
                    onChange={(e) => handleMedicationChange('isPRN', e.target.checked)}
                  />
                }
                label="PRN (As Needed)"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Administration Times</Typography>
              <FormGroup>
                {['morning', 'afternoon', 'evening', 'night'].map((time) => (
                  <FormControlLabel
                    key={time}
                    control={
                      <Checkbox
                        checked={medicationForm.administrationTimes[time]}
                        onChange={() => handleAdministrationTimeChange(time)}
                      />
                    }
                    label={time.charAt(0).toUpperCase() + time.slice(1)}
                  />
                ))}
              </FormGroup>
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="dense"
                label="Additional Notes"
                fullWidth
                multiline
                rows={4}
                value={medicationForm.notes}
                onChange={(e) => handleMedicationChange('notes', e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMedicationDialog(false)}>Cancel</Button>
          <Button onClick={handleAddMedication}>{editingMedicationIndex !== null ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </StyledPaper>
  );
};

export default MedicationsBox;
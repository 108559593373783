import React, { useState } from 'react';
import {
  Paper, Avatar, Typography, TextField, Box, InputAdornment, IconButton,
  Button, FormControl, MenuItem, Select
} from '@mui/material';
import { parseISO, format, isValid, parse } from 'date-fns';
import { 
  Person as PersonIcon, 
  CloudUpload as CloudUploadIcon, 
  Edit as EditIcon, 
  Save as SaveIcon, 
  Home as HomeIcon, 
  Event as EventIcon, 
  Hotel as HotelIcon 
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { IMaskInput } from 'react-imask';

const StyledPaper = styled(Paper)(({ theme }) => ({
  overflow: 'hidden',
  transition: 'all 0.3s',
  '&:hover': {
    boxShadow: theme.shadows[4],
  },
  width: '100%',
}));

const StyledHeader = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(2, 3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const StyledContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const SquareAvatar = styled(Avatar)(({ theme }) => ({
  width: '100%',
  height: 'auto',
  aspectRatio: '1 / 1',
  borderRadius: 0,
  position: 'relative',
}));

const ResidentNameWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  padding: theme.spacing(2),
  transition: 'all 0.3s ease',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const ResidentName = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '1.5rem',
  fontWeight: 'bold',
  textShadow: '1px 1px 2px rgba(0,0,0,0.8)',
  transition: 'all 0.3s ease',
  textAlign: 'center',
}));

const AnimatedButton = styled(Button)(({ theme }) => ({
  transition: 'all 0.3s',
}));

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  width: '100%',
  maxWidth: 300,
}));

const StyledTextField = styled(TextField)(({ theme, isediting }) => ({
  '& .MuiInputBase-root': {
    backgroundColor: isediting === 'true' ? theme.palette.background.paper : 'transparent',
    transition: theme.transitions.create(['background-color', 'box-shadow']),
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: isediting === 'true' ? `1px solid ${theme.palette.primary.main}` : 'none',
  },
  '& .MuiInputBase-input': {
    padding: isediting === 'true' ? theme.spacing(1) : 0,
    fontWeight: 'normal',
    color: theme.palette.text.primary,
  },
  '& .MuiInputLabel-root': {
    display: isediting === 'true' ? 'block' : 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: isediting === 'true' ? theme.palette.primary.main : 'transparent',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: isediting === 'true' ? theme.palette.primary.main : 'transparent',
  },
}));

const DateMaskInput = React.forwardRef(function DateMaskInput(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="00/00/0000"
      definitions={{
        '0': /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const CenteredBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
});

const ClickableTypography = styled(Typography)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const UploadIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  right: 8,
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ResidentHeader = ({ 
  resident, 
  houses, 
  housesLoading, 
  handleAvatarUpload, 
  handleEditSave,
  handleHouseChange,
  handleRoomChange,
  handleDateChange,
  isMobile,
  setResident
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedFields, setEditedFields] = useState({
    name: resident.name,
    assignedHouse: resident.assignedHouse,
    assignedRoom: resident.assignedRoom,
    moveInDate: resident.moveInDate
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      const updatedResident = await handleEditSave(editedFields);
      setResident(updatedResident);
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving resident details:', error);
    }
  };

  const handleChange = (field) => (event) => {
    setEditedFields({ ...editedFields, [field]: event.target.value });
  };

  const renderEditableHouseAndRoom = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mb: 2 }}>
        <Box flexGrow={1}>
          <Box display="flex" alignItems="center">
            <InputAdornment position="start" sx={{ mr: 1, color: 'primary.main' }}>
              <HomeIcon />
            </InputAdornment>
            <Box flexGrow={1}>
              {!isEditing && (
                <Typography variant="body1" fontWeight="bold" color="primary.main">
                  Assigned House
                </Typography>
              )}
              <StyledTextField
                select
                fullWidth
                label={isEditing ? "Assigned House" : ""}
                value={editedFields.assignedHouse || ''}
                onChange={(e) => {
                  handleChange('assignedHouse')(e);
                  handleHouseChange(e);
                }}
                InputProps={{
                  readOnly: !isEditing,
                }}
                variant={isEditing ? "outlined" : "standard"}
                size="small"
                isediting={isEditing.toString()}
              >
                {housesLoading ? (
                  <MenuItem disabled>Loading houses...</MenuItem>
                ) : houses.length > 0 ? (
                  houses.map((house) => (
                    <MenuItem key={house._id} value={house._id}>
                      {house.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No houses available</MenuItem>
                )}
              </StyledTextField>
            </Box>
          </Box>
        </Box>
        <Box flexGrow={1}>
          {renderEditableRoom()}
        </Box>
      </Box>
    );
  };

  const renderEditableRoom = () => {
    if (!editedFields.assignedHouse) return null;

    const assignedHouse = houses.find(h => h._id === editedFields.assignedHouse);
    if (!assignedHouse) return null;

    return (
      <Box display="flex" alignItems="center">
        <InputAdornment position="start" sx={{ mr: 1, color: 'primary.main' }}>
          <HotelIcon />
        </InputAdornment>
        <Box flexGrow={1}>
          {!isEditing && (
            <Typography variant="body1" fontWeight="bold" color="primary.main">
              Assigned Room
            </Typography>
          )}
          <StyledTextField
            select
            fullWidth
            label={isEditing ? "Assigned Room" : ""}
            value={editedFields.assignedRoom || ''}
            onChange={(e) => {
              handleChange('assignedRoom')(e);
              handleRoomChange(e);
            }}
            InputProps={{
              readOnly: !isEditing,
            }}
            variant={isEditing ? "outlined" : "standard"}
            size="small"
            isediting={isEditing.toString()}
          >
            {assignedHouse.rooms.map((room) => (
              <MenuItem key={room._id} value={room._id}>
                {room.name}
              </MenuItem>
            ))}
          </StyledTextField>
        </Box>
      </Box>
    );
  };

  const renderEditableMoveInDate = () => {
    return (
      <Box display="flex" alignItems="center" mb={2}>
        <InputAdornment position="start" sx={{ mr: 1, color: 'primary.main' }}>
          <EventIcon />
        </InputAdornment>
        <Box flexGrow={1}>
          {!isEditing && (
            <Typography variant="body1" fontWeight="bold" color="primary.main">
              Move-in Date
            </Typography>
          )}
          <StyledTextField
            fullWidth
            label={isEditing ? "Move-in Date" : ""}
            value={editedFields.moveInDate ? format(parseISO(editedFields.moveInDate), 'MM/dd/yyyy') : ''}
            onChange={(e) => {
              const dateValue = e.target.value;
              if (dateValue.length === 10) {
                const parsedDate = parse(dateValue, 'MM/dd/yyyy', new Date());
                if (isValid(parsedDate)) {
                  const isoDate = parsedDate.toISOString();
                  handleChange('moveInDate')({ target: { value: isoDate } });
                  handleDateChange('moveInDate', isoDate);
                }
              }
            }}
            InputProps={{
              readOnly: !isEditing,
              inputComponent: DateMaskInput,
            }}
            variant={isEditing ? "outlined" : "standard"}
            size="small"
            isediting={isEditing.toString()}
          />
        </Box>
      </Box>
    );
  };

  const renderEditableName = () => {
    return (
      <ResidentNameWrapper>
        {isEditing ? (
          <StyledTextField
            fullWidth
            label="Resident Name"
            value={editedFields.name}
            onChange={handleChange('name')}
            variant="outlined"
            size="small"
            sx={{
              backgroundColor: 'white',
              '& .MuiInputBase-input': {
                color: 'black',
              },
              '& .MuiInputLabel-root': {
                color: 'rgba(0, 0, 0, 0.6)',
              },
            }}
          />
        ) : (
          <ResidentName>
            {resident.name}
          </ResidentName>
        )}
      </ResidentNameWrapper>
    );
  };

  return (
    <StyledPaper elevation={3}>
      <StyledHeader>
        <Typography variant={isMobile ? "subtitle1" : "h6"} fontWeight="bold">
          Resident Profile
        </Typography>
        <AnimatedButton
          onClick={isEditing ? handleSave : handleEdit}
          startIcon={isEditing ? <SaveIcon /> : <EditIcon />}
          variant="contained"
          color={isEditing ? "success" : "primary"}
          size={isMobile ? "small" : "medium"}
        >
          {isEditing ? 'Save' : 'Edit'}
        </AnimatedButton>
      </StyledHeader>
      <Box position="relative">
        <SquareAvatar
          src={resident.avatarUrl}
          alt={resident.name}
          variant="square"
        >
          {!resident.avatarUrl && <PersonIcon sx={{ fontSize: '4rem' }} />}
        </SquareAvatar>
        <UploadIconButton
          component="label"
          aria-label="upload picture"
        >
          <CloudUploadIcon />
          <VisuallyHiddenInput type="file" onChange={handleAvatarUpload} accept="image/*" />
        </UploadIconButton>
        {renderEditableName()}
      </Box>
      <StyledContent>
        {renderEditableHouseAndRoom()}
        {renderEditableMoveInDate()}
      </StyledContent>
    </StyledPaper>
  );
};

export default ResidentHeader;
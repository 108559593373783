import React from 'react';
import { AppBar, Toolbar, Button, Container, Box, IconButton, useMediaQuery, Drawer, List, ListItem, ListItemText, Typography, Divider, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { styled, useTheme } from '@mui/material/styles';
import logoLight from '../../assets/hopelinx.png';
import logoDark from '../../assets/hopelinxdark.png';
import hopelinxIcon from '../../assets/hopelinxicon.png';
import { motion } from 'framer-motion';
import hopelinxLogo from '../../assets/hopelinxlogo.png';

const LogoFade = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '240px',
  height: '60px',
  display: 'flex',
  alignItems: 'center',
}));

const LogoImage = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: '180px',
  height: '100%',
  objectFit: 'contain',
  transition: 'opacity 0.3s ease-in-out',
}));

const IconImage = styled('img')({
  width: '50px',
  height: '50px',
  marginRight: '10px',
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '100%',
    height: '100%',
    background: theme.palette.background.default,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
}));

const DrawerListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Header = ({ trigger, scrollToSection, navItems }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const slideTransition = {
    enter: {
      transform: 'translateX(0%)',
      transition: {
        duration: 0.3,
        ease: [0.4, 0, 0.2, 1],
      },
    },
    exit: {
      transform: 'translateX(100%)',
      transition: {
        duration: 0.3,
        ease: [0.4, 0, 0.2, 1],
      },
    },
  };

  return (
    <>
      <AppBar position="fixed" color="transparent" elevation={0} sx={{ 
        backgroundColor: trigger ? 'rgba(255, 255, 255, 0.95)' : 'transparent', 
        transition: 'background-color 0.3s',
        boxShadow: trigger ? '0px 2px 4px -1px rgba(0,0,0,0.2)' : 'none',
      }}>
        <Container maxWidth="lg">
          <Toolbar sx={{ height: 80 }}>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
              <LogoFade>
                <IconImage src={hopelinxIcon} alt="HopeLink Icon" />
                <LogoImage 
                  src={logoLight} 
                  alt="HopeLink Logo" 
                  sx={{ opacity: trigger ? 0 : 1 }}
                />
                <LogoImage 
                  src={logoDark} 
                  alt="HopeLink Logo Dark" 
                  sx={{ opacity: trigger ? 1 : 0 }}
                />
              </LogoFade>
            </Box>
            {isSmallScreen ? (
              <IconButton
                edge="start"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ 
                  color: trigger ? 'text.primary' : 'common.white',
                  transition: 'color 0.3s',
                }}
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <Box sx={{ display: 'flex', gap: 2 }}>
                {navItems.map((item) => (
                  <Button 
                    key={item.text}
                    onClick={() => item.link.startsWith('#') ? scrollToSection(item.link) : null}
                    component={item.link.startsWith('#') ? 'button' : Link}
                    to={item.link.startsWith('#') ? undefined : item.link}
                    variant={item.isButton ? "contained" : "text"}
                    color={item.isButton ? "secondary" : "inherit"}
                    sx={{ 
                      color: item.isButton ? 'white' : (trigger ? 'text.primary' : 'common.white'),
                      transition: 'color 0.3s',
                      '&:hover': {
                        backgroundColor: item.isButton ? 'secondary.dark' : (trigger ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.1)'),
                      },
                    }}
                  >
                    {item.text}
                  </Button>
                ))}
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>

      <motion.div
        initial="exit"
        animate={drawerOpen ? "enter" : "exit"}
        variants={slideTransition}
      >
        <StyledDrawer 
          anchor="right" 
          open={drawerOpen} 
          onClose={toggleDrawer(false)}
        >
          <Box sx={{ position: 'absolute', top: 20, right: 20 }}>
            <IconButton onClick={toggleDrawer(false)} sx={{ color: 'text.primary' }}>
              <CloseIcon fontSize="large" />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
            <img src={hopelinxLogo} alt="HopeLink Logo" style={{ width: '200px', height: 'auto' }} />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 6 }}>
            {navItems.filter(item => item.text !== 'Login').map((item) => (
              <Button
                key={item.text}
                onClick={() => {
                  if (item.link.startsWith('#')) {
                    scrollToSection(item.link);
                  } else {
                    // Handle navigation for non-anchor links
                  }
                  setDrawerOpen(false);
                }}
                sx={{
                  fontSize: '1.2rem',
                  fontWeight: 500,
                  color: 'text.primary',
                  mb: 2,
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                }}
              >
                {item.text}
              </Button>
            ))}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 'auto' }}>
            <Button 
              variant="contained" 
              color="secondary" 
              fullWidth 
              component={Link}
              to="/login"
              sx={{ mb: 2, py: 1.5, fontSize: '1.1rem', maxWidth: 300 }}
            >
              Login
            </Button>
            <Button 
              variant="contained" 
              color="primary" 
              fullWidth 
              component={Link}
              to="/register/admin"
              sx={{ py: 1.5, fontSize: '1.1rem', maxWidth: 300 }}
            >
              Start Free Trial
            </Button>
          </Box>
        </StyledDrawer>
      </motion.div>
    </>
  );
};

export default Header;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container, Typography, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Button, Box
} from '@mui/material';
import AccessDenied from '../../components/common/AccessDenied';

const RentPayments = () => {
  const [rentPayments, setRentPayments] = useState([]);
  const [stripeAccountStatus, setStripeAccountStatus] = useState('');
  const [stripeAccountLink, setStripeAccountLink] = useState('');
  const [error, setError] = useState('');
  const [rentPaymentEnabled, setRentPaymentEnabled] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profileRes = await axios.get('/api/auth/profile', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setRentPaymentEnabled(profileRes.data.rentPaymentEnabled);
        if (profileRes.data.rentPaymentEnabled) {
          await Promise.all([fetchRentPayments(), fetchStripeAccountStatus()]);
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
        setError('Error fetching profile data');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const fetchRentPayments = async () => {
    try {
      const response = await axios.get('/api/admin/rent-payments', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setRentPayments(response.data);
    } catch (error) {
      console.error('Error fetching rent payments:', error);
    }
  };

  const fetchStripeAccountStatus = async () => {
    try {
      const response = await axios.get('/api/admin/stripe-account-status', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setStripeAccountStatus(response.data.status);
      setStripeAccountLink(response.data.accountLink);
    } catch (error) {
      console.error('Error fetching Stripe account status:', error);
    }
  };

  const createStripeAccount = async () => {
    try {
      const response = await axios.post('/api/admin/create-stripe-account', {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setStripeAccountLink(response.data.accountLink);
      setStripeAccountStatus('pending');
      setError('');
    } catch (error) {
      console.error('Error creating Stripe account:', error);
      setError(error.response?.data?.message || 'Error creating Stripe account');
    }
  };

  const updatePaymentStatus = async (id, status) => {
    try {
      await axios.put(`/api/admin/rent-payments/${id}`, { status }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      fetchRentPayments();
    } catch (error) {
      console.error('Error updating rent payment:', error);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!rentPaymentEnabled) return <AccessDenied />;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Rent Payments</Typography>
      
      <Box mb={4}>
        <Typography variant="h6" gutterBottom>Stripe Account Status: {stripeAccountStatus}</Typography>
        {error && (
          <Typography color="error" gutterBottom>{error}</Typography>
        )}
        {stripeAccountStatus === 'pending' && stripeAccountLink && (
          <Button variant="contained" color="primary" href={stripeAccountLink} target="_blank">
            Complete Stripe Account Setup
          </Button>
        )}
        {stripeAccountStatus === 'inactive' && (
          <Button variant="contained" color="primary" onClick={createStripeAccount}>
            Create Stripe Account
          </Button>
        )}
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Resident</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rentPayments.map((payment) => (
              <TableRow key={payment._id}>
                <TableCell>{payment.resident.name}</TableCell>
                <TableCell>${payment.amount}</TableCell>
                <TableCell>{new Date(payment.dueDate).toLocaleDateString()}</TableCell>
                <TableCell>{payment.status}</TableCell>
                <TableCell>
                  {payment.status !== 'paid' && (
                    <Button onClick={() => updatePaymentStatus(payment._id, 'paid')}>
                      Mark as Paid
                    </Button>
                  )}
                  {payment.status === 'pending' && (
                    <Button onClick={() => updatePaymentStatus(payment._id, 'overdue')}>
                      Mark as Overdue
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default RentPayments;
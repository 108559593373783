/**
 * The `ProfileForm` component is a React functional component that renders a form for updating a user's profile information.
 * 
 * The form includes the following sections:
 * - Personal Information: Name, Email, Phone Number, Date of Birth
 * - Resident Information (if the user is a resident): Sobriety Date, Move-in Date
 * - Address
 * - Emergency Contact (if the user is a resident)
 * - Admin Information (if the user is an admin): Admin Code
 * 
 * The component uses the `useAuth` hook to access the user data and update function from the authentication context.
 * The form data is initialized with the user's existing data, and updates are made to the form state using various `handleChange` functions.
 * When the form is submitted, the `handleSubmit` function sends a PUT request to the `/api/auth/profile` endpoint to update the user's profile, and updates the user data in the authentication context.
 */
import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Grid, Typography, Avatar } from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import axios from 'axios';

export const ProfileForm = () => {
  // Access user data and update function from auth context
  const { user, setUser } = useAuth();

  // Initialize form state with empty values
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    dateOfBirth: '',
    address: { street: '', city: '', state: '', zipCode: '', country: '' },
    emergencyContact: { name: '', relationship: '', phoneNumber: '' },
    sobrietyDate: '',
    moveInDate: '',
    adminCode: '',
  });

  const [avatarUrl, setAvatarUrl] = useState('');
  const fileInputRef = useRef(null);

  // Populate form data when user data is available
  useEffect(() => {
    if (user) {
      setFormData(prevState => ({
        ...prevState,
        ...user,
        dateOfBirth: user.dateOfBirth ? new Date(user.dateOfBirth).toISOString().split('T')[0] : '',
        sobrietyDate: user.sobrietyDate ? new Date(user.sobrietyDate).toISOString().split('T')[0] : '',
        moveInDate: user.moveInDate ? new Date(user.moveInDate).toISOString().split('T')[0] : '',
        adminCode: user.adminCode || '',
      }));
      setAvatarUrl(user.avatarUrl || '');
    }
  }, [user]);

  // Handle changes for top-level form fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle changes for nested address fields
  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      address: {
        ...prevState.address,
        [name]: value,
      },
    }));
  };

  // Handle changes for nested emergency contact fields
  const handleEmergencyContactChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      emergencyContact: {
        ...prevState.emergencyContact,
        [name]: value,
      },
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put('/api/auth/profile', formData, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setUser(response.data);
      alert('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      alert('Error updating profile');
    }
  };

  const handleAvatarUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('avatar', file);

      try {
        const response = await axios.post('/api/admin/avatar', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        setAvatarUrl(response.data.avatarUrl);
        setUser(prevUser => ({ ...prevUser, avatarUrl: response.data.avatarUrl }));
      } catch (error) {
        console.error('Error uploading avatar:', error);
        alert('Error uploading avatar');
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Avatar
            src={avatarUrl}
            alt={formData.name}
            sx={{ width: 100, height: 100, mb: 2 }}
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleAvatarUpload}
            style={{ display: 'none' }}
            ref={fileInputRef}
          />
          <Button
            variant="outlined"
            onClick={() => fileInputRef.current.click()}
          >
            Change Avatar
          </Button>
        </Grid>
        {/* Personal Information Section */}
        <Grid item xs={12}>
          <Typography variant="h6">Personal Information</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Phone Number"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Date of Birth"
            name="dateOfBirth"
            type="date"
            value={formData.dateOfBirth}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        {user.role === 'resident' && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">Resident Information</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Sobriety Date"
                name="sobrietyDate"
                type="date"
                value={formData.sobrietyDate}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Move-in Date"
                name="moveInDate"
                type="date"
                value={formData.moveInDate}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </>
        )}

        {/* Address Section */}
        <Grid item xs={12}>
          <Typography variant="h6">Address</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Street"
            name="street"
            value={formData.address.street}
            onChange={handleAddressChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="City"
            name="city"
            value={formData.address.city}
            onChange={handleAddressChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="State"
            name="state"
            value={formData.address.state}
            onChange={handleAddressChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Zip Code"
            name="zipCode"
            value={formData.address.zipCode}
            onChange={handleAddressChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Country"
            name="country"
            value={formData.address.country}
            onChange={handleAddressChange}
          />
        </Grid>

        {/* Conditional Emergency Contact Section for residents */}
        {user.role === 'resident' && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">Emergency Contact</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={formData.emergencyContact.name}
                onChange={handleEmergencyContactChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Relationship"
                name="relationship"
                value={formData.emergencyContact.relationship}
                onChange={handleEmergencyContactChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phoneNumber"
                value={formData.emergencyContact.phoneNumber}
                onChange={handleEmergencyContactChange}
              />
            </Grid>
          </>
        )}

        {/* Conditional Admin Information Section */}
        {user.role === 'admin' && (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">Admin Information</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Admin Code"
                name="adminCode"
                value={formData.adminCode}
                InputProps={{
                  readOnly: true,
                }}
                variant="filled"
              />
            </Grid>
          </>
        )}

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Update Profile
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ProfileForm;
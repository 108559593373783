import React, { useState } from 'react';
import { Box, Container, Typography, Grid, Button, Paper, Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Link } from 'react-router-dom';

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Intro Rust", sans-serif',
  fontWeight: 700,
  fontSize: '3rem',
  letterSpacing: '0.05em',
  marginBottom: theme.spacing(8),
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2.5rem',
  },
}));

const FAQItem = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
  borderRadius: 16,
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease',
  cursor: 'pointer',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 6px 25px rgba(0, 0, 0, 0.15)',
  },
}));

const FAQ = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const faqs = [
    { question: "How secure is HopeLink?", answer: "HopeLink uses state-of-the-art encryption and is fully HIPAA-compliant to ensure the highest level of security for your data." },
    { question: "Can I customize HopeLink for my recovery house?", answer: "Yes, HopeLink offers customization options to tailor the platform to your specific needs." },
    { question: "Is there a free trial available?", answer: "We offer a 14-day free trial for all new accounts, allowing you to explore all features before committing." },
    { question: "How does HopeLink support resident privacy?", answer: "HopeLink implements strict access controls and data segregation to ensure resident information is only accessible to authorized personnel." },
  ];

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <Box 
      sx={{ 
        background: 'linear-gradient(135deg, #FFF5E6 0%, #FFE6CC 100%)', 
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        py: 10
      }} 
      id="faq"
    >
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          <SectionTitle variant="h2">
            Frequently Asked Questions
          </SectionTitle>
        </motion.div>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={10}>
            {faqs.map((faq, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                viewport={{ once: true }}
              >
                <FAQItem onClick={() => handleToggle(index)}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ fontWeight: 600, color: 'primary.main' }}>
                      {faq.question}
                    </Typography>
                    {expandedIndex === index ? <RemoveIcon color="primary" /> : <AddIcon color="primary" />}
                  </Box>
                  <Collapse in={expandedIndex === index}>
                    <Typography variant="body1" sx={{ mt: 2, color: 'text.secondary' }}>
                      {faq.answer}
                    </Typography>
                  </Collapse>
                </FAQItem>
              </motion.div>
            ))}
          </Grid>
        </Grid>
        <Box sx={{ mt: 8, textAlign: 'center' }}>
          <Typography variant="h6" color="text.secondary" gutterBottom>
            Still have questions?
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            endIcon={<ArrowForwardIcon />}
            component={Link}
            to="/contact"
            sx={{
              mt: 2,
              px: 4,
              py: 1.5,
              borderRadius: 30,
              fontWeight: 600,
              transition: 'all 0.3s',
              '&:hover': {
                transform: 'translateY(-3px)',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            Contact Us
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default FAQ;
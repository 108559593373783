/**
 * A React component that displays a card with the total number of admins.
 *
 * @param {object} props - The component props.
 * @param {number} props.count - The total number of admins.
 * @returns {JSX.Element} - The rendered AdminCountCard component.
 */
import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';

const AdminCountCard = ({ count }) => {
  return (
    <Card>
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          Total Admins
        </Typography>
        <Typography variant="h5" component="h2">
          {count}
        </Typography>
        <PeopleIcon color="primary" />
      </CardContent>
    </Card>
  );
};

export default AdminCountCard;
// client/src/pages/admin/ResidentManagement.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Grid, Typography, Paper, Box, CircularProgress, Tabs, Tab } from '@mui/material';
import EventManager from '../../components/admin/EventManager';
import TaskManager from '../../components/admin/TaskManager';
import MedicationManager from '../../components/admin/MedicationManager';
import ResidentList from '../../components/admin/ResidentList';

const ResidentManagement = () => {
  const [residents, setResidents] = useState([]);
  const [events, setEvents] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [medications, setMedications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const [residentsRes, eventsRes, tasksRes, medicationsRes] = await Promise.all([
        axios.get('/api/admin/residents', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }),
        axios.get('/api/admin/events', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }),
        axios.get('/api/admin/tasks', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }),
        axios.get('/api/admin/medications', {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        }),
      ]);

      setResidents(residentsRes.data.residents || []);
      setEvents(eventsRes.data || []);
      setTasks(tasksRes.data || []);
      setMedications(medicationsRes.data || []);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('An error occurred while fetching data. Please try again.');
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>Resident Management</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 'calc(100vh - 200px)' }}>
            <ResidentList residents={residents} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 'calc(100vh - 200px)' }}>
            <Tabs value={activeTab} onChange={handleTabChange} centered>
              <Tab label="Events" />
              <Tab label="Tasks" />
              <Tab label="Medications" />
            </Tabs>
            <Box sx={{ flexGrow: 1, mt: 2, overflow: 'auto' }}>
              {activeTab === 0 && <EventManager events={events} setEvents={setEvents} residents={residents} />}
              {activeTab === 1 && <TaskManager tasks={tasks} setTasks={setTasks} residents={residents} />}
              {activeTab === 2 && <MedicationManager medications={medications} setMedications={setMedications} residents={residents} />}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ResidentManagement;
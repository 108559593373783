import React, { useState, useEffect, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import { useTheme } from '@mui/material/styles';
import {
  Container, Typography, Grid, Card, Box, Chip,
  Button, CardMedia, CardContent, CardActions,
  Select, MenuItem, FormControl, InputLabel,
  Tabs, Tab
} from '@mui/material';
import { Home as HomeIcon } from '@mui/icons-material';
import { ColorModeContext } from '../../contexts/ColorModeContext';

const ResidentsList = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  const [activeResidents, setActiveResidents] = useState([]);
  const [archivedResidents, setArchivedResidents] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [houses, setHouses] = useState([]);

  useEffect(() => {
    fetchResidents();
    fetchHouses();
  }, []);

  const fetchResidents = async () => {
    try {
      const activeResponse = await axios.get('/api/admin/residents', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setActiveResidents(activeResponse.data.residents);

      const archivedResponse = await axios.get('/api/admin/residents?archived=true', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setArchivedResidents(archivedResponse.data.residents);
    } catch (error) {
      console.error('Error fetching residents:', error);
    }
  };

  const fetchHouses = async () => {
    try {
      const response = await axios.get('/api/admin/houses', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setHouses(response.data);
    } catch (error) {
      console.error('Error fetching houses:', error);
    }
  };

  const residents = tabValue === 0 ? activeResidents : archivedResidents;

  const handleHouseChange = async (residentId, newHouseId) => {
    try {
      await axios.put(`/api/admin/resident/${residentId}`, { assignedHouse: newHouseId }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      // Update the resident's assigned house in the local state
      const updatedResidents = residents.map(resident => 
        resident._id === residentId 
          ? { ...resident, assignedHouse: houses.find(h => h._id === newHouseId) } 
          : resident
      );
      setActiveResidents(updatedResidents);
    } catch (error) {
      console.error('Error updating resident house:', error);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" component="h1" sx={{ mb: 2 }}>
        {tabValue === 0 ? "Current Residents" : "Archived Residents"}
      </Typography>
      
      <Tabs
        value={tabValue}
        onChange={(event, newValue) => setTabValue(newValue)}
        sx={{ mb: 4 }}
      >
        <Tab label="Current Residents" />
        <Tab label="Archived Residents" />
      </Tabs>

      <Grid container spacing={3}>
        {residents.map((resident) => (
          <Grid item xs={12} sm={6} md={4} key={resident._id}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: 'transform 0.3s, box-shadow 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: theme.shadows[10],
                },
              }}
            >
              <CardMedia
                component="img"
                height="200"
                image={resident.avatarUrl || 'https://via.placeholder.com/150'}
                alt={resident.name}
              />
              <CardContent>
                <Typography variant="h6" component="div">
                  {resident.name}
                </Typography>
                {tabValue === 0 && (
                  <Chip
                    label="ACTIVE"
                    color="success"
                    size="small"
                    sx={{ mt: 1 }}
                  />
                )}
                <Box sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel id={`house-select-label-${resident._id}`}>Assigned House</InputLabel>
                    <Select
                      labelId={`house-select-label-${resident._id}`}
                      value={resident.assignedHouse ? resident.assignedHouse._id : ''}
                      onChange={(e) => handleHouseChange(resident._id, e.target.value)}
                      label="Assigned House"
                    >
                      <MenuItem value="" disabled>Select a house</MenuItem>
                      {houses.map((house) => (
                        <MenuItem key={house._id} value={house._id}>
                          {house.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </CardContent>
              <CardActions>
                <Button
                  component={RouterLink}
                  to={`/admin/resident/${resident._id}`}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  View Details
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ResidentsList;
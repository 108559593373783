/**
 * Renders the Maintenance Requests page for the admin user.
 * This component fetches the maintenance requests from the server, displays them in a list, and allows the admin to update the status of each request.
 * The component also checks if the user has the necessary permissions to access the maintenance requests feature.
 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Container, Typography, Grid, Card, CardContent, CardActions, 
  Button, Chip, TextField, InputAdornment, Dialog, DialogTitle, 
  DialogContent, DialogActions, IconButton, Box, useTheme
} from '@mui/material';
import { 
  Search as SearchIcon,
  Build as BuildIcon,
  AccessTime as AccessTimeIcon,
  CheckCircle as CheckCircleIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon
} from '@mui/icons-material';
import AccessDenied from '../../components/common/AccessDenied';

const MaintenanceRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [maintenanceRequestEnabled, setMaintenanceRequestEnabled] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRequest, setSelectedRequest] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [profileRes, requestsRes] = await Promise.all([
          axios.get('/api/auth/profile', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          }),
          axios.get('/api/admin/maintenance-requests', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          }),
        ]);
        setMaintenanceRequestEnabled(profileRes.data.maintenanceRequestEnabled);
        setRequests(requestsRes.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.response?.data?.message || 'An error occurred');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!maintenanceRequestEnabled) return <AccessDenied />;

  const fetchRequests = async () => {
    try {
      const { data } = await axios.get('/api/admin/maintenance-requests', {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setRequests(data);
    } catch (error) {
      console.error('Error fetching maintenance requests:', error);
    }
  };

  const updateRequestStatus = async (id, status) => {
    try {
      await axios.put(`/api/admin/maintenance-requests/${id}`, { status }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      fetchRequests();
      setSelectedRequest(null);
    } catch (error) {
      console.error('Error updating maintenance request:', error);
    }
  };

  const filteredRequests = requests.filter(request => 
    request.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    request.resident.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getStatusColor = (status) => {
    switch(status) {
      case 'pending': return theme.palette.warning.main;
      case 'in-progress': return theme.palette.info.main;
      case 'completed': return theme.palette.success.main;
      default: return theme.palette.grey[500];
    }
  };

  const getStatusIcon = (status) => {
    switch(status) {
      case 'pending': return <AccessTimeIcon />;
      case 'in-progress': return <BuildIcon />;
      case 'completed': return <CheckCircleIcon />;
      default: return null;
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>Maintenance Requests</Typography>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search requests..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 4 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Grid container spacing={3}>
        {filteredRequests.map((request) => (
          <Grid item xs={12} sm={6} md={4} key={request._id}>
            <Card 
              sx={{ 
                height: '100%', 
                display: 'flex', 
                flexDirection: 'column',
                transition: 'all 0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: theme.shadows[4],
                },
              }}
            >
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" component="div" gutterBottom>
                  {request.title}
                </Typography>
                <Chip
                  icon={getStatusIcon(request.status)}
                  label={request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                  sx={{ 
                    bgcolor: getStatusColor(request.status),
                    color: 'white',
                    mb: 2
                  }}
                />
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  Resident: {request.resident.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {request.description.slice(0, 100)}...
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" onClick={() => setSelectedRequest(request)}>
                  View Details
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      <Dialog open={!!selectedRequest} onClose={() => setSelectedRequest(null)} maxWidth="sm" fullWidth>
        {selectedRequest && (
          <>
            <DialogTitle sx={{ m: 0, p: 2 }}>
              {selectedRequest.title}
              <IconButton
                aria-label="close"
                onClick={() => setSelectedRequest(null)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                Status: 
                <Chip
                  icon={getStatusIcon(selectedRequest.status)}
                  label={selectedRequest.status.charAt(0).toUpperCase() + selectedRequest.status.slice(1)}
                  sx={{ 
                    bgcolor: getStatusColor(selectedRequest.status),
                    color: 'white',
                    ml: 1
                  }}
                />
              </Typography>
              <Typography gutterBottom>Resident: {selectedRequest.resident.name}</Typography>
              <Typography gutterBottom>Description: {selectedRequest.description}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => updateRequestStatus(selectedRequest._id, 'in-progress')} color="primary">
                Mark In Progress
              </Button>
              <Button onClick={() => updateRequestStatus(selectedRequest._id, 'completed')} color="secondary">
                Mark Completed
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Container>
  );
};

export default MaintenanceRequests;